import Vue from "vue";
import { PiniaVuePlugin } from "pinia";
import { datadogRum } from "@datadog/browser-rum";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import pinia from "@/stores";
import ApiClient from "@/api";
import { i18n } from "@/i18n";
import "@/plugins/directives";
import vuetify from "@/plugins/vuetify";
import { piniaSessionStoragePlugin } from "@/plugins/piniaSessionStorage";
import { vimeo, datadog, isDev, isLocal } from "@/config";
import { poinReferrer, poinPartnerCode } from "@/utils/cookie-utils";
import { register } from "swiper/element/bundle";

register();

Vue.config.productionTip = false;

// datadog
// https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
if (!isDev && !isLocal) {
  datadogRum.init({
    applicationId: datadog.applicationId,
    clientToken: datadog.clientToken,
    site: "datadoghq.com",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ["clickmap"],
    defaultPrivacyLevel: "allow",
    service: "poincampus",
    // allowedTracingUrls: [baseUrl, baseUrlV2],
  });
  datadogRum.startSessionReplayRecording();
}

// 레퍼러 및 파트너 코드 저장
poinReferrer.set();
poinPartnerCode.set();

// vimeo
const vimeoApiInstance = new ApiClient(null, { baseURL: vimeo.baseUrl }, [
  "vimeo",
]);
Object.defineProperties(Vue.prototype, {
  $vimeo: {
    get: function () {
      return vimeoApiInstance;
    },
  },
});

Vue.use(PiniaVuePlugin);
pinia.use(piniaSessionStoragePlugin);

// global event bus
const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return EventBus;
    },
  },
});

Vue.component("PImg", () => import("@/components/poin-ui/Img.vue"));
Vue.component("PBtn", () => import("@/components/poin-ui/Button.vue"));
Vue.component("PBtnList", () => import("@/components/poin-ui/ButtonList.vue"));
Vue.component("PBtnMore", () => import("@/components/poin-ui/ButtonMore.vue"));
Vue.component("PIcon", () => import("@/components/poin-ui/Icon.vue"));
Vue.component("PAsset", () => import("@/components/poin-ui/Asset.vue"));
Vue.component("PIconBtn", () => import("@/components/poin-ui/IconButton.vue"));
Vue.component("PInput", () => import("@/components/poin-ui/Input.vue"));
Vue.component(
  "PInputMerge",
  () => import("@/components/poin-ui/InputMerge.vue")
);
Vue.component("PTextarea", () => import("@/components/poin-ui/Textarea.vue"));
Vue.component("PSelect", () => import("@/components/poin-ui/Select.vue"));
Vue.component(
  "PSelectSort",
  () => import("@/components/poin-ui/SelectSort.vue")
);
Vue.component("PTable", () => import("@/components/poin-ui/Table.vue"));
Vue.component("PCheckbox", () => import("@/components/poin-ui/Checkbox.vue"));
Vue.component("PRadio", () => import("@/components/poin-ui/Radio.vue"));
Vue.component("PChip", () => import("@/components/poin-ui/Chip.vue"));
Vue.component("PChipIcon", () => import("@/components/poin-ui/ChipIcon.vue"));
Vue.component("PBadge", () => import("@/components/poin-ui/Badge.vue"));
Vue.component(
  "PBadgeDelete",
  () => import("@/components/poin-ui/BadgeDelete.vue")
);
Vue.component("PFileInput", () => import("@/components/poin-ui/FileInput.vue"));
Vue.component(
  "PDatePicker",
  () => import("@/components/poin-ui/DatePicker.vue")
);
Vue.component("PSwitch", () => import("@/components/poin-ui/Switch.vue"));
Vue.component("PSpinner", () => import("@/components/poin-ui/Spinner.vue"));
Vue.component("PTabs", () => import("@/components/poin-ui/Tabs.vue"));
Vue.component("PTip", () => import("@/components/poin-ui/Tip.vue"));
Vue.component("PLink", () => import("@/components/poin-ui/LinkButton.vue"));
Vue.component("PFilter", () => import("@/components/poin-ui/SelectFilter.vue"));
Vue.component(
  "PStickyHeader",
  () => import("@/components/poin-ui/StickyHeader.vue")
);
Vue.component("PDateInput", () => import("@/components/poin-ui/DateInput.vue"));
Vue.component("EmptyBox", () => import("@/components/poin-ui/EmptyBox.vue"));
Vue.component("EmptyCard", () => import("@/components/poin-ui/EmptyCard.vue"));
Vue.component("PModal", () => import("@/components/poin-ui/Modal.vue"));
Vue.component("PLabel", () => import("@/components/poin-ui/Label.vue"));
Vue.component("PLottie", () => import("@/components/poin-ui/Lottie.vue"));
Vue.component(
  "PColorPicker",
  () => import("@/components/poin-ui/ColorPicker.vue")
);
Vue.component(
  "AdminTitle",
  () => import("@/components/campus/admin/AdminTitle.vue")
);
Vue.component(
  "EditorViewer",
  () => import("@/components/common/EditorViewer.vue")
);
Vue.component(
  "InfoVertical",
  () => import("@/components/poin-ui/InfoVertical.vue")
);
Vue.component(
  "InfoHorizon",
  () => import("@/components/poin-ui/InfoHorizon.vue")
);
Vue.component("InfoTitle", () => import("@/components/poin-ui/InfoTitle.vue"));
Vue.component(
  "PInputImage",
  () => import("@/components/poin-ui/InputImage.vue")
);
Vue.component(
  "MonthPicker",
  () => import("@/components/poin-ui/MonthPicker.vue")
);

// if (import.meta.hot) {
//   import.meta.hot.on("custom-reload", ({ file }) => {
//     // store 전체를 새로운 상태로 교체하지만, 현재 상태는 유지
//     const currentState = JSON.parse(JSON.stringify(store.state));

//     store.hotUpdate({
//       mutations: Object.keys(store._mutations).map(
//         (key) => store._mutations[key][0]
//       ),
//       actions: Object.keys(store._actions).map((key) => store._actions[key][0]),
//       // getters: store._wrappedGetters,
//       modules: store._modules.root._children,
//     });

//     // 이전 상태 복원
//     store.replaceState(currentState);
//   });
// }

new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
