import Vue from "vue";
import Router from "vue-router";

import globalStore from "@/store";
import { isLocal, isDev, webBaseUrl } from "@/config";
import { commonRoutes } from "@/router/common-routes";
import { campusRoutes } from "@/router/campus-routes";
import { poincampusRoutes } from "@/router/poin-routes";
import { routerBeforeEach } from "@/router/utils/before-each";
import { checkAppVersion } from "@/utils/app-version";

Vue.use(Router);

window.localStorage.setItem("pageRefreshed", "false");

const getRoutes = () => {
  const origin = window.location.origin;
  // localhost, dev.poincampus.com, poincampus.com with subpath
  // [O] poincampus routes
  // [O] campus routes
  if (isLocal || webBaseUrl === origin) {
    return [
      poincampusRoutes,
      {
        path: "/:campusDomain",
        meta: { campusOnly: true },
        ...campusRoutes,
      },
    ];
  }
  // subdomain or extDomain
  // [X] poincampus routes
  // [O] campus routes
  return [{ path: "/", meta: { campusOnly: true }, ...campusRoutes }];
};

const routes = [
  ...commonRoutes,
  ...getRoutes(),
  {
    path: "*",
    name: "404",
    redirect: { path: "/" },
  },
];

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else if (from.path === to.path) {
      // 쿼리만 변경된 경우, 스크롤 변동 없도록 수정
      return {};
    }
    // Scroll to top
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => routerBeforeEach(to, from, next));

router.afterEach(async () => {
  // naver analytics
  if (!isDev && window.wcs) {
    window.wcs_do();
  }
  // hide spinner
  await checkAppVersion();
  globalStore.dispatch("common/setLoading", false);
});

router.onError((error) => {
  console.error(error);

  if (error.name === "ChunkLoadError") {
    const pageAlreadyRefreshed = JSON.parse(
      window.localStorage.getItem("pageRefreshed") || "false"
    );
    if (!pageAlreadyRefreshed) {
      window.localStorage.setItem("pageRefreshed", "true");
      return window.location.reload();
    }
  }
});

// customize router push method - catch navigation redirected err
const originalPushMethod = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPushMethod.call(this, location, onResolve, onReject);
  }
  return originalPushMethod.call(this, location).catch((err) => {
    const isNavFail = Object.values(Router.NavigationFailureType).some(
      (fType) => Router.isNavigationFailure(err, fType)
    );
    if (isNavFail) {
      // If pushing interrupted because of redirection from navigation guard - ignore it.
      return Promise.resolve(false);
    }
    // Otherwise throw error
    return Promise.reject(err);
  });
};

export default router;
