import { includes, isEmpty } from "lodash";

import globalStore from "@/store";
import { isDev, isLocal, webBaseUrl } from "@/config";
import { path, cookieUtils, ezwel } from "@/utils";
import { isMobile, setFavicon, setDocumentTitle } from "@/utils/dom-utils";

import {
  handleOauth,
  handleCampusMemberAuth,
  checkShutdown,
} from "@/router/utils/auth-utils";
import { setPwaInfo } from "@/router/utils/pwa";
import { checkLoginPath } from "@/utils/path";
import ChannelService from "@/utils/ChannelService";
import { useHistoryStore } from "@/stores/history";

export const routerBeforeEach = async (to, from, next) => {
  const initialRoute = from.path === "/" && !from.name;
  // 최초 진입시 로딩 (새로고침, url 직접 접근)
  if (initialRoute) {
    globalStore.dispatch("common/setLoading", true);
    // await fetchSysConfig();
  }

  // set auth
  const a = globalStore.getters["auth/getAuthenticated"];
  if (!a && !checkLoginPath(to)) {
    await globalStore.dispatch("auth/checkAuthentication");
  }

  if (to.name === "notification-bridge") {
    return next();
  }

  if (to.params.campusDomain !== "home") {
    delete to.query.campusUuid;
  } else {
    // 캠퍼스도메인 "home" 으로 설정되어있고 query.campusId도 없는 경우
    if (!to.query.campusUuid) {
      to.params.campusDomain = globalStore.getters["campuses/getCampusDomain"];
    }
  }

  // campusOnly Setting
  const isAuthBridge = to.name === "auth-bridge";

  if (isAuthBridge) {
    if (!to.query?.next) {
      globalStore.dispatch("common/setShowGlobalMenu", false);
      return next("/error");
    }
    const campusOnly = !to.query?.next?.includes(webBaseUrl);
    globalStore.dispatch("common/setCampusOnly", campusOnly);
  } else {
    const campusOnly = path.checkCampusDetailPath(to);
    globalStore.dispatch("common/setCampusOnly", campusOnly);
  }
  const campusOnly = globalStore.getters["common/campusOnly"];
  let isSameCampus = false;
  let dispatchFlag = false;
  // mau 집계 api 호출를 위한 session, user 정보
  const sessionId = cookieUtils.getCookie("poin-msi");
  const userId = globalStore.getters["users/getUserUuid"];
  to.meta.fromHistory =
    from.name !== null || document.referrer.includes(window.location.origin);
  if (campusOnly) {
    // 캠퍼스 최초 진입 시, 캠퍼스 정보 갱신
    const oldCampusId = globalStore.getters["campuses/getCampusUuid"];
    const oldCampusDomain = globalStore.getters["campuses/getCampusDomain"];
    const oldExtDomain =
      globalStore.getters["campuses/getCampusInfo"]?.extDomain;
    const newCampusId = to.query.campusUuid;
    const newCampusDomain = getCampusDomain(to);
    if (
      oldExtDomain &&
      newCampusDomain &&
      oldExtDomain.replace("www.", "") === newCampusDomain.replace("www.", "")
    ) {
      isSameCampus = true;
    } else {
      isSameCampus =
        oldCampusDomain === newCampusDomain || oldCampusId === newCampusId;
    }
    if (!newCampusId && !newCampusDomain) {
      console.log("campusOnly, but no campusId and no domain");
      globalStore.dispatch("common/setShowGlobalMenu", false);
      return next("/error");
    }
    const loadCampusScope = getLoadCampusScope(to);
    const pageChange =
      to?.name === "campus-page" &&
      from?.name === "campus-page" &&
      to?.params?.slug !== from?.params?.slug;
    if (!isSameCampus || loadCampusScope || pageChange) {
      if (!isSameCampus) {
        globalStore.dispatch("common/setLoading", true);
        globalStore.commit("members/clearMemberItem");
      }
      if (
        ((newCampusId || newCampusDomain) && from?.name !== to?.name) ||
        pageChange
      ) {
        const slug = to.meta?.slug || to.params?.slug || "home";
        const payload = {
          campusId: newCampusId,
          ...(!isSameCampus && { domain: newCampusDomain, replace: true }),
          scope: loadCampusScope,
          // 최초 캠퍼스 진입인 경우, mau 집계를 위해 sessionId, userId 전달
          ...(!isSameCampus &&
            userId && {
              headers: { "poin-custom": userId },
              userId,
            }),
          slug,
        };
        if (!isSameCampus && sessionId) {
          updateAccessLog(payload, sessionId);
        }
        const { success } = await globalStore.dispatch(
          "campuses/reqGetCampusInfo",
          payload
        );
        if (!success) {
          console.log("getCampusInfo ERROR");
          globalStore.dispatch("common/setShowGlobalMenu", false);
          return next("/error");
        } else {
          dispatchFlag = true;
          /*
          기존 캠퍼스 온보딩 완료 처리
          1. campusInfo.spec 속성이 없는 캠퍼스의 관리자가 최초 접속 시
          2. 유료 플랜 사용중 또는 판매 신청 완료 또는 캠퍼스 개설일이 2024.03.01 이전일 경우
          */
          const campusInfo = globalStore.getters["campuses/getCampusInfo"];
          const isCampusSuper = globalStore.getters["members/isCampusSuper"];
          const notComplete = !campusInfo?.spec?.some(
            (s) => s.code === "ONBOARDING_CAMPUS"
          );
          if (isCampusSuper && notComplete) {
            const regDttm = campusInfo?.regDttm;
            if (!!regDttm && new Date(regDttm) < new Date(1709251200000)) {
              await globalStore.dispatch("campuses/reqPutBasicCampusInfo", {
                spec: [
                  {
                    code: "ONBOARDING_CAMPUS",
                    title: "캠퍼스 온보딩",
                    seq: 0,
                    quota: 1,
                  },
                  {
                    code: "ONBOARDING_PRODUCT",
                    title: "프로덕트 온보딩",
                    seq: 10,
                    quota: 1,
                  },
                ],
              });
            }
          }
        }
      }
    }

    const { extDomain, domain } = globalStore.getters["campuses/getCampusInfo"];

    // URL Simplify
    if (isLocal) {
      if (to.params.campusDomain === "home") {
        globalStore.dispatch("common/setLoading", true);
        to.params.campusDomain = domain;
        delete to.query.campusUuid;
        globalStore.dispatch("common/setLoading", false);
        return next();
      }
    } else {
      const hostname = extDomain
        ? extDomain
        : domain
          ? `${domain}.poincampus.com`
          : "";
      const isSameOrigin =
        window.location.hostname === hostname ||
        window.location.hostname?.replace("www.", "") === hostname;
      const toAuthBridge = (href) => {
        globalStore.dispatch("common/setLoading", true);
        const url = new URL(href);
        url.searchParams.delete("campusUuid");
        globalStore.dispatch("common/setLoading", false);
        return next({
          name: "auth-bridge",
          query: {
            next: url.href,
          },
        });
      };

      if (!isSameOrigin && !isAuthBridge) {
        if (to.params.campusDomain === "home") {
          const origin = extDomain
            ? `https://${extDomain}`
            : `https://${domain}.poincampus.com`;
          const href = origin + to.fullPath.replace("/home", "");
          toAuthBridge(href);
          return;
        }

        if (!isSameCampus) {
          const origin = extDomain
            ? `https://${extDomain}`
            : `https://${domain}.poincampus.com`;
          const href =
            origin + to.fullPath.replace(`/home`, "").replace(`/${domain}`, "");
          toAuthBridge(href);
          return;
        }

        if (extDomain) {
          const href =
            origin + to.fullPath.replace(`/home`, "").replace(`/${domain}`, "");
          toAuthBridge(href);
          return;
        }
      }
    }

    /**
     * 파트너 링크 클릭
     */
    if (initialRoute && !to.meta?.productDetail) {
      const historyStore = useHistoryStore();
      historyStore.postPartnerLinkLogs(to);
    }

    /**
     * 이지웰 헤더 요청
     */
    if (initialRoute) {
      await ezwel.init(next);
    }
  }

  if (!isDev && !isLocal) {
    // ChannelTalk
    const onBoot = !!localStorage.getItem("Channel.ch-veil-id");
    if (onBoot) {
      ChannelService.track("PageView", {
        title: campusOnly
          ? globalStore.getters["campuses/getCampusInfo"]?.name
          : "포인캠퍼스 PoinCampus",
        url: `${window.location.host}${to.path}`,
        page: `${window.location.host}${to.path}`,
      });
    }
  }

  // handle oauth callback
  if (to.name === "login-oauth-callback") {
    const oAuthResult = await handleOauth(to, next);
    if (oAuthResult.success) {
      return next(oAuthResult.data);
    } else {
      return next("/");
    }
  }

  // global component setting
  if (to.matched?.[0]?.meta) {
    const meta = to.meta;
    const component = meta.component;

    globalStore.dispatch("common/setGlobalComponent", component || "WEB");
  }

  const authenticated = globalStore.getters["auth/getAuthenticated"];
  const authRequired =
    to.matched.findIndex((routeItem) => routeItem.meta.authRequired) > -1;
  if (authRequired && !authenticated) {
    const campusDomain =
      globalStore.getters["campuses/getCampusDomain"] || getCampusDomain(to);
    globalStore.dispatch("common/setLocationPath", to.fullPath);
    if (to.name === "campus-register") {
      next({ name: "join-select" });
      return;
    }
    if (campusDomain) {
      next({
        name: "campus-login-select",
        params: {
          campusDomain,
        },
      });
    } else {
      next({ name: "login-select", query: to.query });
    }
    return;
  }

  // set member
  // set SEO
  // set PWA
  if (campusOnly) {
    if (authenticated) {
      const member = globalStore.getters["members/getMemberItem"];
      const requiresMemberAuth = to.matched.some(
        (record) => record.meta?.memberAuth
      );
      if (
        isEmpty(member) ||
        (!dispatchFlag && requiresMemberAuth && from?.name !== to?.name)
      ) {
        const sessionId = cookieUtils.getCookie("poin-msi");
        await globalStore.dispatch("members/reqGetMember", {
          // 로그인 이후 최초 멤버 조회인 경우, mau 집계를 위해 sessionId, userId 전달
          ...((sessionId || userId) && {
            headers: { "poin-custom": sessionId || userId },
            userId,
          }),
        });
      }
    }
    const campusInfo = globalStore.getters["campuses/getCampusInfo"];
    const { favicon } = globalStore.getters["campuses/getDisplayCampusInfo"];
    setFavicon(favicon?.path || "/favicon.ico");
    setDocumentTitle(campusInfo);
    if (!isSameCampus) {
      if (!campusInfo.domain && !campusInfo.extDomain) {
        setPwaInfo({
          campusName: campusInfo.name,
          ...(favicon && { iconPath: favicon.path }),
        });
      }
    }
    globalStore.dispatch("common/setLoading", false);
    return handleCampusMemberAuth(to, from, next);
  } else {
    // 특정 캠퍼스 진입이 아닌 경우.
    globalStore.dispatch("campuses/clearCampusInfo");
    globalStore.dispatch("common/setCampusOnly", false);
    setFavicon();
    setDocumentTitle();
    setPwaInfo();
    globalStore.dispatch("common/setLoading", false);
    return next();
  }
};

const getCampusDomain = (to) => {
  if (to.params.campusDomain === "home") return null;
  const hostname = window.location.hostname;
  const origin = window.location.origin;
  // auth bridge
  if (to.name === "auth-bridge" && to.query.next) {
    const url = new URL(to.query.next);
    if (url.host.includes("poincampus.com")) {
      return url.host.split(".")[0];
    } else {
      return url.host;
    }
  }
  // 포인캠퍼스
  if (isLocal || webBaseUrl === origin) {
    // if (isDev || "poincampus.com" === hostname) {
    return to.params.campusDomain;
  }
  // 특정 캠퍼스 서브도메인
  if (hostname.includes("poincampus.com")) {
    return hostname.split(".")[0];
  }
  // 외부도메인 (www 제거)
  return hostname;
};

/**
 * 캠퍼스 정보 불러오는 meta 존재 여부 리턴
 */
const getLoadCampusScope = (to) => {
  let scope;
  to.matched?.forEach((route) => {
    const scopeMeta = route.meta?.loadCampusScope;
    if (scopeMeta) {
      scope = scopeMeta;
    }
  });
  return scope;
};

const fetchSysConfig = async () => {
  try {
    const response = await fetch("/sysConfig.json");
    const sysConfig = await response.json();
    globalStore.commit("configure/setSysConfig", sysConfig);
    checkShutdown();
  } catch (e) {
    console.log("fetchSysConfig error");
    console.log(e);
  }
};

/**
 * 접속 로그 기록 업데이트
 * 30초 후에도 동일 세션인 경우에만 업데이트 요청.
 */
const updateAccessLog = (
  payload,
  oldSessionId,
  oldHost = window.location.hostname
) => {
  setTimeout(async () => {
    const newSessionId = cookieUtils.getCookie("poin-msi");
    const newHost = window.location.hostname;
    const userId = globalStore.getters["users/getUserUuid"];
    if (newSessionId === oldSessionId && newHost === oldHost && !userId) {
      await globalStore.dispatch("campuses/reqGetCampusInfo", {
        ...payload,
        headers: { "poin-custom": newSessionId },
      });
    }
  }, 30000);
};
