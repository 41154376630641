import isEmpty from "lodash.isempty";
import { differenceInMilliseconds } from "date-fns";
import * as webview from "@/utils/webview";
import { clearAllPoinLinkCookies } from "@/utils/cookie-utils";
import { encryptPublic } from "@/utils/text-format.js";
import { getCredentials } from "@/api/getCredentials";

export default (apiInstance, localStorage, sessionStorage) => ({
  checkAuthentication: async (store) => {
    const auth = store.getters["getAuth"];
    const snsType = store.getters["getSnsType"];
    const isWebview = store.rootGetters["common/isPoinWebview"];
    const isAdmin = auth?.isAdmin;

    // auth 정보가 없으면 비로그인 상태 처리
    if (!snsType) {
      store.commit("setAuthenticated", false);
      return;
    }

    // auth 정보가 있을 때
    if (isWebview || isAdmin) {
      // 포인캠퍼스 앱: 기존 checkAuth 로직 사용
      const expireTime = auth?.expireTime;
      const accessKeyId = auth?.accessKeyId;

      const needRefresh =
        differenceInMilliseconds(new Date(expireTime), new Date()) <= 0;
      // localStorage에 저장된 auth 정보로 유저 정보 조회
      const { success } = await store.dispatch(
        "users/getUserFromCognitoId",
        {
          setAccInfo: true,
          // refresh 필요한 경우, signature 빼고 api 호출하도록 한다.
          // signature빼고 보내면 최종 접속 정보를 현재 접속정보로 업데이트해주므로
          // 중복 로그인에 걸리지 않는다.
          sigYn: !needRefresh,
          accessKeyId,
        },
        { root: true }
      );
      if (!success) {
        store.dispatch("signOut");
        return;
      }
      if (!isAdmin && needRefresh) {
        const refreshSuccess = await store.dispatch("reqRefreshToken");
        if (!refreshSuccess) {
          store.dispatch("signOut");
          return;
        }
      }
      store.commit("setAuthenticated", true);
      return;
    }
    // 웹 (브라우저)
    // 임시 자격 증명 발급
    const identityId = auth?.identityId;
    const token = auth?.token;
    if (identityId && token) {
      const res = await getCredentials({
        identityId,
        token,
      });
      if (!res) {
        store.dispatch("signOut");
        return;
      }
      store.commit("setAuthenticated", true);
    } else {
      store.commit("setAuthenticated", false);
    }
  },
  setAuth: async (store, payload) => {
    const { snsType, auth } = payload;

    if (isEmpty(auth)) {
      store.commit("setAuth", {});
      localStorage.setAuth({});
      sessionStorage.setAuth({});
    } else {
      // 브라우저 저장소에는 사용자 정보 저장하지 않도록 수정.
      const newAuth = {
        ...auth,
        snsType,
        user: null,
        instructor: null,
        email: null,
      };
      delete newAuth.user;
      delete newAuth.instructor;
      delete newAuth.email;
      store.commit("setAuth", newAuth);
      sessionStorage.setAuth(newAuth);
      if (store.rootGetters["common/getKeepLogin"]) {
        localStorage.setAuth(newAuth);
      }
    }
  },
  setAuthenticated: async (store, authenticated) => {
    store.commit("setAuthenticated", authenticated);
  },
  reqAuthLogin: async (store, payload) => {
    const { snsType, code, state, mktYn } = payload;
    let token = null;
    let refreshToken = null;
    // let code = null;
    if (snsType === "NONE") {
      const { email, password } = payload;
      const uglyPassword = await encryptPublic(password);
      const result = await apiInstance.auth.login({
        snsType,
        email,
        password: uglyPassword,
        // TODO: MKT
        mktYn,
      });
      return result;
    }
    const result = await apiInstance.auth.login({
      snsType,
      token,
      code,
      refreshToken,
      state,
      // TODO: MKT
      mktYn,
    });
    return result;
  },
  saveUserPool: async (store, payload) => {
    // save user db
    const { userInfo } = payload;
    const data = {
      cognitoId: store.rootGetters["users/getCognitoId"],
      ...userInfo,
    };
    const result = await apiInstance.users.postUsers(data);
    if (result.success) {
      store.commit("users/setUser", result.data, { root: true });
      return true;
    } else {
      return false;
    }
  },
  checkSignUser: async (store, payload) => {
    const { snsType } = payload;
    const resAuthLogin = await store.dispatch("reqAuthLogin", payload);
    //reAuthLogin 로그인 성공
    if (resAuthLogin.success && !isEmpty(resAuthLogin.data)) {
      const authInfo = resAuthLogin.data;
      let success = authInfo.user && !isEmpty(authInfo.user);
      // 로그인 성공시(기존 유저인 경우) user 정보 함께 전달됨.
      if (success) {
        store.dispatch("setAuth", {
          snsType,
          auth: authInfo,
        });
        store.dispatch(
          "users/setUser",
          { user: authInfo.user },
          { root: true }
        );
        store.commit("setAuthenticated", true);
      }
      return {
        success,
        authInfo: authInfo,
      };
    }
    return {
      success: false,
      authInfo: null,
      ...resAuthLogin,
    };
  },
  //로그인 action 처리
  login: async (store, payload) => {
    const { snsType } = payload;
    const deviceId = window.sessionStorage.getItem("firebaseToken");
    const isPoinWebview = store.rootGetters["common/isPoinWebview"];
    //자체 로그인
    if (snsType === "NONE") {
      let checkSignUser = await store.dispatch("checkSignUser", payload);
      if (checkSignUser.success) {
        // exist user pool
        store.commit("setAuthenticated", true);
        // 소셜 로그인
      } else {
        if (checkSignUser.msg) {
          return checkSignUser;
        } else {
          // exist cognito but not exist user pool
          const result = await store.dispatch("saveUserPool", {
            userInfo: {
              name: checkSignUser.authInfo.idToken.payload.name,
              email: payload.email,
              nickname: checkSignUser.authInfo.idToken.payload.nickname,
              ...(checkSignUser.authInfo.idToken.payload["custom:agreeYn"] &&
                checkSignUser.authInfo.idToken.payload["custom:agreeDttm"] && {
                  agreeYn:
                    checkSignUser.authInfo.idToken.payload["custom:agreeYn"],
                  agreeDttm:
                    checkSignUser.authInfo.idToken.payload["custom:agreeDttm"],
                }),
              snsType,
            },
          });

          if (result) {
            checkSignUser = await store.dispatch("checkSignUser", payload);
            if (checkSignUser.success) {
              store.commit("setAuthenticated", true);
            }
          }
          store.commit("deleteJoinUser");
        }
      }
      store.dispatch("setAuth", {
        snsType,
        auth: checkSignUser.authInfo,
      });
      if (checkSignUser.success) {
        window.localStorage.setItem("recentSnsType", snsType);
        if (deviceId && !isPoinWebview) {
          store.dispatch(
            "users/reqPutUpdateUsers",
            {
              deviceId: deviceId,
            },
            { root: true }
          );
        }
      }
      return checkSignUser;
    } else {
      const checkSignUser = await store.dispatch("checkSignUser", payload);
      store.dispatch("setAuth", {
        snsType,
        auth: checkSignUser.authInfo,
      });
      if (checkSignUser.success) {
        store.commit("setAuthenticated", true);
        window.localStorage.setItem("recentSnsType", snsType);
        if (deviceId && !isPoinWebview) {
          store.dispatch(
            "users/reqPutUpdateUsers",
            {
              deviceId,
            },
            { root: true }
          );
        }
      }
      return checkSignUser;
    }
  },
  signUp: async (store, payload) => {
    const { snsType } = payload;
    if (snsType === "NONE") {
      const {
        name,
        email,
        password,
        redirectUrl,
        nickname = "",
        agreeYn = false,
        agreeDttm = null,
      } = payload;
      const uglyPassword = await encryptPublic(password);
      const result = await apiInstance.auth.signUp({
        name,
        email,
        password: uglyPassword,
        redirectUrl,
        ...(nickname && { nickname }),
        ...(agreeYn &&
          agreeDttm && {
            agreeYn,
            agreeDttm,
          }),
      });
      if (result.success) {
        store.commit("setJoinUser", {
          email,
          password,
        });
      }
      return result;
    } else {
      // save user pool
      const mktYn =
        window.sessionStorage.getItem("mktYn") === "true" ? true : false;
      const campusOnly = store.rootGetters["common/campusOnly"];
      const {
        snsType,
        name,
        email,
        cognitoId,
        nickname = "",
        agreeYn = false,
        agreeDttm = null,
      } = payload;
      const result = await apiInstance.users.postUsers({
        cognitoId,
        name,
        email,
        snsType,
        ...(nickname && { nickname }),
        ...(agreeYn &&
          agreeDttm && {
            agreeYn: "Y",
            agreeDttm,
          }),
        ...(mktYn !== undefined && !campusOnly && { mktYn }),
      });
      if (result?.success) {
        store.commit("users/setUser", result.data, { root: true });
      }
      return result;
    }
  },
  signOut: (store, payload) => {
    store.dispatch("setAuth", {
      snsType: "",
      auth: {},
    });
    store.commit("setCredentials", null);
    store.commit("setAuthenticated", false);
    store.dispatch("users/setClearUser", null, { root: true });
    store.dispatch("members/clearMemberItem", null, { root: true });
    store.commit("products/clearProductsUser", null, { root: true });
    store.commit("activities/clearPlaylist", null, { root: true });
    store.dispatch("common/setLocationPath", "", { root: true });
    // 파트너 링크 관련 쿠키 삭제
    clearAllPoinLinkCookies();
    // 장바구니 관련 세션 스토리지 삭제
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    if (campusId) {
      window.sessionStorage.removeItem(`poin-pinia-checkout:${campusId}`);
    }
    if (store.rootGetters["common/isPoinWebview"]) {
      webview.signOut();
    }
  },
  clearAuth: (store, payload) => {
    store.dispatch("setAuth", {
      snsType: "",
      auth: {},
    });
    store.commit("setAuthenticated", false);
    store.commit("setCredentials", null);
    store.dispatch("users/setClearUser", null, { root: true });
    store.dispatch("members/clearMemberItem", null, { root: true });
    // 파트너 링크 관련 쿠키 삭제
    clearAllPoinLinkCookies();
  },
  reqForgotPassword: async (store, payload) => {
    const { email } = payload;
    if (email) {
      const result = await apiInstance.auth.forgotPassword({
        email,
      });
      return result;
    }
  },
  reqResetPassword: async (store, payload) => {
    const { code, password, email } = payload;
    const uglyPassword = await encryptPublic(password);
    if (email) {
      const result = await apiInstance.auth.resetPassword({
        email,
        code,
        password: uglyPassword,
      });
      return result;
    }
  },
  emailVerification: async (store, payload) => {
    const { email, password } = payload;
    const result = await apiInstance.auth.emailVerification({
      email,
    });
    if (result.success) {
      store.commit("setJoinUser", {
        email,
        ...(password && { password }),
      });
    }
    return result;
  },
  reqChangePassword: async (store, payload) => {
    const { newPassword, oldPassword } = payload;
    const email = store.rootGetters["users/getUserEmail"];
    const newUglyPassword = await encryptPublic(newPassword);
    const oldUglyPassword = await encryptPublic(oldPassword);
    const result = await apiInstance.auth.changePassword({
      email,
      newPassword: newUglyPassword,
      oldPassword: oldUglyPassword,
    });
    return result;
  },
  authVerification: async (store, payload) => {
    const { code, email = store.getters["getJoinUser"]?.email } = payload;
    const result = await apiInstance.auth.authVerification({
      code,
      email,
    });
    return result;
  },
  reqRefreshToken: async (store) => {
    // 포인캠퍼스 앱에서만 사용, web에서는 tokenRefresh 사용
    const auth = store.rootGetters["auth/getAuth"];
    const isWebview = store.rootGetters["common/isPoinWebview"];
    const isAdmin = auth?.isAdmin;
    if (auth && (isWebview || isAdmin)) {
      const snsType = store.getters["getSnsType"];
      const userUuid = store.rootGetters["users/getUserUuid"];
      const params = {
        accessKeyId: auth.accessKeyId,
        cognitoId: auth.cognitoId,
        userUuid,
      };

      const result = await apiInstance.auth.refreshToken(params);

      if (result?.success) {
        const { data = {} } = result;
        store.dispatch("setAuth", {
          snsType,
          auth: { ...data, ...(isAdmin && { isAdmin: true }) },
        });
      }
      return result?.success ?? false;
    }
    return false;
  },
  tokenRefresh: async (store) => {
    // web에서만 사용, 앱은 reqRefreshToken 사용
    const snsType = store.getters["getSnsType"];
    const auth = store.getters["getAuth"];
    const userUuid = store.rootGetters["users/getUserUuid"];
    const refreshToken = auth.refreshToken;
    const cognitoId = auth.identityId?.replace("ap-northeast-2:", "");

    const params = {
      refreshToken,
      cognitoId,
      userUuid,
    };

    const result = await apiInstance.auth.refreshToken(params);
    if (result?.success) {
      store.dispatch("setAuth", {
        snsType,
        auth: result.data,
      });
    }
    return result;
  },
  setTokenRefreshing: async (store, { tokenRefreshing }) => {
    store.commit("setTokenRefreshing", tokenRefreshing);
  },
  checkLocalAuth: (store, changeEvent) => {
    const { key, newValue } = changeEvent;
    if (key !== "auth") return;
    const auth = store.getters["getAuth"];
    const localAuth = JSON.parse(newValue);
    if (localAuth.cognitoId && localAuth.cognitoId === auth.cognitoId) {
      const isRefreshed =
        new Date(localAuth.expireTime) - new Date(auth.expireTime) > 0;
      if (isRefreshed) {
        store.commit("setAuth", localAuth);
        sessionStorage.setAuth(localAuth);
      }
    }
  },
});
