import ChannelService from "@/utils/ChannelService";
import CryptoJS from "crypto-js";
import { computed } from "vue";
import { useStore } from "@/store";
import { getDateFormat } from "@/utils/date-format";
import { planCodeFilter, planCycleFilter } from "@/utils/text-format";
import { passwordSecretKey } from "@/config";

export function useChannelService() {
  const store = useStore();

  const user = computed(() => store.getters["users/getUser"]);
  const userId = computed(() => store.getters["users/getUserUuid"]);

  const member = computed(() => store.getters["members/getMemberItem"]);
  const isCampusSuper = computed(() => store.getters["members/isCampusSuper"]);
  const isStaff = computed(() => store.getters["members/isStaff"]);

  const getChPluginKey = computed(() => store.getters["common/getChPluginKey"]);
  const campusOnly = computed(() => store.getters["common/campusOnly"]);

  const campusInfo = computed(() => store.getters["campuses/getCampusInfo"]);
  const campusUsage = computed(() => store.getters["campuses/getUsage"]);

  const convertChMobileFormat = computed(() => {
    const number = user.value?.cp || user.value?.contact || "";

    // 010 으로 시작하는 경우 채널톡 mobileNumber 포맷으로 변경
    const replaceNumber = number?.startsWith("010")
      ? number.replace(/-/g, "").replace(/^010/, "+8210")
      : number;

    return replaceNumber;
  });

  const convertArrToCommaStr = (arr = []) => {
    return (
      arr?.reduce((acc, cur, index) => {
        acc += cur?.name?.split(" ").join("");
        if (index < arr?.length - 1) {
          acc += ",";
        }
        return acc;
      }, "") || "-"
    );
  };

  const loginProfile = computed(() => {
    return {
      name: user.value?.name,
      email: user.value?.email,
      mobileNumber: convertChMobileFormat.value,
    };
  });

  const updateCompleteProductIds = (completeIds = []) => {
    ChannelService.updateUser({ profile: { completeIds } }, (error, user) => {
      if (error) {
        console.log(error);
      } else {
        store.commit(
          "common/setUserCompleteProductIds",
          user?.profile?.completeIds
        );
      }
    });
  };
  const updateProductProgress = (updateProduct) => {
    if (!updateProduct) {
      return;
    }

    const key = `progressRate#${updateProduct.targetId}`;
    const value = updateProduct.progressRate || 0;

    const userProductsProgress =
      store.getters["common/getUserProductsProgress"];

    const productExists = userProductsProgress.some(
      (p) => p.key === key && p.value === value
    );
    // 저장된 progressRate 정보와 같은 경우
    if (productExists) {
      return;
    }

    ChannelService.updateUser({ profile: { [key]: value } }, (error, user) => {
      if (error) {
        console.error(error);
        return;
      }

      const filteredProgress = Object.keys(user?.profile || {})
        .filter((k) => k.startsWith("progressRate#"))
        .map((k) => ({ key: k, value: user.profile[k] }))
        .filter((item) => item.value !== undefined);

      store.commit("common/setUserProductsProgress", filteredProgress);
    });
  };

  const toggleButton = (yn = true) => {
    if (yn) {
      ChannelService.showChannelButton();
    } else {
      ChannelService.hideMessenger();
      ChannelService.hideChannelButton();
    }
  };

  const setEventPage = (page) => {
    ChannelService.setPage(page);
    onBoot();
  };

  const resetEventPage = () => {
    ChannelService.resetPage();
  };

  const getPoinConfig = async () => {
    const configure = {
      pluginKey: getChPluginKey.value,
      memberId: userId.value,
    };

    let userCampus = await getUserCampusInfo();

    return { ...configure, profile: userCampus };
  };

  const getUserCampusInfo = async () => {
    if (campusInfo.value) {
      return getCampusProfile(campusInfo.value);
    } else {
      const result = await store.dispatch("campuses/getCampusInfo", {
        campusId: userId.value,
      });

      if (result.success && result.data) {
        return getCampusProfile(result.data);
      }
    }
  };

  const getCampusProfile = (data) => {
    return {
      ...loginProfile.value,
      poin_snsType: user.value?.snsType,
      poin_memberId: userId.value,
      poin_loginYn: true,
      poin_hasRoleOrAuth: isCampusSuper.value,
      poin_campusYn: true,
      poin_campusName: data.name,
      poin_planCode: planCodeFilter(data.PLAN),
      poin_planCycle: planCycleFilter(data.PLAN?.planCycle),
      poin_sales: data.salesStatus,
      poin_productYn: !!campusUsage.value?.volProducts,
      poin_contentYn: !!campusUsage.value?.volContents,
    };
  };

  const getMemberConfig = () => {
    const configure = {
      pluginKey: getChPluginKey.value,
      zIndex: 999,
      mobileMessengerMode: "newTab",
      language: "ko",
    };

    const profile = {
      ...loginProfile.value,
      nickname: member.value?.nickname?.replace(/\s/g, ""),
      marketing: member.value?.mktYn ? "O" : "X",
      regDttm: getDateFormat(member.value?.regDttm),
      tags: convertArrToCommaStr(member.value?.groups),
      communities: convertArrToCommaStr(member.value?.communities),
      productIds: member.value?.productIds?.join(",") || "",
    };

    return { ...configure, profile };
  };

  const channelConfig = async () => {
    /**
     * 포인 캠퍼스 채널톡 노출
     * 1. 포인캠퍼스 메인
     * 2. 개별 캠퍼스 && 직원 권한
     *
     * 캠퍼스 연동 채널톡 노출
     * 1. 연동 캠퍼스 직원이 아닌 경우 (회원, 비회원 모두 노출)
     */
    if (
      (!campusOnly.value && user?.value?.campusYn) ||
      (campusOnly.value && isStaff.value)
    ) {
      return await getPoinConfig();
    } else {
      return getMemberConfig();
    }
  };

  const onBoot = async () => {
    const config = await channelConfig();

    ChannelService.boot(config, (error, user) => {
      if (error) {
        console.log(error);
      } else {
        // success
      }
    });
  };

  const onReset = (reboot = false) => {
    store.commit("common/disconnectPluginKey");
    ChannelService.shutdown();
    if (reboot) {
      onBoot();
    }
  };

  const onUpdate = (key) => {
    const bytes = CryptoJS.AES.decrypt(key, passwordSecretKey);
    const decryptedKey = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    store.commit("common/updatePluginKeyConnection", decryptedKey);
    onBoot();
  };

  return {
    onBoot,
    onReset,
    onUpdate,
    updateCompleteProductIds,
    updateProductProgress,
    toggleButton,
    setEventPage,
    resetEventPage,
    getMemberConfig,
  };
}
