import globalStore from "@/store";

export default (apiInstance) => ({
  // Policy Template 조회
  async reqGetPolicyTemplate(_, { adminsObjType }) {
    const result = await apiInstance.admins.getPolicyTemplate({
      adminsObjType,
    });
    if (result.success) {
      const {
        data: { conts = "" },
      } = result;
      if (!conts) {
        globalStore.dispatch("common/setAlert", {
          open: true,
          msg: "등록된 템플릿이 없습니다.",
        });
      }
      return conts;
    }
    globalStore.dispatch("common/setAlert", {
      open: true,
      msg: "알 수 없는 오류입니다.",
    });
    return false;
  },
  // Policy Template 조회
  async reqGetPublicAdmins(store, { adminsObjType }) {
    const result = await apiInstance.admins.getPublicAdmins({
      adminsObjType,
    });
    if (result.success) {
      const { data } = result;
      if (data.subject) {
        store.commit("setNotificationBar", data);
      }
    }
    return result;
  },
  async reqGetNotificationBar({ dispatch }) {
    const result = await dispatch("reqGetPublicAdmins", {
      adminsObjType: "NOTIFICATION_BAR",
    });
    return result;
  },
  async reqGetAppVersion({ dispatch }) {
    const result = await dispatch("reqGetPublicAdmins", {
      adminsObjType: "APP_VER",
    });
    return result?.data?.ver ?? "";
  },
  // 인터뷰 목록 전체 조회
  reqGetInterviewList: async (store) => {
    const result = await apiInstance.admins.getInterviewList();
    if (result.success) {
      store.commit("setInterviewList", result.data);
    }
    return result.success;
  },
  // 인터뷰 상세 단건 조회
  reqGetInterview: async (store, payload) => {
    const { id } = payload;
    const result = await apiInstance.admins.getInterview({ id });
    if (!result.success) {
      switch (result.name) {
        case "REQUEST_NOT_FOUND":
          globalStore.dispatch("common/setAlert", {
            open: true,
            msg: "인터뷰 정보가 없습니다.",
          });
          return;
        default:
          globalStore.dispatch("common/setAlert", {
            open: true,
            msg: "다시 시도해주세요.",
          });
          return;
      }
    }
    store.commit("setInterviewItem", result.data);
    return result.data;
  },
});
