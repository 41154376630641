import globalStore from "@/store";
import Cookies from "js-cookie";
import { getSearchParam } from "@/utils/url-utils";
import { encrypt, encryptPublic } from "@/utils/text-format";
import { createForm } from "@/utils/dom-utils";
import { baseUrlV2, endpointV2 } from "@/config";
import { v4 as uuid } from "uuid";
/**
 * 앱 최초 구동시 호출
 * - 이지웰 접속 여부 체크
 * - 이지웰 헤더 요청
 * - 이지웰 설정 정보 세션 쿠키 저장
 */
export const init = async (next) => {
  // 이지웰 접속 여부 체크
  // 1. url subpath /ezwel 여부 판단
  // 2. session cookie poin-ez-cspCd 존재 여부로 판단
  let token = getSearchParam("token") || Cookies.get("poin-ez-token");
  const goUrl = getSearchParam("goUrl");
  const isEzwelEntry = window.location.pathname.includes("/ezwel") || token;
  if (!isEzwelEntry) return;

  // 이미 헤더 설정된 경우 스킵
  const alreadyEzwelEntry = globalStore.getters["common/isEzwelEntry"];
  if (alreadyEzwelEntry) return;

  // 이지웰 헤더 요청.
  const result = await globalStore.dispatch("payments/reqGetEzwelHeader", {
    token,
  });
  if (!result) {
    window.location.href = "/error";
    return;
  }
  const { userKey, cspCd, header, mobileYn, clientCd } = result;

  // 헤더 설정
  const { header: headerHtml, js, css } = header;
  if (!headerHtml) {
    window.location.href = "/error";
    return;
  }
  // 이지웰 설정 정보 세션 쿠키 저장
  Cookies.set("poin-ez-cspCd", cspCd);
  Cookies.set("poin-ez-userKey", userKey);
  Cookies.set("poin-ez-mobileYn", mobileYn);
  Cookies.set("poin-ez-token", token);
  Cookies.set("poin-ez-clientCd", clientCd);
  // 이즈웰 접속 여부 저장
  globalStore.commit("common/setIsEzwelEntry", true);
  // css 추가
  css?.forEach((href) => {
    const linkElement = document.createElement("link");
    linkElement.rel = "stylesheet";
    linkElement.href = href;
    document.head.appendChild(linkElement);
  });
  // 헤더 추가
  const headerElement = document.createElement("div");
  headerElement.innerHTML = headerHtml;
  headerElement.id = "ezwel-header";
  document.body.prepend(headerElement);

  // 설정 완료 후 페이지 이동
  if (goUrl) {
    next(goUrl);
  } else if (window.location.pathname.includes("/ezwel")) {
    // 새로고침한 경우, ezwel 경로가 아닐 수 있으므로 ezwel경로로만 제한한다.
    next(window.location.pathname.replace("/ezwel", "/"));
  }
};

const getChannelType = () => {
  /** 주문채널 (모바일 복지관 : 1002, 복지관 IPhone App : 1003, 복지관 Android App : 1004) **/
  let channelType = "";

  if (navigator.userAgent.match("APP") != null) {
    // Application인 경우
    if (navigator.userAgent.match("iPhone") != null) {
      // iOS
      channelType = 1003;
    } else if (navigator.userAgent.match("Android") != null) {
      // Android
      channelType = 1004;
    }
  } else if (
    navigator.userAgent.match("Android") != null ||
    navigator.userAgent.match("iPhone") != null
  ) {
    // 모바일웹인 경우
    channelType = 1002;
  }

  return channelType;
};

/**
 * 결제 요청
 * - 주문 정보 암호화 api 호출
 * - ezwell 결제창 호출
 */
export const order = async (orderData, goUrlInfo) => {
  let {
    mobileYn,
    goodsNm,
    goodsCd,
    payMoney,
    aspOrderNum,
    orderNm,
    orderEmail,
    orderMobile,
  } = orderData;
  const {
    priceId = "",
    couponId = "",
    promotionId = "",
    redirectUrl,
  } = goUrlInfo;

  // 결제 완료 후 호출될 api 주소 설정
  let goUrl = `${baseUrlV2}/${endpointV2.payments}/products/ext`;
  goUrl += `?paymentWay=EZWEL&campusId=${globalStore.getters["campuses/getCampusUuid"]}&productId=${goodsCd}&priceId=${priceId}&paymentId=${aspOrderNum}`;
  orderData.goUrl = goUrl;

  // 모바일 설정
  if (mobileYn === "Y") {
    const channelType = getChannelType();
    orderData.channelType = channelType;
  }

  // 결제창 이동 전 결제 데이터 생성에 필요한 파라미터를 함께 전달한다.
  const tempOrderData = {
    good_mny: payMoney,
    good_name: goodsNm,
    ordr_idxx: aspOrderNum,
    buyr_name: orderNm,
    buyr_mail: orderEmail,
    buyr_tel2: orderMobile,
    ezwelMobileYn: Cookies.get("poin-ez-mobileYn"),
    campusId: globalStore.getters["campuses/getCampusUuid"],
    productId: goodsCd,
    priceId: priceId || "",
    couponId,
    promotionId,
    userId: globalStore.getters["users/getUserUuid"],
    memberId: globalStore.getters["members/getMemberId"],
    paymentWay: "EZWEL",
    redirectUrl,
  };
  // orderInfo는 암호화하여 전달한다.
  // 암호화 데이터가 커서 하이브리드 암호화로 대칭키 함께 전달한다.
  const aesKey = uuid();
  const orderInfoEncrypted = await globalStore.dispatch(
    "payments/reqEzwelOrder",
    {
      aesKey: await encryptPublic(aesKey),
      orderData: await encrypt(orderData, aesKey),
      tempOrderData: await encrypt(tempOrderData, aesKey),
    }
  );
  if (!orderInfoEncrypted) {
    throw Error("결제 요청 중 오류가 발생했습니다.");
  }

  // 이지웰 결제창 호출 (form submit)
  createForm(
    "POST",
    "https://newasp.ezwel.com/aspReceiver.ez",
    orderInfoEncrypted
  ).submit();
};
