<template>
  <div class="sns-select__container w-full">
    <h2 class="join--title login--title mb-10">
      {{ createTitleText }}
    </h2>

    <google-button
      :button-disable="false"
      :callback="googleButtonClick"
      :label="createText('google')"
      :sns-style="this.$style.google"
      :sns-type="'google'"
    />

    <kakao-button
      :button-disable="false"
      :callback="kakaoButtonClick"
      :label="createText('kakao')"
      :sns-style="this.$style.kakao"
      :sns-type="'kakao'"
      class="kakao mt-3"
    />

    <naver-button
      :button-disable="false"
      :callback="naverButtonClick"
      :label="createText('naver')"
      :sns-style="this.$style.naver"
      :sns-type="'naver'"
      dark
      class="naver mt-3"
    />

    <facebook-button
      :button-disable="false"
      :callback="facebookButtonClick"
      :label="createText('facebook')"
      :sns-style="this.$style.facebook"
      :sns-type="'facebook'"
      dark
      class="facebook mt-3"
    />

    <apple-button
      :button-disable="false"
      :callback="appleButtonClick"
      :label="createText('apple')"
      :sns-style="this.$style.apple"
      :sns-type="'apple'"
      dark
      class="apple mt-3"
    />

    <div class="divider__wrapper">
      <span class="divider"></span>
      <span class="content">또는</span>
      <span class="divider"></span>
    </div>

    <button class="none-type" @click="routingLink" v-if="useType === 'join'">
      <img alt="email" src="https://file.poincampus.com/assets/sns/none.svg" />
      {{ createText() }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnsJoinMixin from "@/mixins/SnsJoinMixin.vue";

export default {
  name: "sns-select",
  // data by extends: useType => login, join
  components: {
    FacebookButton: () => import("@/components/button/Sns.vue"),
    googleButton: () => import("@/components/button/Sns.vue"),
    kakaoButton: () => import("@/components/button/Sns.vue"),
    naverButton: () => import("@/components/button/Sns.vue"),
    appleButton: () => import("@/components/button/Sns.vue"),
  },
  mixins: [SnsJoinMixin],
  props: {
    emailButtonCallback: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      redirectUrl: "http://localhost:3000/oauth-callback/google",
    };
  },
  computed: {
    ...mapGetters({
      locationPath: "common/getLocationPath",
      ua: "common/getUa",
      isPwa: "common/isPwa",
      isSamsungBrowser: "common/isSamsungBrowser",
      campusOnly: "common/campusOnly",
      campusDomain: "campuses/getCampusDomain",
      campusUuid: "campuses/getCampusUuid",
      auth: "auth/getAuth",
    }),
    routingName() {
      // const routingName =
      //   this.useType === "login" ? "login-form" : "join-terms-agree";
      const result = this.campusOnly
        ? "campus-join-terms-agree"
        : "join-terms-agree";
      return result;
    },
    createTitleText() {
      return this.useType === "login"
        ? "로그인"
        : !this.campusOnly
          ? "당신만의 플랫폼 사업을 시작해 보세요!"
          : "회원가입";
    },
  },
  methods: {
    routingLink() {
      const params = {
        snsType: "NONE",
        ...(this.campusOnly && { campusDomain: this.campusDomain }),
      };
      this.$router.push({
        name: this.routingName,
        params,
        query: this.$route.query,
      });
    },
    createText(snsType) {
      if (snsType === "facebook") {
        return this.useType === "login"
          ? "페이스북 로그인"
          : "페이스북 회원가입";
      } else if (snsType === "google") {
        return this.useType === "login"
          ? "Google로 로그인"
          : "Google로 회원가입";
      } else if (snsType === "naver") {
        return this.useType === "login" ? "네이버 로그인" : "네이버 회원가입";
      } else if (snsType === "kakao") {
        return this.useType === "login" ? "카카오 로그인" : "카카오 회원가입";
      } else if (snsType === "apple") {
        return this.useType === "login" ? "Apple로 로그인" : "Apple로 회원가입";
      } else {
        return this.useType === "login"
          ? "이메일로 로그인"
          : "이메일로 회원가입";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.none-type {
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $white;
  > img {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 15px;
    left: 20px;
  }
  &:before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  &:hover:before {
    opacity: 0.08;
  }
}

.divider__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;
  width: 100%;
  .divider {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $gray3;
  }

  .content {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 300;
    color: $gray6;
    margin: 0 14px;
  }
}
</style>

<style module lang="scss">
$kakaoColor: #fee500;
$naverColor: #21c759;
$fbColor: #3568b8;
$appleColor: #000000;

.google {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px $gray3;
  background-color: $white;
}

.kakao {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: $kakaoColor;
  border: 1px solid $kakaoColor;
}

.naver {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px $naverColor;
  background-color: $naverColor;
}

.facebook {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px $fbColor;
  background-color: $fbColor;
}

.apple {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px $appleColor;
  background-color: $appleColor;
}
</style>
