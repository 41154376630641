import { isEmpty } from "lodash";

const authStorageStrategy = (sessionStorage, localStorage) => {
  if (!isEmpty(sessionStorage.getAuth())) {
    return sessionStorage.getAuth();
  }

  if (!isEmpty(localStorage.getAuth())) {
    return localStorage.getAuth();
  }
  return {};
};

export default (localStorage, sessionStorage) => ({
  authenticated: false,
  auth: authStorageStrategy(sessionStorage, localStorage),
  tokenRefreshing: false,
  joinUser: null,
  credentials: null,
  credentialsExpireTime: null,
  credentialsPromise: null,
});
