import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useAlert } from "@/stores/alert";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n-composable";
import { toJsonDateFormat } from "@/utils/date-format";

export const useProductStore = defineStore("product", () => {
  const store = useStore();
  const alert = useAlert();
  const { t } = useI18n();

  const newProduct = ref(null);
  const reservation = ref(null);
  const reserveUsers = ref({ lastKey: "", items: [] });
  
  const products = ref([]);
  const lastKey = ref("");

  const product = computed(() => {
    return store.getters["products/getProduct"];
  });
  const productId = computed(() => product.value.id);
  const productName = computed(() => product.value?.name || "");

  const imageSrc = computed(() => {
    return product.value?.image?.items?.[0]?.path;
  });

  const getCompletionContents = (contents = [], x = 0.01) => {
    let newContents = [];
    const video = contents.find((content) => content.contentType === "VIDEO");
    const audio = contents.find((content) => content.contentType === "AUDIO");

    const newVideo = {
      ...video,
      compRate: (video?.compRate || 80) * x,
    };
    const newAudio = {
      ...audio,
      compRate: (audio?.compRate || 80) * x,
    };

    const videoIdx = contents.findIndex(
      (content) => content.contentType === "VIDEO"
    );
    newContents = [
      ...contents.slice(0, videoIdx),
      ...contents.slice(videoIdx + 1),
    ];

    const audioIdx = newContents.findIndex(
      (content) => content.contentType === "AUDIO"
    );
    newContents = [
      ...newContents.slice(0, audioIdx),
      ...newContents.slice(audioIdx + 1),
    ];

    newContents = [...newContents, newVideo, newAudio];

    return newContents;
  };

  async function putProduct() {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];

    const hasSubscription =
      newProduct.value.purchaseTypes?.includes("SUBSCRIPTION");
    const pauseYn = !!(
      newProduct.value.SETTING.pauseDays && newProduct.value.SETTING.pauseTimes
    );

    const result = await this.api.products.putProduct({
      image: newProduct.value.image,
      period: newProduct.value.period,
      limit: newProduct.value.limit,
      price: newProduct.value.price,
      startDttm: new Date(newProduct.value.startDttm).toJSON(),
      endDttm: new Date(newProduct.value.endDttm).toJSON(),
      communityIds: newProduct.value.communityIds,
      DESC: {
        ...newProduct.value.DESC,
        campusId,
      },
      tags: newProduct.value.tags,
      name: newProduct.value.name,
      SETTING: {
        ...newProduct.value.SETTING,
        campusId,
        completion: {
          completionType: newProduct.value.SETTING.completion.completionType,
          completionUnit: newProduct.value.SETTING.completion.completionUnit,
          compRate:
            newProduct.value.SETTING.completion.completionUnit === "RATE"
              ? newProduct.value.SETTING.completion.compRate * 0.01
              : newProduct.value.SETTING.completion.compRate,
          contents: getCompletionContents(
            newProduct.value.SETTING.completion.contents,
            0.01
          ),
        },
        pauseYn,
        pauseDays: pauseYn ? newProduct.value.SETTING.pauseDays : 0,
        pauseTimes: pauseYn ? newProduct.value.SETTING.pauseTimes : 0,
        reqContsYn:
          newProduct.value.SETTING.reqContsYn &&
          newProduct.value.SETTING.reqConts?.length > 0,
      },
      categoryId: newProduct.value.categoryId,
      productType: newProduct.value.productType,
      campusId,
      memberId,
      publishYn: newProduct.value.classfiedYn
        ? false
        : newProduct.value.publishYn,
      freeYn: newProduct.value.freeYn,
      groupPriceYn: newProduct.value.groupPriceYn,
      groupPrice: newProduct.value.groupPrice,
      id: newProduct.value.id,
      classfiedYn: newProduct.value.classfiedYn || false,
      ...(newProduct.value.joinType && { joinType: newProduct.value.joinType }),
      ...(newProduct.value.openDttm && {
        openDttm: new Date(newProduct.value.openDttm).toJSON(),
      }),
      ...(newProduct.value.purchaseTypes && {
        purchaseTypes: newProduct.value.purchaseTypes,
      }),
      ...(newProduct.value.refundPolicyId && {
        refundPolicyId: newProduct.value.refundPolicyId,
      }),
      ...(hasSubscription && { subsPrice: newProduct.value.subsPrice }),
      ...(hasSubscription && { subsMonths: newProduct.value.subsMonths }),
      custAdditional: newProduct.value.custAdditional,
      custAddType: newProduct.value.custAddType,
    });
    return result;
  }

  async function getProduct(payload) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const { id, local = false } = payload;

    const result = await this.api.products.getProduct({
      campusId,
      memberId,
      id,
    });
    if (result?.success) {
      const { data } = result;
      if (!local) {
        newProduct.value = {
          ...data,
          SETTING: {
            ...data.SETTING,
            completion: {
              compRate:
                data.SETTING?.completion?.completionUnit === "RATE"
                  ? Math.floor(
                      (data.SETTING?.completion?.compRate || 0.8) * 100
                    )
                  : data.SETTING?.completion?.compRate || 1,
              contents: getCompletionContents(
                data.SETTING?.completion?.contents,
                100
              ),
              completionType:
                data.SETTING?.completion?.completionType || "AUTO",
              completionUnit:
                data.SETTING?.completion?.completionUnit || "RATE",
            },
          },
        };
        store.commit("products/setProduct", result.data);
      }
    }
    return result;
  }

  // 수익 분배 설정
  async function putProductProfitShare(payload = {}) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const { partnerYn, partnerLinkYn, partnerLinkRate, targets } = payload;

    if (targets?.length < 1) {
      return { success: false };
    }

    const result = await this.api.products.putPartners({
      campusId,
      memberId,
      partnerYn,
      partnerLinkYn,
      partnerLinkRate,
      targets,
    });

    if (!result.success) {
      let title = "설정 실패";
      let msg = "";
      switch (result.code) {
        case "ACCESS_DENIED":
          msg = t("error.access");
          break;
        case "UNAUTHORIZE":
          msg = t("error.authorize");
          break;
        case "INVALID_PARAMETER":
          msg = t("error.invalid");
          break;
        case "NOT_SUPPORTED":
          msg = t("error.notSupportedAddon", { type: "제휴 마케팅" });
          break;
        default:
          msg = t("error.default");
      }

      alert.open({
        title,
        msg,
      });
    }

    return result;
  }

  async function getSearchPartners(payload = {}) {
    const params = {
      campusId: store.getters["campuses/getCampusUuid"],
      memberId: store.getters["members/getMemberId"],
      ...payload,
    };
    const result = await this.api.products.getSearchPartners(params);
    return result;
  }

  const categories = ref([]);
  async function getCategories(payload = {}) {
    categories.value = [];
    const res = await this.api.products.getCategories({
      campusId: store.getters["campuses/getCampusUuid"],
      ...(payload?.manageYn && { manageYn: payload.manageYn }),
    });
    if (res?.success) {
      categories.value = res.data?.categories || [];
    }
    return res;
  }
  async function postCategories(payload = {}) {
    const memberId = store.getters["members/getMemberId"];
    const campusId = store.getters["campuses/getCampusUuid"];
    const { categories = [] } = payload;
    const result = await this.api.products.postCategories({
      campusId,
      memberId,
      categories,
    });
    if (result.success) {
      categories.value = result.data.categories || [];
    }
    return result;
  }

  async function putExtra({ productId, changeType, targets }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];

    const result = await this.api.products.putExtra({
      campusId,
      ...(productId && { productId }),
      ...(changeType && { changeType }),
      ...(targets && { targets }),
      memberId,
    });

    return result;
  }

  async function getPromotions(payload) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];

    const res = await this.api.products.getPromotions({
      memberId,
      campusId,
      ...payload,
    });

    return res;
  }

  async function getDetail({
    id,
    userId = store.getters["users/getUserUuid"],
    campusId = store.getters["campuses/getCampusUuid"],
    memberId = store.getters["members/getMemberId"],
    calcUserCoupons = false,
    publicYn = false,
  }) {
    if (!id) {
      return { success: false };
    }

    const isMember = memberId && store.getters["members/isCampusJoinMember"];

    const getDetail =
      isMember && !publicYn
        ? this.api.products.getDetail
        : this.api.products.getDetailPublic;

    const result = await getDetail({
      id,
      ...(userId && { userId }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(calcUserCoupons && { calcUserCoupons }),
    });
    if (result.success) {
      store.commit("products/setProduct", result.data);
      store.commit("members/setProductMember", result.data.productMember);
    }
    return result;
  }

  // 예약 목록 조회
  async function getReservations({ id, fetchType }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.getReservationUsers({
      id,
      campusId,
      memberId,
      fetchType,
      ...(fetchType === "EXCEL" && { excelType: "RESERVE" }),
    });
    return res;
  }

  // 예약 정보 조회 (reservation에 저장)
  async function getReservation({ id }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.getProduct({ id, campusId, memberId });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약 정보 추가
  async function postReservation({ name = "예약" }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const today = new Date();
    const res = await this.api.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/Reservation.png",
            key: "DEFAULT",
          },
        ],
      },
      communityIds: [],
      DESC: {
        campusId,
        introduce: "",
        inquiry: {
          etc: "",
          contact: "",
        },
      },
      OPTIONS: [
        {
          name: "예약 옵션",
          contentType: "OPTION",
          publishYn: false,
          image: {
            conts: "",
            textColor: "black",
            horizontal: "CENTER",
            nameYn: false,
            items: [
              {
                path: "https://file.poincampus.com/assets/sample/ReservationOption.png",
                key: "DEFAULT",
              },
            ],
          },
          startDttm: new Date(99999999999999).toJSON(),
          endDttm: new Date(99999999999999).toJSON(),
          conts: "",
          campusId,
          toDttm: today.toJSON(),
          fromDttm: today.toJSON(),
          inquiry: {
            etc: "",
            contact: "",
          },
          place: {
            pre: "",
            post: "",
            lon: 0,
            lat: 0,
            desc: "",
          },
          seq: 0,
          desc: "",
          items: [
            {
              contentType: "PRICE",
              name: "가격 옵션",
              desc: "",
              campusId,
              price: 1000,
              groupPrices: [],
              limit: 0,
              seq: 0,
            },
          ],
        },
      ],
      custAddType: "GLOBAL",
      limit: 0,
      name,
      productType: "RESERVATION",
      campusId,
      memberId,
      classfiedYn: false,
      refundPolicyId: "",
      publishYn: false,
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
    });
    return res;
  }

  // 예약 정보 수정
  async function putReservation() {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const {
      image,
      refundPolicyId,
      DESC,
      custAddType,
      custAdditional,
      tags = "",
      name,
      classfiedYn,
      categoryId,
      productType,
      id,
      taxFreeYn,
      freeYn,
      publishYn,
      groupPriceYn,
      groupPrice,
    } = reservation.value;
    const res = await this.api.products.putProduct({
      image,
      refundPolicyId,
      custAddType,
      custAdditional,
      DESC,
      tags,
      name,
      classfiedYn,
      categoryId,
      productType,
      id,
      campusId,
      memberId,
      taxFreeYn,
      freeYn,
      publishYn,
      groupPriceYn,
      ...(groupPrice && { groupPrice }),
    });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약 순서 변경
  async function updateReservationOptionsSeq() {
    const { id, OPTIONS = [] } = reservation.value;
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const newOptions = OPTIONS.map((option) => {
      return {
        id: option.id,
        seq: option.seq,
        contentType: "OPTION",
      };
    });
    const res = await this.api.products.putProduct({
      id,
      campusId,
      memberId,
      OPTIONS: newOptions,
      productType: "RESERVATION",
    });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약 옵션 정보 추가
  async function postReservationOption({ name = "예약 옵션" }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const { id, freeYn, OPTIONS = [] } = reservation.value;
    const today = new Date();
    const res = await this.api.products.putProduct({
      id,
      campusId,
      memberId,
      productType: "RESERVATION",
      OPTIONS: [
        {
          name,
          contentType: "OPTION",
          publishYn: false,
          image: {
            conts: "",
            textColor: "black",
            horizontal: "CENTER",
            nameYn: false,
            items: [
              {
                path: "https://file.poincampus.com/assets/sample/ReservationOption.png",
                key: "DEFAULT",
              },
            ],
          },
          startDttm: new Date(99999999999999).toJSON(),
          endDttm: new Date(99999999999999).toJSON(),
          conts: "",
          campusId,
          fromDttm: today.toJSON(),
          toDttm: today.toJSON(),
          inquiry: {
            etc: "",
            contact: "",
          },
          place: {
            pre: "",
            post: "",
            lon: 0,
            lat: 0,
            desc: "",
          },
          seq: OPTIONS.length,
          desc: "",
          items: [
            {
              contentType: "PRICE",
              name: "가격 옵션",
              desc: "",
              campusId,
              price: freeYn ? 0 : 1000,
              groupPrices: [],
              limit: 0,
              seq: 0,
            },
          ],
        },
      ],
    });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약 옵션 정보 수정
  async function putReservationOption({ option }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.putProduct({
      id: reservation.value.id,
      campusId,
      memberId,
      OPTIONS: [{ ...option, campusId }],
      productType: "RESERVATION",
    });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약 옵션 정보 복제
  async function copyReservationOption({ option }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.putProduct({
      campusId,
      memberId,
      id: reservation.value.id,
      productType: "RESERVATION",
      OPTIONS: [
        {
          name: option.name.trimRight() + "의 복사본",
          contentType: "OPTION",
          publishYn: false,
          image: option.image,
          startDttm: new Date(99999999999999).toJSON(),
          endDttm: new Date(99999999999999).toJSON(),
          conts: option.conts,
          campusId,
          toDttm: option.toDttm,
          fromDttm: option.fromDttm,
          inquiry: option.inquiry,
          place: option.place,
          seq: reservation.value?.OPTIONS?.length || 0,
          desc: option.desc,
          items: option.items,
        },
      ],
    });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약 옵션 정보 삭제
  async function deleteReservationOption({ id }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.putProduct({
      campusId,
      memberId,
      id: reservation.value.id,
      DELETE_OPTIONS: [id],
      productType: "RESERVATION",
    });
    if (res?.success) {
      reservation.value = res.data;
    }
    return res;
  }

  // 예약자 목록 조회
  async function getReserveUsers(payload) {
    const {
      campusId = store.getters["campuses/getCampusUuid"],
      memberId = store.getters["members/getMemberId"],
      id,
      fetchType,
      keyword,
      limit,
      optionId,
      priceId,
      excelType,
      fileName,
      productType,
      startDttm,
      endDttm,
      sort,
      order,
      first = true,
    } = payload;
    if (first) {
      reserveUsers.value.lastKey = "";
      reserveUsers.value.items = [];
    }
    const lastKey = reserveUsers.value.lastKey;
    const res = await this.api.products.getReservationUsers({
      id,
      campusId,
      memberId,
      fetchType,
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(startDttm && { startDttm: toJsonDateFormat(startDttm, true) }),
      ...(endDttm && { endDttm: toJsonDateFormat(endDttm) }),
      ...(optionId && { optionId }),
      ...(priceId && { priceId }),
      ...(excelType && { excelType }),
      ...(fileName && { fileName }),
      ...(productType && { productType }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(lastKey && { lastKey }),
    });
    if (res?.success) {
      if (res?.data) {
        reserveUsers.value.lastKey = res.data?.lastKey || "";
        reserveUsers.value.items = [
          ...reserveUsers.value.items,
          ...res.data?.results,
        ];
      }
    } else {
      const alert = useAlert();
      let msg = res?.message;
      switch (res?.name) {
        case "UNAUTHORIZE":
          msg = "조회 권한이 없습니다.";
          break;
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "예약 정보를 찾을 수 없습니다.";
          break;
        default:
          msg = "오류 발생";
          break;
      }
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return res;
  }

  async function getProductsStaff(payload = {}) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];

    if (payload.first) {
      products.value = [];
      lastKey.value = "";
    }

    const res = await this.api.products.getProducts({
      campusId,
      memberId,
      ...(lastKey.value && { lastKey: lastKey.value }),
      ...payload,
    });
    if (res.success) {
      products.value = [...products.value, ...res.data.results];
      lastKey.value = res.data.lastKey || "";
    }
    return res;
  }

  async function cloneProduct({ id }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.postProductClone({
      id,
      campusId,
      memberId,
    });

    return res;
  }

  async function deleteProduct({ id }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const res = await this.api.products.deleteProduct({
      memberId,
      campusId,
      id,
    });

    return res;
  }

  async function updateProduct(payload) {
    const res = await this.api.products.putProduct({
      ...payload,
      startDttm: payload.startDttm
        ? new Date(payload.startDttm).toJSON()
        : new Date(99999999999999).toJSON(),
      endDttm: payload.endDttm
        ? new Date(payload.endDttm).toJSON()
        : new Date(99999999999999).toJSON(),
    });
    return res;
  }

  async function putStatus(payload = {}) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const { productType, publishYn, startDttm, endDttm, id } = payload;

    const res = await this.api.products.putStatus({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(startDttm && { startDttm }),
      ...(endDttm && { endDttm }),
      productType,
      id,
      publishYn,
    });

    return res;
  }

  return {
    products,
    lastKey,
    product,
    newProduct,
    reservation,
    reserveUsers,
    productId,
    productName,
    imageSrc,
    putProduct,
    getProduct,
    putProductProfitShare,
    getSearchPartners,
    categories,
    getCategories,
    postCategories,
    putExtra,
    getDetail,
    getPromotions,
    getReservations,
    getReservation,
    postReservation,
    putReservation,
    updateReservationOptionsSeq,
    postReservationOption,
    putReservationOption,
    copyReservationOption,
    deleteReservationOption,
    getReserveUsers,
    getProductsStaff,
    cloneProduct,
    deleteProduct,
    updateProduct,
    putStatus,
  };
});
