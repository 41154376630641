import { poinChPluginKey } from "@/config";

export default {
  setKeepLogin: (state, keepLogin) => {
    state.keepLogin = keepLogin;
  },
  setLocationPath: (state, path) => {
    state.locationPath = path;
  },
  setCurrentMenu: (state, menu) => {
    state.menu = {
      menus: state.menu.menus,
      current: menu,
    };
  },
  setWindowWidth: (state, width) => {
    state.windowWidth = width;
    if (width < 600) {
      state.isMobile = true;
      state.md = false;
      state.sm = true;
    } else if (width < 960) {
      state.isMobile = true;
      state.md = true;
      state.sm = false;
    } else {
      state.isMobile = false;
      state.md = false;
      state.sm = false;
    }
  },
  setUa: (state, ua) => {
    if (ua.match(/kakaotalk/i)) {
      state.ua = "kakaotalk";
    } else if (ua.match(/instagram/i)) {
      state.ua = "instagram";
    } else if (ua.match(/android/i)) {
      state.ua = "android";
    } else if (ua.match(/iphone|ipad|ipod/i)) {
      state.ua = "ios";
    } else {
      state.ua = "desktop";
    }
  },
  setCampusOnly: (state, yn) => {
    state.campusOnly = yn;
  },
  setGlobalComponent: (state, comp) => {
    state.globalComponent = comp;
  },
  setLoading: (state, loading) => {
    state.loading = loading;
  },
  setShowGlobalMenu: (state, yn) => {
    state.showGlobalMenu = yn;
  },
  setIsPoinWebview: (state, yn) => {
    state.isPoinWebview = yn;
  },
  setIsPwa: (state, yn) => {
    state.isPwa = yn;
  },
  setIsSamsungBrowser: (state, yn) => {
    state.isSamsungBrowser = yn;
  },
  setIsPoinAos: (state, yn) => {
    state.isPoinAos = yn;
  },
  setAlert: (state, alert) => {
    state.alert = { ...alert };
  },
  setConfirm: (state, confirm) => {
    state.confirm = { ...confirm };
  },
  setToast: (state, toast) => {
    state.toast = { ...toast };
  },
  setAdminToast: (state, adminToast) => {
    state.adminToast = { ...adminToast };
  },
  setSelectedOptions: (state, options) => {
    state.selectedOptions = options;
  },
  clearSelectedOptions: (state) => {
    state.selectedOptions = [];
  },
  updatePluginKeyConnection: (state, key) => {
    state.connectedChPluginKey = key;
  },
  disconnectPluginKey: (state) => {
    state.connectedChPluginKey = poinChPluginKey;
  },
  resetChannel: (state, yn) => {
    state.resetChannelFlag = yn;
  },
  setVideoPanel: (state, value) => {
    state.videoPanel = value;
  },
  setVideoPanelOpen: (state, value) => {
    state.videoPanelOpen = value;
  },
  setVideoPanelHeight: (state, value) => {
    state.videoPanelHeight = value;
  },
  setVideoFiles: (state, files = []) => {
    state.videoFiles = [...files];
  },
  updateVideoFiles: (state, files = []) => {
    state.videoFiles = [...state.videoFiles, ...files];
  },
  activeStartMenu: (state, openYn) => {
    state.openPoinStartMenu = openYn;
  },
  setUserCompleteProductIds: (state, ids = []) => {
    state.userCompleteProductIds = ids;
  },
  setIsEzwelEntry: (state, isEzwelEntry) => {
    state.isEzwelEntry = isEzwelEntry;
  },
  setUserProductsProgress: (state, arr) => {
    state.userProductsProgress = arr;
  },
};
