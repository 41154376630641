const getters = {
  getAuthenticated: (state) => state.authenticated,
  getAuth: (state) => {
    return state.auth;
  },
  getTokenRefreshing: (state) => {
    return state.tokenRefreshing ?? false;
  },
  getSnsType: (_, getters) => {
    return getters.getAuth?.snsType ?? "";
  },
  getCognitoId: (_, getters, ___, rootGetters) => {
    return rootGetters["common/isPoinWebview"] || getters.getAuth?.isAdmin
      ? getters.getAuth.cognitoId
      : getters.getAuth.identityId?.replace("ap-northeast-2:", "");
  },
  getJoinUser: (state) => {
    return state.joinUser;
  },
  getCredentials: (state) => {
    return state.credentials;
  },
  isCredentialsValid: (state) => () => {
    const now = Date.now();
    return (
      state.credentials &&
      state.credentialsExpireTime &&
      now < state.credentialsExpireTime - 600000
    );
  },
  getCredentialsPromise: (state) => state.credentialsPromise,
  getAccessKeyId: (state) => {
    return state.credentials?.accessKeyId;
  },
};

export default getters;
