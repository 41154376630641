import globalStore from "@/store";
import { poinChPluginKey } from "@/config";

export default {
  getKeepLogin: (state) => state.keepLogin,
  getLocationPath: (state) => state.locationPath,
  getMenus: (state) => {
    if (globalStore.getters["users/isInstructor"]) {
      return state.menu.menus;
    }
    return state.menu.menus.slice(0, 1);
  },
  getCurrentMenu: (state) => state.menu.current,
  getWindowWidth: (state) => state.windowWidth,
  isMobile: (state) => state.isMobile,
  getUa: (state) => {
    return state.ua;
  },
  campusOnly: (state) => state.campusOnly,
  isWebview: (state) => state.globalComponent === "WEBVIEW",
  isLanding: (state) => state.globalComponent === "LANDING",
  isWeb: (state) => state.globalComponent === "WEB",
  getGlobalComponent: (state) => state.globalComponent,
  loading: (state) => state.loading,
  showGlobalMenu: (state) => state.showGlobalMenu,
  isPoinWebview: (state) => state.isPoinWebview,
  isPoinAos: (state) => state.isPoinAos,
  isPwa: (state) => state.isPwa,
  isSamsungBrowser: (state) => state.isSamsungBrowser,
  alert: (state) => state.alert,
  confirm: (state) => state.confirm,
  toast: (state) => state.toast,
  adminToast: (state) => state.adminToast,
  isMd: (state) => state.md,
  isSm: (state) => state.sm,
  getSelectedOptions: (state) => state.selectedOptions,
  poinChannelConnected: (state) =>
    state.connectedChPluginKey === poinChPluginKey,
  getResetChannelFlag: (state) => state.resetChannelFlag,
  getChPluginKey: (state) => state.connectedChPluginKey,
  getVideoPanel: (state) => state.videoPanel,
  getVideoPanelOpen: (state) => state.videoPanelOpen,
  getVideoPanelHeight: (state) => state.videoPanelHeight,
  getVideoFiles: (state) => state.videoFiles,
  openPoinStartMenu: (state) => state.openPoinStartMenu,
  getUserCompleteProductIds: (state) => state.userCompleteProductIds,
  getUserProductsProgress: (state) => state.userProductsProgress,
  isEzwelEntry: (state) => state.isEzwelEntry,
};
