import axios from "axios";
import qs from "qs";

import { authExpriedTimeCheck, endpoint, endpointV2 } from "@/config";
import globalStore from "@/store";
import { getAwsSigV4Headers } from "@/utils";
import { checkShutdown } from "@/router/utils/auth-utils";
import apis from "./modules";
import createResponse from "./create-response";
import createErrorRes from "./create-error";
import { isValidJSON } from "@/utils/validation";
import { getCredentials } from "./getCredentials";

function isExpired(config) {
  // return true
  if (
    config.url === `${endpoint.auth}/auth/refresh` ||
    config.url === `${endpoint.auth}/auth/login` ||
    config.url === `${endpoint.auth}/auth/tokenRefresh`
  ) {
    return false;
  }

  const auth = globalStore.getters["auth/getAuth"];
  const authenticated = globalStore.getters["auth/getAuthenticated"];
  if (!authenticated || !auth || !auth.expireTime) {
    return false;
  }

  const snsType = globalStore.getters["auth/getSnsType"];
  const email = globalStore.getters["users/getUserEmail"];
  if (!snsType || snsType === "undefined" || snsType === "NONE") {
    if (!email) {
      return false;
    }
  }

  const expireDate = new Date(auth.expireTime);
  const dt = (expireDate.getTime() - Date.now()) / 1000;
  return dt < authExpriedTimeCheck;
}

// Refresh Token 진행
// 동시에 실행되는 다른 request 는 대기
async function refreshToken(config) {
  const tokenRefreshing = globalStore.getters["auth/getTokenRefreshing"];
  if (!tokenRefreshing) {
    globalStore.dispatch("auth/setTokenRefreshing", {
      tokenRefreshing: true,
    });
    await globalStore.dispatch("auth/reqRefreshToken");
    globalStore.dispatch("auth/setTokenRefreshing", {
      tokenRefreshing: false,
    });
  } else {
    await new Promise((resolve, reject) => {
      const timerId = setInterval(() => {
        const tokenRefreshing = globalStore.getters["auth/getTokenRefreshing"];
        if (!tokenRefreshing) {
          clearInterval(timerId);
          resolve(true);
        }
      }, 100);
    });
  }

  const refreshedAuth = globalStore.getters["auth/getAuth"];
  const { method, params, baseURL, url, data } = config;
  config.headers = getAwsSigV4Headers({
    auth: refreshedAuth,
    method,
    params,
    url: `${baseURL}/${url}`,
    data: isValidJSON(data) ? JSON.parse(data) : data,
  });

  return config;
}

export const defaultReqInterceptor = async (config) => {
  const isWebview = globalStore.getters["common/isPoinWebview"];
  const isAdmin = globalStore.getters["auth/getAuth"]?.isAdmin;
  if ((isWebview || isAdmin) && isExpired(config)) {
    config = await refreshToken(config);
  }

  checkShutdown();
  return config;
};

const defaultResInterceptor = createResponse;

class ApiClient extends axios.Axios {
  /**
   @constructor
   @param {object} config
   @param {object} options
   */
  constructor(config = {}, options, categories) {
    const axiosConfig = ApiClient.transformToAxiosConfig(config, options);
    super(axiosConfig);

    this.authConfig = config;
    this.axiosConfig = axiosConfig;
    this.tokenRefreshing = false;
    this.tokenUpdateTime = null;
    this.tryRefreshTokenCount = 0;
    if (categories) {
      this.categories = Object.keys(apis)
        .filter((key) => {
          const findItem = categories.findIndex((category) => category === key);
          return findItem > -1 && apis[key];
        })
        .map((key) => key);
    } else {
      this.categories = Object.keys(apis).map((key) => key);
    }
    this.attachAPIs(this.categories);
    this.updateAuthConfig = this.updateAuthConfig.bind(this);
    this.updateResponseInterceptors =
      this.updateResponseInterceptors.bind(this);
    this.updateRequestInterceptors = this.updateRequestInterceptors.bind(this);

    this.updateRequestInterceptors();
    this.updateResponseInterceptors();
    this.setParamsSerializer.call(this);
  }

  static transformToAxiosConfig(config, options) {
    let authConfig = config;
    let axiosOptions = options;
    if (!authConfig || typeof authConfig !== "object") {
      authConfig = {};
    }

    if (!axiosOptions || typeof axiosOptions !== "object") {
      axiosOptions = {};
      if (!axiosOptions.baseURL) {
        throw new Error("please pass baseUrl");
      }
    }

    const baseURL = axiosOptions.baseURL;
    const axiosConfig = Object.assign(
      {
        baseURL,
        headers: {
          "Content-Type": "application/json",
        },
      },
      axiosOptions
    );
    if (authConfig.token) {
      axiosConfig.headers.Authorization = `Bearer ${authConfig.token}`;
    }

    return axiosConfig;
  }

  attachAPIs(categories) {
    categories.forEach((category) => {
      this[category] = {};
      Object.keys(apis[category]).forEach((methodName) => {
        this[category][methodName] = apis[category][methodName].bind(this);
      });
    });
  }

  async getMethod(method, isSigV4, { url, params = {}, data = {}, headers }) {
    const campusId = globalStore.getters["campuses/getCampusUuid"];
    const memberId = globalStore.getters["members/getMemberId"];
    const target = method === "get" ? params : data;
    if (campusId && !target.campusId) {
      target.campusId = campusId;
    }
    if (
      method === "get" &&
      url === `${endpointV2.campuses}/` &&
      target.domain
    ) {
      delete target.campusId;
    }
    if (memberId && !target.memberId) {
      target.memberId = memberId;
    }
    if (isSigV4) {
      const isWebview = globalStore.getters["common/isPoinWebview"];
      const auth = globalStore.getters["auth/getAuth"];
      const isAdmin = auth?.isAdmin;
      let newAuth;

      if (!isWebview && !isAdmin) {
        const credentials = await getCredentials({
          identityId: auth.identityId,
          token: auth.token,
        });
        newAuth = {
          accessKeyId: credentials?.accessKeyId,
          secretAccessKey: credentials?.secretAccessKey,
          sessionToken: credentials?.sessionToken,
        };
      }

      const signedHeaders = getAwsSigV4Headers({
        auth: isWebview || isAdmin ? auth : newAuth,
        method,
        params,
        url: `${this.axiosConfig.baseURL}/${url}`,
        data,
        headers,
      });
      return this.request({
        url,
        method,
        headers: signedHeaders,
        ...(params && { params }),
        ...(data && { data: JSON.stringify(data) }),
        validateStatus: function (status) {
          return status < 399; // 상태 코드가 400 이상일 때 에러로 처리
        },
      });
    }
    return this.request({
      url,
      method,
      headers,
      ...(params && { params }),
      ...(data && { data: JSON.stringify(data) }),
      validateStatus: function (status) {
        return status < 399; // 상태 코드가 400 이상일 때 에러로 처리
      },
    });
  }

  getAuthConfig() {
    return this.authConfig;
  }

  updateAuthConfig(config) {
    if (!config || typeof config !== "object" || !config.token) {
      throw new Error("token must be included");
    }

    this.authConfig = Object.assign(this.authConfig, config);
    this.setAuthorizationHeader(config.token);
  }

  getTryRefreshTokenCount() {
    return this.tryRefreshTokenCount;
  }

  setTryRefreshTokenCount(count) {
    this.tryRefreshTokenCount = count;
  }

  async tokenRefresh() {
    const refreshTokenReqResultSuccess = await globalStore.dispatch(
      "auth/reqRefreshToken"
    );
    if (refreshTokenReqResultSuccess) {
      this.setTryRefreshTokenCount(this.getTryRefreshTokenCount() + 1);
    }
    return refreshTokenReqResultSuccess;
  }

  setAuthorizationHeader(token) {
    this.defaults.headers.Authorization = `Bearer ${token}`;
  }

  setParamsSerializer() {
    this.defaults.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    };
  }

  setBaseUrl(baseUrl) {
    this.defaults.baseURL = baseUrl;
  }

  getBaseUrl() {
    return this.defaults.baseURL;
  }

  getUpdateTokenTime() {
    return this.tokenUpdateTime;
  }

  updateTokenTime(time) {
    // update time check
    // update time을 저장해서 한시간 이내가 아니면 tri
    // 한시간 이내면 거절
    this.tokenUpdateTime = time;
  }

  updateRequestInterceptors(reqInterceptor = defaultReqInterceptor) {
    if (this.interceptors.request.handlers.length) {
      this.interceptors.request.handlers.splice(0, 1);
    }

    if (this.getBaseUrl() !== "https://api.vimeo.com") {
      this.interceptors.request.use(reqInterceptor, function (error) {
        // Do something with request error
        return Promise.reject(error);
      });
    }
  }

  updateResponseInterceptors(
    resInterceptor = defaultResInterceptor,
    errorHandle = createErrorRes
  ) {
    if (this.interceptors.response.handlers.length) {
      this.interceptors.response.handlers.splice(0, 1);
    }
    this.interceptors.response.use(resInterceptor, async (error) => {
      const bindErrorHandle = errorHandle.bind(this);
      const result = bindErrorHandle(error);
      return result;
    });
  }
}

export default ApiClient;
