import { useStore } from "@/store";

export const checkAppVersion = async () => {
  const store = useStore();
  const version = await store.dispatch("admins/reqGetAppVersion");
  const currentVersion = __APP_VERSION__;

  if (version && currentVersion && version !== currentVersion) {
    const lastReloadTime = window.localStorage.getItem("lastVersionReloadTime");
    const now = Date.now();
    const WAIT_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

    if (!lastReloadTime || now - parseInt(lastReloadTime) > WAIT_TIME) {
      window.localStorage.setItem("lastVersionReloadTime", now.toString());
      window.location.reload();
    }
  }
};
