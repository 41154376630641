import Cookies from "js-cookie";

function getExpires(hours = 1) {
  const now = new Date();
  const expires = new Date(now.getTime() + 60 * 60 * 1000 * hours);
  return expires;
}

// 특정 이름의 쿠키를 가져오는 함수
export function getCookie(name) {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];

  return cookieValue ? decodeURIComponent(cookieValue) : undefined;
}

export const poinReferrer = {
  set: () => {
    // 인앱 브라우저에서 외부 브라우저 호출시, 레퍼러 유실을 막기 위해 쿼리 파라미터에 레퍼러를 추가함.
    const urlParams = new URLSearchParams(window.location.search);
    const partnerCode = urlParams.get("pcd");
    if (!partnerCode) {
      return;
    }
    const inAppRef = urlParams.get("poin-ref");
    const referrer = inAppRef || document.referrer;
    if (!referrer) {
      return;
    }
    if (referrer.startsWith(window.location.origin)) {
      return;
    }
    const blackList = [
      "https://accounts.google.co.kr/",
      "https://logins.daum.net/",
      "https://nid.naver.com/",
      "https://www.facebook.com/",
      "https://appleid.apple.com/",
    ];
    if (blackList.includes(referrer)) {
      return;
    }
    const expires = getExpires(24 * 7); // 7일간 유효
    Cookies.set("poin-referrer", referrer, { expires });
    // removeSearchParam("poin-ref");
    return referrer;
  },
  get: () => {
    const referrer = Cookies.get("poin-referrer");
    return referrer;
  },
  clear: () => {
    Cookies.remove("poin-referrer");
  },
};

export const poinPartnerCode = {
  set: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const partnerCode = urlParams.get("pcd");
    if (!partnerCode) {
      return;
    }
    const expires = getExpires(24 * 7); // 7일간 유효
    Cookies.set("poin-partner-code", partnerCode, { expires });
    // removeSearchParam("pcd");
    return partnerCode;
  },
  get: () => {
    const partnerCode = Cookies.get("poin-partner-code");
    return partnerCode;
  },
  clear: () => {
    Cookies.remove("poin-partner-code");
  },
};

export const poinOldPostedUrl = {
  // 파트너 링크 로그를 5분 내에 중복 호출하지 않도록 하기 위해, 기존 호출했던 url을 저장한다.
  set: () => {
    Cookies.set("poin-old-posted-url", window.location.href, {
      expires: getExpires(5 / 60),
    });
  },
  get: () => {
    return Cookies.get("poin-old-posted-url");
  },
  clear: () => {
    Cookies.remove("poin-old-posted-url");
  },
};
export const poinLinkLog = {
  set: ({ pk, id }) => {
    Cookies.set("poin-link-log", JSON.stringify({ pk, id }), {
      expires: getExpires(24 * 7),
    });
  },
  get: () => {
    const linkLog = Cookies.get("poin-link-log");
    try {
      return linkLog ? JSON.parse(linkLog) : {};
    } catch (e) {
      console.log(e);
    }
  },
  clear: () => {
    Cookies.remove("poin-link-log");
  },
};

export const clearAllPoinLinkCookies = () => {
  poinReferrer.clear();
  poinPartnerCode.clear();
  poinOldPostedUrl.clear();
  poinLinkLog.clear();
};
