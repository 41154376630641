import { getDateFormat } from "@/utils/date-format";
import { Authority, MemberStatus, ProgressStatus } from "@/enums/membersEnum";
import { ContentType } from "@/enums/productsEnum";
import { i18n } from "@/i18n/index";

export default {
  getNewProduct(state) {
    return state.newProduct;
  },
  getProduct(state) {
    return state.product;
  },
  getProductsStaff(state) {
    return state.productsStaff.items;
  },
  getProductsStaffLastKey(state) {
    return state.productsStaff.lastKey;
  },
  getProductDelegators(state) {
    return state.delegators;
  },
  getCustAdditional(state) {
    return state.custAdditional.items || [];
  },
  getPromotions(state) {
    return state.promotions;
  },
  getPromotionLastkey(state) {
    return state.promotionLastkey;
  },
  getPromotion(state) {
    return state.promotion;
  },
  getCheckPrices(state) {
    return state.checkPrices;
  },
  getFilteredContents(state, __, ___, rootGetters) {
    if (
      state.product?.SETTING?.options?.sequencialYn &&
      rootGetters["members/isProductMember"]
    ) {
      let wait = false;

      const isComplete = (content) => {
        const group = [ProgressStatus.COMPLETE, ProgressStatus.COMPLETE_FORCE];
        if (group.includes(content?.activity?.completeStatus)) {
          return true;
        }
        return false;
      };

      const isRealContent = (content) => {
        const group = [ContentType.CURRICULUM, ContentType.SECTION];
        if (group.includes(content.contentType)) {
          return false;
        }
        return true;
      };

      const setWait = (content) => {
        if (!isRealContent(content)) {
          return;
        }
        if (isComplete(content)) {
          return;
        }
        wait = true;
      };

      const arr = [];
      const newContents =
        state.product?.CONTENTS?.length > 0
          ? structuredClone(state.product?.CONTENTS)
          : [];
      newContents?.forEach((content) => {
        const success = isComplete(content) || content.previewYn;
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            const success = isComplete(child) || child.previewYn;
            child.wait = success ? false : wait;
            if (!wait) {
              setWait(child);
            }
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                const success = isComplete(descendant) || descendant.previewYn;
                descendant.wait = success ? false : wait;
                if (!wait) {
                  setWait(descendant);
                }
              });
            }
          });
        }
        arr.push({ ...content, wait: success ? false : wait });
        if (!wait) {
          setWait(content);
        }
      });
      return arr;
    }
    return state.product?.CONTENTS || [];
  },
  
  // @soo todo :: productDetail / getProductOffer 통일
  // Product Detail Getters
  productDetail(state, getters, __, rootGetters) {
    const { product = {} } = state;
    const name = product.name;
    const productId = product.id;
    const productType = product.productType;
    const isBasic = productType === "BASIC";
    const isSales = productType === "SALES";
    const image = product.image?.items[0];
    const thumbnail = image?.path
      ? image
      : {
          key: "",
          path: "",
        };
    let horizontal = "";
    switch (product.image?.horizontal) {
      case "LEFT":
        horizontal = "text-left justify-start";
        break;
      case "CENTER":
        horizontal = "text-cneter justify-center";
        break;
      case "RIGHT":
        horizontal = "text-right justify-end";
        break;
      default:
        horizontal = "d-none";
    }
    const color = product.image?.textColor;
    const textColor = color ? `${color}--text` : "d-none";
    const nameYn = product.image?.nameYn;
    const conts = product.image?.conts;
    const thumbnailText = nameYn ? product.name : conts || "";
    const introduce = product.DESC?.introduce || "";
    const contents = product?.CONTENTS || [];
    const spreadContents = (() => {
      const arr = [];
      contents?.forEach((content) => {
        arr.push(content);
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            arr.push({ ...child, sub: true, indent: 1 });
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                arr.push({ ...descendant, sub: true, indent: 2 });
              });
            }
          });
        }
      });
      return arr;
    })();
    const contentsCnt =
      spreadContents?.filter((content) => {
        if (content.contentType === ContentType.CURRICULUM) {
          return false;
        }
        if (content.contentType === ContentType.SECTION) {
          return false;
        }
        return true;
      })?.length || 0;
    const managers = product.DESC?.managers?.map((manager) => {
      if (manager.member) {
        return manager.member;
      }
      return manager;
    });
    const managersViewYn = product.DESC?.managers?.length > 0;
    const links = product.DESC?.links;
    const linksViewYn = links?.length > 0;
    const startDttm = product.startDttm;
    const endDttm = product.endDttm;
    const joinType = product.joinType;
    const classfiedYn = product.classfiedYn;
    const productMember = rootGetters["members/getProductMember"];
    const isExpired = (() => {
      // 거절/내보내기 회원의 endDttm이 처리 시점으로 설정되어 재신청이 불가능한 문제가 있어 예외 처리.
      // 콘텐츠 상품 (운영) > 내보내기 후 승인 대기 상태 : endDttm 이 내보내기 시점으로 설정되어 기간 만료 처리됨
      if (
        productMember?.memberStatus === "DEL" ||
        productMember?.memberStatus === "REJECT" ||
        (isBasic && productMember?.memberStatus === "REQUEST")
      ) {
        return false;
      }
      if (!productMember?.endDttm) {
        return false;
      }
      if (productMember.endDttm === new Date(99999999999999).toJSON()) {
        return false;
      }
      const left = new Date(productMember.endDttm) - new Date();
      if (left < 0) {
        return true;
      }
      return false;
    })();
    const productMemberStatus = productMember?.memberStatus;
    const isRequest = productMemberStatus === MemberStatus.REQUEST;
    const isReject = productMemberStatus === MemberStatus.REJECT;
    const isPayRequest = productMemberStatus === MemberStatus.PAY_REQUEST;
    const isJoin = productMemberStatus === MemberStatus.JOIN && !isExpired;
    const limit = product.limit || 0;
    const salesCnt = isSales
      ? product?.STAT?.salesCnt || 0
      : isBasic
        ? product?.STAT?.joinCnt || 0
        : 0;
    const payReqCnt = product?.STAT?.payReqCnt || 0;
    const purchaseCnt = productMember?.purchaseCnt || 0;
    const soldout = limit > 0 && limit - salesCnt - payReqCnt < 1;
    const category = product.categoryId
      ? state.categories?.find(
          (category) => category.id === state.product.categoryId
        )?.name || ""
      : "";
    const rating = product.rating || 0;
    const ratingPeople = product.ratingCnt || 0;
    const star = `${rating.toFixed(1)} (${ratingPeople})`;
    const offerInfo = getters.getProductOffer();
    const prePromotion = offerInfo.isPrePromotion;
    const isStandby =
      !startDttm ||
      startDttm === new Date(99999999999999).toJSON() ||
      offerInfo.isStandby;
    const isNotStart = prePromotion ? false : new Date(startDttm) > new Date();
    // 선택한 라디오 구매 유형에 대한 사전 프로모션 진행 여부 (1회 또는 정기 구매 중 하나만 해당)
    const isNotPrePromoPurchaseType =
      offerInfo?.isPrePromotion &&
      !(offerInfo?.promoOnceApplied || offerInfo?.promoSubsApplied);
    const openDttm =
      product?.openDttm &&
      product?.openDttm !== new Date(99999999999999).toJSON() &&
      new Date(product?.openDttm) > new Date()
        ? product.openDttm
        : "";
    const isEnd = new Date(endDttm) < new Date();
    const period = product.period || 0;
    const periodLabel = period
      ? i18n.tc("productDetail.period", 2, { period })
      : i18n.tc("productDetail.period");
    const periodDescription = period
      ? i18n.tc("productDetail.periodDesc", 2, { period })
      : i18n.tc("productDetail.periodDesc");
    const isProductMember = rootGetters["members/isProductMember"];
    const delegators = product.delegators || [];
    const isDelegator = delegators.includes(rootGetters["members/getMemberId"]);
    const isManager =
      rootGetters["members/hasRoleOrAuth"]({
        authority: [
          Authority.CAMPUS_SUPER_MANAGE,
          Authority.PRODUCT_SUPER_MANAGE,
        ],
      }) || isDelegator;
    const isPrePaidBeforeOpenDttm = (() => {
      // 프로덕트 이용 가능 기간 만료
      if (isExpired) {
        return false;
      }
      // 정기구매 상태 이용중 || 1회구매 결제완료
      const isActiveOrPaid =
        productMember?.subsStatus === "ACTIVE" ||
        (productMember?.purchaseType === "ONCE" &&
          productMember?.paymentStatus === "PAID");
      // 이용 시작일이 현재 시점 이후
      const isOpenDateValid = new Date(openDttm) > new Date();

      const isMemberJoined = productMember?.memberStatus === "JOIN";

      if (isActiveOrPaid && isOpenDateValid && isMemberJoined) {
        return true;
      }
    })();

    const purchaseBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return true;
      }
      if (isProductMember) {
        return false;
      }
      if (isPrePaidBeforeOpenDttm) {
        return true;
      }

      if (isNotPrePromoPurchaseType) {
        return true;
      }

      return isEnd || isNotStart || soldout;
    })();
    const joinBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (isJoin) {
        return false;
      }
      // productDetailJoin.vue > joinBtnClick() disabled 처리 통일
      if (isExpired || isRequest) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const fixedBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return true;
      }
      if (isProductMember) {
        return false;
      }
      if (isBasic && (isExpired || isRequest)) {
        return true;
      }
      // 주식분석가달수
      if (
        rootGetters["campuses/getCampusUuid"] ===
        "662a33cf-01e1-427d-994a-0cd71bf4c2ae"
      ) {
        return true;
      }
      if (isNotPrePromoPurchaseType) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const btnTheme = (() => {
      if (isRequest) {
        return "gray";
      }
      if (isProductMember) {
        return "primarySub";
      }
      if (isPayRequest) {
        return "gray";
      }
      return "primary";
    })();
    const joinTypeText = (() => {
      return classfiedYn
        ? i18n.t("productDetail.unclassified")
        : joinType === "AUTO"
          ? i18n.t("productDetail.joinTypeAuto")
          : joinType === "MANUAL"
            ? i18n.t("productDetail.joinTypeManual")
            : "";
    })();
    const joinBtnText = (() => {
      // 콘텐츠 상품 (운영)
      // 1. 프로덕트 모집 시작일 전 참여 불가 (Disabled)
      // 2. 프로덕트 모집 마감일 이후 참여 불가 (Disabled)
      // 3. 이미 참여한 프로덕트일 경우, room으로 이동
      // 4. joinType에 따라 참여하기(누구나 참여 가능) / 참여 신청하기(승인 후 참여 가능)
      if (rootGetters["members/isStaff"]) {
        return "관리하기";
      }
      if (isJoin) {
        return i18n.t("productDetail.toRoom");
      }
      // @lars 요청으로 November 15th, 2022 10:26 AM 추가했으나 (cdfb3a0)
      // 거절 후 다시 재신청 가능하도록 요청해서 다시 제거
      // if (isReject) {
      //   return "거절됨";
      // }
      if (isRequest) {
        return "승인 대기 중";
      }
      if (isNotStart) {
        return isStandby ? "모집 예정" : getDateFormat(startDttm);
      }
      if (isEnd) {
        return i18n.t("productDetail.basicEnd");
      }
      if (soldout) {
        return "정원 초과"; // PC-674
      }
      if (isExpired) {
        return "기간 만료";
      }
      if (joinType === "MANUAL") {
        return i18n.t("productDetail.joinManual");
      }
      if (joinType === "AUTO") {
        return i18n.t("productDetail.joinAuto");
      }
      return "-";
    })();
    const purchaseBtnText = (() => {
      // 콘텐츠 상품
      // 1. 프로덕트 판매 시작일 전 참여 불가 (Disabled)
      // 2. 프로덕트 판매 마감일 이후 참여 불가 (Disabled)
      // 3. 이미 참여한 프로덕트일 경우, room으로 이동
      // 4. joinType에 따라 참여하기(누구나 참여 가능) / 참여 신청하기(승인 후 참여 가능)
      // 5. (정기결제) 이용 대기 : 결제완료 + 이용 시작일 이전
      if (rootGetters["members/isStaff"]) {
        return "관리하기";
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return `${getDateFormat(productMember?.startDttm)} 시작 가능`;
      }
      if (isProductMember) {
        return i18n.t("productDetail.toRoom");
      }
      if (isPrePaidBeforeOpenDttm) {
        return `${getDateFormat(openDttm)}부터 이용 가능`;
      }
      if (isPayRequest) {
        return "결제 대기";
      }
      if (isNotStart || isStandby || isNotPrePromoPurchaseType) {
        return "오픈 예정";
      }
      if (isEnd) {
        return i18n.t("productDetail.salesEnd");
      }
      if (soldout) {
        return "판매 마감"; // PC-674
      }
      // 정기구매 > 결제 실패 (재구매하기 -> 구매하기로 변경)
      if (productMember?.subsStatus === "FAIL") {
        return "구매하기";
      }
      return purchaseCnt > 0 ? "재구매하기" : "구매하기";
    })();
    const tags =
      product.tags
        ?.split("#")
        ?.filter((tag) => tag)
        ?.map((tag) => "#" + tag) || [];

    return {
      name,
      productId,
      productType,
      isBasic,
      isSales,
      thumbnail,
      horizontal,
      textColor,
      nameYn,
      conts,
      thumbnailText,
      introduce,
      managers,
      managersViewYn,
      links,
      linksViewYn,
      isManager,
      startDttm,
      endDttm,
      joinType,
      classfiedYn,
      productMemberStatus,
      isExpired,
      isJoin,
      isRequest,
      isReject,
      limit,
      salesCnt,
      purchaseCnt,
      soldout,
      category,
      rating,
      star,
      isNotStart,
      isEnd,
      contentsCnt,
      period,
      periodLabel,
      periodDescription,
      isProductMember,
      fixedBtnDisabled,
      btnTheme,
      purchaseBtnDisabled,
      joinBtnDisabled,
      joinTypeText,
      joinBtnText,
      purchaseBtnText,
      tags,
      delegators,
      isDelegator,
      openDttm,
      isPrePaidBeforeOpenDttm,
    };
  },

  getProductOffer: (state, getters, __, rootGetters) => (option) => {
    const { product = {} } = state;
    const isReservation = product.productType === "RESERVATION";
    const isSales = product.productType === "SALES";

    const promotion = (() => {
      const promotionArr = product?.PROMOTION || [];
      const reservePromotion = promotionArr?.promoOpts?.filter(
        (promo) => promo.promoOptId === option?.id
      );
      const promotions = isSales ? promotionArr : reservePromotion;

      if (promotions?.length < 1 || promotions?.[0]?.promotionStatus !== "ON") {
        return null;
      }
      const promo = promotions.reduce((acc, cur) => {
        acc = {
          ...cur,
          isEnd:
            cur?.endDttm !== new Date(99999999999999).toJSON() &&
            new Date(cur?.endDttm) < new Date(),
        };
        return acc;
      }, {});
      return promo;
    })();

    // 콘텐츠 상품
    //   1. 프로모션 여부
    //     1-1. 일반
    //          - 프로모션 종료일이 있는경우 promotion.endDttm
    //          - 상시적용
    //             a. 프로덕트 판매 종료일이 있는경우 product.endDttm
    //             b. 프로덕트 판매 종료일 X > 프로모션 이름만 노츌
    //     1-2. 사전
    //          - promotion.endDttm 타이머
    //     1.3. 남은 개수 (콘텐츠 상품만 표시)
    //         - promoCnt 우선 promoLimit X productLimit 있는경우 productLimit 표시
    //   2. 그룹할인 (회원 특가) 여부
    //     2-1. 프로모션 X , groupRatio
    //   3. 한정 판매
    //     3-1. 프로모션 X product.endDttm || productLimit O
    //   4. 오픈 예정
    //     4-1.
    //       - promotion X > product.startDttm < new Date
    const promotionYn = (() => {
      if (!promotion) {
        return false;
      }
      if (isReservation) {
        // 프로모션 진행중
        // (예약 옵션 시작일 변경) 옵션 시작일이 현재 시간 이전이거나 오픈 예정 상태이거나 프로모션 시작일이 현재시간 이후
        if (
          !promotion?.promoPreYn &&
          (new Date(option?.startDttm) > new Date(promotion.startDttm) ||
            new Date(option?.startDttm) > new Date() ||
            new Date(promotion?.startDttm) > new Date())
        ) {
          return false;
        }
        // (사전 프로모션 진행중 예약 옵션 시작일 변경) 옵션 시작일이 프로모션 시작일 이후인경우
        if (
          promotion?.promoPreYn &&
          (new Date(option?.startDttm) < new Date(promotion?.endDttm) ||
            new Date(promotion?.startDttm) > new Date())
        ) {
          return false;
        }
        // 상시 적용
        if (promotion?.endDttm === new Date(99999999999999).toJSON()) {
          return true;
        }
      }

      // 사전 프로모션 진행 여부
      if (
        promotion?.promoPreYn &&
        (new Date(option?.startDttm) < new Date(promotion?.endDttm) ||
          new Date(promotion?.startDttm) > new Date())
      ) {
        return false;
      }

      // 일반 프로모션
      if (!promotion?.promoPreYn) {
        // 프로덕트 시작일이 프로모션 시작일 이후로 설정
        if (new Date(product?.startDttm) > new Date(promotion?.startDttm)) {
          return false;
        }
        // 프로모션 시작일이 도래하지않음
        if (new Date(promotion?.startDttm) > new Date()) {
          return false;
        }
      }
      // 프로모션 개수제한 O
      if (promotion.promoLimit > 0) {
        // 프로모션 판매 개수가 제한 개수보다 크거나 같은 경우 프로모션 종료
        if (promotion.promoSalesCnt >= promotion?.promoLimit) {
          return false;
        }
      }

      return promotion?.promotionStatus === "ON" && !promotion?.isEnd;
    })();

    const promotionName = promotionYn ? promotion.name : "";

    const isPrePromotion = (() => {
      return promotionYn && promotion?.promoPreYn;
    })();

    const isStandby = (() => {
      if (isPrePromotion) {
        return false;
      }
      if (isSales) {
        return new Date(product?.startDttm) > new Date();
      }
      const notReady = new Date(option?.startDttm) > new Date();

      return (
        !option?.startDttm ||
        option?.startDttm === new Date(99999999999999).toJSON() ||
        notReady
      );
    })();

    const promoLimit = promotion?.promoLimit || 0;
    const promoSalesCnt = promotion?.promoSalesCnt || 0;
    const ogLimit = product?.limit || 0;
    const ogSalesCnt = product?.salesCnt || 0;
    const payReqCnt = product?.STAT?.payReqCnt || 0;
    const isEnd = isSales
      ? new Date(product?.endDttm) < new Date()
      : new Date(option?.endDttm) < new Date();

    const remainCnt = (() => {
      if (isEnd) {
        return 0;
      }
      if (promoLimit) {
        const remains = promoLimit - promoSalesCnt;
        return remains;
      }
      if (ogLimit) {
        const remains = ogLimit - ogSalesCnt - payReqCnt;
        return remains;
      }

      if (isReservation) {
        const allPricesLimitCnt = option?.items?.reduce((acc, cur) => {
          acc += cur?.limit || 0;

          return acc;
        }, 0);
        return allPricesLimitCnt;
      }
      return 0;
    })();

    const displayPrice = product?.displayPrice;
    const member = rootGetters["members/getMemberItem"];

    const groupDiscount = (() => {
      // 로그인 회원의 그룹 정보가 없다면
      if (member?.groupSks?.length < 1) {
        return false;
      }

      const memberGroups = member?.groupSks || [];

      if (isReservation) {
        const findGroupDisItem = product?.groupPrice?.opts?.find(
          (opt) => opt.optId === option?.id && opt.applyYn
        );
        // 옵션에 진행중인 그룹 할인이 없다면
        if (!findGroupDisItem) {
          return false;
        }

        // 회원이 소속된 그룹이 옵션 > 그룹할인 대상인지 확인
        const isDisGroupMember = findGroupDisItem?.opts?.[0]?.groups?.some(
          (group) => memberGroups?.includes(group.groupId) && group.ratio > 0
        );

        return isDisGroupMember;
      }

      return displayPrice?.promoRatio === 0 && displayPrice?.groupRatio > 0;
    })();

    let dttm = isSales ? product.endDttm : option?.endDttm;

    if (isStandby) {
      dttm = isSales ? product?.startDttm : option?.startDttm;
    }

    if (promotionYn) {
      dttm = promotion?.endDttm;
      // 프로모션 상시적용
      if (dttm === new Date(99999999999999).toJSON()) {
        // 판매 마감일이 있는경우
        if (isSales && product.endDttm !== new Date(99999999999999).toJSON()) {
          dttm = product.endDttm;
        }
        // 접수 마감일이 있는경우
        if (
          isReservation &&
          option.endDttm !== new Date(99999999999999).toJSON()
        ) {
          dttm = option.endDttm;
        }
      }
    }

    const limitDttm = dttm === new Date(99999999999999).toJSON() ? "" : dttm;

    const hasLimited = remainCnt > 0 || !!limitDttm;

    if (!promotionYn && !isStandby && !hasLimited && !groupDiscount) {
      return "";
    }

    return {
      promotionYn,
      promotionName,
      isPrePromotion,
      isStandby,
      isEnd,
      groupDiscount,
      hasLimited,
      limitDttm,
      ...(!isReservation && { remainCnt }),
    };
  },
};
