import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
} from "@aws-sdk/client-cognito-identity";
import { useStore } from "@/store";
import { awsRegion, userPoolId } from "@/config";

const REGION = awsRegion;
const USER_POOL_ID = userPoolId;

export async function getCredentials({ identityId, token, isAdmin = false }) {
  const store = useStore();
  const snsType = store.getters["auth/getSnsType"];

  if (store.getters["auth/isCredentialsValid"]()) {
    return store.getters["auth/getCredentials"];
  }

  if (store.getters["auth/getCredentialsPromise"]) {
    return store.getters["auth/getCredentialsPromise"];
  }

  const promise = (async () => {
    try {
      if (!isAdmin) {
        const result = await store.dispatch("auth/tokenRefresh");

        if (result?.success) {
          token = result.data?.token;
          identityId = result.data?.identityId;
        } else {
          throw new Error("token refresh 실패");
        }
      }

      let params;
      if (snsType === "NONE") {
        const loginKey = `cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`;
        params = {
          IdentityId: `${REGION}:${identityId.replace("ap-northeast-2:", "")}`,
          Logins: {
            [loginKey]: token,
          },
        };
      } else {
        params = {
          IdentityId: identityId,
          Logins: {
            "cognito-identity.amazonaws.com": token,
          },
        };
      }

      const cognitoIdentityClient = new CognitoIdentityClient({
        region: REGION,
      });

      const getCredentialsCommand = new GetCredentialsForIdentityCommand(
        params
      );
      const credentialsResponse = await cognitoIdentityClient.send(
        getCredentialsCommand
      );

      if (!credentialsResponse.Credentials) {
        throw new Error("Credentials 가져오기 실패");
      }

      const credentials = {
        accessKeyId: credentialsResponse.Credentials.AccessKeyId,
        secretAccessKey: credentialsResponse.Credentials.SecretKey,
        sessionToken: credentialsResponse.Credentials.SessionToken,
        expiration: credentialsResponse.Credentials.Expiration,
      };

      store.commit("auth/setCredentials", credentials);

      await store.dispatch("users/getUserFromCognitoId", {
        setAccInfo: true,
        cognitoId: identityId.replace("ap-northeast-2:", ""),
        sigYn: false,
        accessKeyId: credentialsResponse.Credentials.AccessKeyId,
      });

      return credentials;
    } catch (e) {
      console.log("Credentials 가져오기 실패:", e);
      store.dispatch("auth/signOut");
    } finally {
      store.commit("auth/setCredentialsPromise", null);
    }
  })();

  store.commit("auth/setCredentialsPromise", promise);
  return promise;
}
