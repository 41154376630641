import { endpointV2 as endpoint } from "@/config";

// policy template 조회
export async function getPresignedUrl({ jobType, memberId, campusId, key }) {
  const params = {
    jobType,
    memberId,
    campusId,
    key,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.storages}/files`,
    params,
  });
}
export async function getPublicPresignedUrl({
  jobType,
  memberId,
  campusId,
  productId,
  contentId,
  targetType,
  key,
}) {
  const params = {
    jobType,
    campusId,
    key,
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(contentId && { contentId }),
    ...(targetType && { targetType }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.storages}/files/public`,
    params,
  });
}
