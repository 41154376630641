import isEmpty from "lodash.isempty";

const selector = {
  getCognitoId: (state, _, __, rootGetters) => {
    // 회원 정보의 cognitoId
    const userCognitoId = state.user?.cognitoUuid;

    // 인증 정보의 cognitoId
    const isWebview = rootGetters["common/isPoinWebview"];
    const isAdmin = rootGetters["auth/getAuth"]?.isAdmin;
    const auth = rootGetters["auth/getAuth"];
    const authCognitoId =
      isWebview || isAdmin
        ? auth.cognitoId
        : auth.identityId?.replace("ap-northeast-2:", "");

    return userCognitoId || authCognitoId;
  },
  getUser: (state) => state.user || null,
  getUserUuid: (state) => {
    return state.user?.uuid;
  },
  getUserEmail: (state) => {
    return state?.user?.email ?? "";
  },
  getSnsType: (state) => {
    return state?.user?.snsType ?? "";
  },
  getSnsTypes: (state) => {
    if (state.user) {
      if (state.user.snsTypes && state.user.snsTypes.length > 1) {
        return state.user.snsTypes;
      }
      return [state.user.snsType];
    }
    return [];
  },
  getMktYn: (state) => {
    return state.user?.mktYn || false;
  },
  isCampusPlan: (state) => {
    if (!isEmpty(state.user)) {
      return state.user.campusYn === true;
    }
    return false;
  },
  isReqDeleteUser: (state) => {
    if (state.user) {
      return !isEmpty(state.user.delDttm);
    }
    return false;
  },
  getDeleteStartDate: (state) => {
    if (selector.isReqDeleteUser(state)) {
      return state.delDttm;
    }
    return "";
  },
};

export default selector;
