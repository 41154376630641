import { toJsonDateFormat } from "@/utils/date-format";
import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";
import { vimeo } from "@/config";
import { useAlert } from "@/stores/alert";
import { transformContents } from "@/utils/contents";
import ApiClient from "@/api";

const vimeoApiInstance = new ApiClient(null, { baseURL: vimeo.baseUrl }, [
  "vimeo",
]);

export default (apiInstance) => ({
  async getProductsStaff(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    return apiInstance.products.getProducts({
      campusId,
      memberId,
      ...payload,
    });
  },
  async reqGetProductsStaff(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      first,
    } = payload;
    if (first) {
      store.commit("clearProductsStaff");
    }
    const lastKey = store.getters["getProductsStaffLastKey"];

    const result = await apiInstance.products.getProducts({
      campusId,
      memberId,
      ...payload,
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      store.commit("updateProductsStaff", result.data);
    }
    return result;
  },
  async getProductsUser(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getSearch = isMember
      ? apiInstance.products.getSearch
      : apiInstance.products.getSearchPublic;

    return getSearch({
      campusId,
      memberId,
      ...payload,
    });
  },
  async reqGetProduct(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { id, local = false } = payload;

    const result = await apiInstance.products.getProduct({
      memberId,
      campusId,
      id,
    });
    if (result?.success) {
      const { data } = result;
      if (!local) {
        store.commit("setNewProduct", {
          ...data,
          SETTING: {
            ...data.SETTING,
            completion: {
              compRate:
                data.SETTING?.completion?.completionUnit === "RATE"
                  ? Math.floor(
                      (data.SETTING?.completion?.compRate || 0.8) * 100
                    )
                  : data.SETTING?.completion?.compRate || 1,
              contents: getCompletionContents(
                data.SETTING?.completion?.contents,
                100
              ),
              completionType:
                data.SETTING?.completion?.completionType || "AUTO",
              completionUnit:
                data.SETTING?.completion?.completionUnit || "RATE",
            },
          },
        });
        store.commit("setProduct", result.data);
      }
    }
    return result;
  },
  async putProduct(store) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const {
      image,
      period,
      limit,
      startDttm,
      refundPolicyId,
      endDttm,
      communityIds,
      DESC,
      tags,
      price,
      joinType,
      name,
      SETTING,
      classfiedYn,
      categoryId,
      productType,
      id,
      publishYn,
      freeYn,
      groupPriceYn,
      groupPrice,
      purchaseTypes,
      subsPrice,
      subsMonths,
      openDttm,
      custAdditional,
      custAddType,
    } = store.getters["getNewProduct"];

    const hasSubscription = purchaseTypes?.includes("SUBSCRIPTION");
    const pauseYn = !!(SETTING.pauseDays && SETTING.pauseTimes);

    const result = await apiInstance.products.putProduct({
      image,
      period,
      limit,
      price,
      startDttm: new Date(startDttm).toJSON(),
      endDttm: new Date(endDttm).toJSON(),
      communityIds,
      DESC: {
        ...DESC,
        campusId,
      },
      tags,
      ...(joinType && { joinType }),
      name,
      SETTING: {
        ...SETTING,
        campusId,
        completion: {
          completionType: SETTING.completion.completionType,
          completionUnit: SETTING.completion.completionUnit,
          compRate:
            SETTING.completion.completionUnit === "RATE"
              ? SETTING.completion.compRate * 0.01
              : SETTING.completion.compRate,
          contents: getCompletionContents(SETTING.completion.contents, 0.01),
        },
        pauseYn,
        pauseDays: pauseYn ? SETTING.pauseDays : 0,
        pauseTimes: pauseYn ? SETTING.pauseTimes : 0,
        reqContsYn: SETTING.reqContsYn && SETTING.reqConts?.length > 0,
      },
      categoryId,
      productType,
      campusId,
      memberId,
      publishYn: classfiedYn ? false : publishYn,
      freeYn,
      groupPriceYn,
      groupPrice,
      id,
      classfiedYn: classfiedYn || false,
      ...(joinType && { joinType }),
      ...(openDttm && { openDttm: new Date(openDttm).toJSON() }),
      ...(purchaseTypes && { purchaseTypes }),
      ...(refundPolicyId && { refundPolicyId }),
      ...(hasSubscription && { subsPrice }),
      ...(hasSubscription && { subsMonths }),
      custAdditional,
      custAddType,
    });

    return result;
  },
  async putContents(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { contents, deleteIds } = payload;
    const { productType, id } = store.getters["getNewProduct"];

    const hasValidName = (content) => {
      return Boolean(content.name);
    };

    const checkNameRecursively = (item) => {
      if (!hasValidName(item)) {
        return true;
      }

      return (
        item.items?.some((subItem) => checkNameRecursively(subItem)) ?? false
      );
    };

    const hasMissingNames = (contents) => {
      return (
        contents?.some((content) => checkNameRecursively(content)) ?? false
      );
    };

    const noName = hasMissingNames(contents);

    if (noName) {
      return {
        success: false,
        message: "[콘텐츠 이름]을 입력해주세요.",
      };
    }

    if (!store.rootGetters["campuses/checkServeYn"]("QUIZ")) {
      const hasQuiz = contents?.some(
        (item) =>
          item.contentType === ContentTypeEnum.QUIZ ||
          item.items?.some(
            (child) =>
              child.contentType === ContentTypeEnum.QUIZ ||
              child.items?.some(
                (descendant) => descendant.contentType === ContentTypeEnum.QUIZ
              )
          )
      );
      if (hasQuiz) {
        return {
          success: false,
          message: "퀴즈 콘텐츠는 현재 요금 플랜에서 사용하실 수 없습니다.",
        };
      }
    }

    const checkVideoUploadDone = (content) => {
      if (
        content.contentType !== ContentTypeEnum.VIDEO &&
        content.lowContentType !== ContentTypeEnum.VIDEO
      ) {
        return true;
      }
      if (content.syncYn) {
        return true;
      }
      if (content.vod?.id || content.vod?.url) {
        return true;
      }
      if (content.clip?.url && content.clip?.time) {
        return true;
      }
      if (!(content.vod?.id || content.vod?.url) && !content.clip?.url) {
        return true;
      }
      return false;
    };

    const noVideo = contents?.some(
      (item) =>
        !checkVideoUploadDone(item) ||
        item.items?.some(
          (child) =>
            !checkVideoUploadDone(child) ||
            child.items?.some((descendant) => !checkVideoUploadDone(descendant))
        )
    );

    if (noVideo) {
      const refreshVimeo = async (content) => {
        if (content.contentType !== ContentTypeEnum.VIDEO) {
          return content;
        }
        if (content.clip?.url && !content.clip?.time) {
          const videoUri = content.clip?.url;
          const result = await vimeoApiInstance.vimeo.getVideo({
            videoUri,
          });
          if (result.success) {
            const { duration } = result.data;
            if (duration) {
              return {
                ...content,
                clip: {
                  ...content.clip,
                  time: duration,
                },
              };
            }
          }
        }
        return content;
      };
      const refreshContents = await Promise.all(
        contents?.map(async (content) => {
          if (content.items?.length > 0) {
            await Promise.all(
              content.items.map(async (child) => {
                if (child.items?.length > 0) {
                  await Promise.all(
                    child.items.map(async (descendant) =>
                      refreshVimeo(descendant)
                    )
                  );
                }
                return refreshVimeo(child);
              })
            );
          }
          return refreshVimeo(content);
        })
      );
      store.commit("updateNewProductContents", refreshContents);
      return {
        success: false,
        message:
          "<p>현재 동영상 업로드 중입니다. 업로드 완료 및 변환 준비가 끝난 이후 저장이 가능합니다.</p>",
      };
    }

    const hasValidCodeLanguage = (content) => {
      if (!content.codeYn) {
        return true;
      }
      return Boolean(content.codeLang?.length);
    };

    const checkContentsRecursively = (item) => {
      if (!hasValidCodeLanguage(item)) {
        return true;
      }

      return (
        item.items?.some((subItem) => checkContentsRecursively(subItem)) ??
        false
      );
    };

    const hasInvalidCodeLanguage = (contents) => {
      return (
        contents?.some((content) => checkContentsRecursively(content)) ?? false
      );
    };

    const noCodeLang = hasInvalidCodeLanguage(contents);

    if (noCodeLang) {
      return {
        success: false,
        message: "코드 에디터 제공 시 실습 파일 유형을 선택해주세요.",
      };
    }

    const result = await apiInstance.products.putProduct({
      DELETE_CONTENTS: deleteIds,
      CONTENTS: transformContents(contents),
      productType,
      campusId,
      memberId,
      id,
    });

    return result;
  },
  async deleteProduct(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { id } = payload;
    const result = await apiInstance.products.deleteProduct({
      memberId,
      campusId,
      id,
    });
    if (result.success) {
      store.commit("removeProduct", id);
    }
    return result;
  },
  async reqGetCustAdditional(store) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const result = await apiInstance.products.getCategories({
      productObjType: "CUST_ADD",
      campusId,
    });
    if (result.success) {
      store.commit("setCustAdditional", result.data?.custAdditional?.items);
    }
    return result;
  },

  async postCustAdditional(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      custAdditional,
    } = payload;

    const result = await apiInstance.products.postCategories({
      productObjType: "CUST_ADD",
      memberId,
      campusId,
      custAdditional,
    });
    if (result.success) {
      store.commit("setCustAdditional", result.data?.custAdditional?.items);
    }
    return result;
  },

  async putProductStatus(store, payload = {}) {
    const {
      publishYn,
      startDttm,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      endDttm,
      id,
      memberId = store.rootGetters["members/getMemberId"],
      table,
    } = payload;
    const result = await apiInstance.products.putStatus({
      id,
      publishYn,
      ...(startDttm && { startDttm }),
      ...(endDttm && { endDttm }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      const UPDATE_MUTATION = table ? "updateProducts" : "setProduct";
      store.commit(UPDATE_MUTATION, result.data);
    }
    return result;
  },
  async getDetail(store, payload = {}) {
    const {
      id,
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      calcUserCoupons = false,
      publicYn = false,
    } = payload;

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getDetail =
      isMember && !publicYn
        ? apiInstance.products.getDetail
        : apiInstance.products.getDetailPublic;
    const result = await getDetail({
      id,
      ...(userId && { userId }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(calcUserCoupons && { calcUserCoupons }),
    });

    return result;
  },
  async reqGetDetail(store, payload = {}) {
    const {
      id,
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      calcUserCoupons = false,
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getDetail = isMember
      ? apiInstance.products.getDetail
      : apiInstance.products.getDetailPublic;
    const result = await getDetail({
      id,
      ...(userId && { userId }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(calcUserCoupons && { calcUserCoupons }),
    });
    if (result.success) {
      store.commit("setProduct", result.data);
      store.commit("members/setProductMember", result.data.productMember, {
        root: true,
      });
    }
    return result;
  },
  async reqGetLimitCheck(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.products.getLimitCheck({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      id,
    });
    return result;
  },
  async postPricesCheck(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      optionId,
      promoName,
      promoRatio,
      onceApplyYn,
      promoSubsRatio,
      subsApplyCnt,
      subsApplyYn,
      groupPrice,
      promoOpts,
      ignoreGroupPrice,
      promotionId,
    } = payload;
    const result = await apiInstance.products.postPricesCheck({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      id,
      ...(optionId && { optionId }),
      ...(promotionId && { promotionId }),
      ...(promoName && { promoName }),
      ...(promoRatio && { promoRatio }),
      ...(promoSubsRatio && { promoSubsRatio }),
      onceApplyYn,
      subsApplyYn,
      ...(subsApplyCnt && { subsApplyCnt }),
      ...(groupPrice && { groupPrice }),
      ...(ignoreGroupPrice && { ignoreGroupPrice }),
      ...(promoOpts?.length > 0 && { promoOpts }),
    });
    if (result.success) {
      store.commit("setChceckPrices", result.data);
    }
    return result;
  },
  async postPromotion(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      id,
      name,
      promoPreYn,
      promoRatio,
      promoLimit,
      startDttm,
      endDttm,
      closeType,
      color,
      promoOptId,
      promoOpts,
      promoSubsRatio,
      onceApplyYn,
      subsApplyYn,
      subsApplyCnt,
    } = payload;

    const result = await apiInstance.products.postPromotion({
      campusId,
      memberId,
      productId,
      id,
      name,
      promoPreYn,
      promoRatio,
      promoLimit,
      startDttm,
      endDttm,
      closeType,
      color,
      promoOptId,
      promoOpts,
      promoSubsRatio,
      onceApplyYn,
      subsApplyYn,
      subsApplyCnt,
    });
    if (result.success) {
      store.commit("setPromotion", result.data);
    }

    return result;
  },
  async reqGetPromotion(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;

    const result = await apiInstance.products.getPromotion({
      campusId,
      memberId,
      id,
    });
    if (result.success) {
      store.commit("setPromotion", result.data);
    }
    return result;
  },
  async reqGetPromotions(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      promotionStatus,
      promoPreYn,
      keyword,
      first = true,
      sort,
      order,
      limit,
      productType,
    } = payload;

    if (first) {
      store.commit("clearPromotions");
    }
    const lastKey = store.getters["getPromotionLastkey"];
    const result = await apiInstance.products.getPromotions({
      campusId,
      memberId,
      productId,
      promotionStatus,
      promoPreYn,
      keyword,
      first,
      sort,
      order,
      lastKey,
      limit,
      productType,
    });
    if (result.success) {
      store.commit("setPromotions", result.data);
    }
    return result;
  },
  async deletePromotion(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { productId, id } = payload;
    const result = await apiInstance.products.deletePromotion({
      campusId,
      memberId,
      productId,
      id,
    });
    if (result.success) {
      store.commit("removePromotion", id);
    }
    return result;
  },
  async putPromotion(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      promoPreYn,
      promoLimit,
      promoRatio,
      productId,
      color,
      startDttm,
      endDttm,
      closeType,
      name,
      promoOptId,
      promoOpts,
      promoSubsRatio,
      onceApplyYn,
      subsApplyYn,
      subsApplyCnt,
    } = payload;
    const result = await apiInstance.products.putPromotion({
      campusId,
      memberId,
      id,
      promoPreYn,
      promoLimit,
      promoRatio,
      productId,
      color,
      startDttm,
      endDttm,
      closeType,
      name,
      promoOptId,
      promoOpts,
      promoSubsRatio,
      onceApplyYn,
      subsApplyYn,
      subsApplyCnt,
    });
    return result;
  },
  async putPromotionStatus(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      promoPreYn,
      promoLimit,
      promoRatio,
      color,
      startDttm,
      endDttm,
      promotionStatus,
      name,
      id,
    } = payload;
    const result = await apiInstance.products.putPromotionStatus({
      campusId,
      memberId,
      productId,
      promoPreYn,
      promoLimit,
      promoRatio,
      color,
      startDttm,
      endDttm,
      promotionStatus,
      name,
      id,
    });
    if (result.success) {
      store.commit("updatePromotions", result.data);
    }
    return result;
  },
  async putCommunities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      changeType, // CREATE, DELETE
      targets,
    } = payload;
    if (!changeType || !targets || !productId) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.products.putCommunities({
      productId,
      targets,
      changeType,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    return result;
  },
  async postLive(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      redirectUri,
      startUrl,
      productId,
      startDttm,
      service,
      contentId,
      endDttm,
      joinUrl,
    } = payload;
    const data = {
      ...(redirectUri && { redirectUri }),
      ...(startUrl && { startUrl }),
      ...(productId && { productId }),
      ...(startDttm && { startDttm }),
      ...(service && { service }),
      ...(campusId && { campusId }),
      ...(contentId && { contentId }),
      ...(endDttm && { endDttm }),
      ...(joinUrl && { joinUrl }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
    };
    const result = await apiInstance.products.postLive(data);
    if (result.success) {
      store.commit("updateProduct", {
        CONTENTS: result.data || [],
      });
    }
    return result;
  },
  async putLive(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      redirectUri,
      startUrl,
      productId,
      startDttm,
      service,
      contentId,
      endDttm,
      joinUrl,
      meetingId,
    } = payload;
    const data = {
      ...(redirectUri && { redirectUri }),
      ...(startUrl && { startUrl }),
      ...(productId && { productId }),
      ...(startDttm && { startDttm }),
      ...(service && { service }),
      ...(campusId && { campusId }),
      ...(contentId && { contentId }),
      ...(endDttm && { endDttm }),
      ...(joinUrl && { joinUrl }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(meetingId && { meetingId }),
    };
    const result = await apiInstance.products.putLive(data);
    if (result.success) {
      store.commit("updateProduct", {
        CONTENTS: result.data || [],
      });
    }
    return result;
  },
  async deleteLive(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      redirectUri,
      productId,
      service,
      contentId,
      meetingId,
    } = payload;
    const data = {
      ...(redirectUri && { redirectUri }),
      ...(productId && { productId }),
      ...(service && { service }),
      ...(campusId && { campusId }),
      ...(contentId && { contentId }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(meetingId && { meetingId }),
    };
    const result = await apiInstance.products.deleteLive(data);
    if (result.success) {
      store.commit("updateProduct", {
        CONTENTS: result.data || [],
      });
    }
    return result;
  },
  async getOgTags(_, payload = {}) {
    const { url } = payload;
    return apiInstance.products.getOgTags({ url });
  },

  //담당자 관리
  async reqGetProductDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    // if (first) {
    //   store.commit("clearCommunityMembers");
    // }
    const result = await apiInstance.products.getProductDelegators({
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setProductDelegators", result.data);
    }
    return result;
  },
  async reqPostProductDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      changeType,
      targets,
    } = payload;
    const result = await apiInstance.products.postProductDelegators({
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(targets && { targets }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  async postProductSales(store, payload = {}) {
    const { name = "프로덕트" } = payload;
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const result = await apiInstance.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/product.png",
            key: "DEFAULT",
          },
        ],
      },
      period: 0,
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
      DELETE_CONTENTS: [],
      communityIds: [],
      DESC: {
        links: [],
        managers: [],
        introduce: "",
        campusId,
      },
      CONTENTS: [],
      price: 1000,
      limit: 0,
      name,
      SETTING: {
        options: {
          sequencialYn: false,
          video: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
          audio: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
        },
        completion: {
          completionType: "AUTO",
          completionUnit: "RATE",
          compRate: 0.8,
          contents: [
            {
              contentType: "VIDEO",
              compRate: 0.8,
            },
            {
              contentType: "AUDIO",
              condition: "",
              compRate: 0.8,
            },
          ],
        },
        certificate: {
          dttmYn: false,
          yn: false,
          startDttm: "",
          seals: [],
          endDttm: "",
          title: "수료증",
          desc: "위 사람은 본 교육과정을 수료하였으므로 이 증서를 수여합니다.",
        },
        campusId,
        pauseYn: false,
        pauseDays: 0,
        pauseTimes: 0,
      },
      productType: "SALES",
      custAddType: "GLOBAL",
      campusId,
      memberId,
    });
    return result;
  },
  async postProductBasic(store, payload = {}) {
    const { name = "프로덕트" } = payload;
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const result = await apiInstance.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/product.png",
            key: "DEFAULT",
          },
        ],
      },
      period: 0,
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
      DELETE_CONTENTS: [],
      communityIds: [],
      DESC: {
        links: [],
        managers: [],
        introduce: "",
        campusId,
      },
      CONTENTS: [],
      price: 0,
      limit: 0,
      name,
      SETTING: {
        options: {
          sequencialYn: false,
          video: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
          audio: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
        },
        completion: {
          completionType: "AUTO",
          completionUnit: "RATE",
          compRate: 0.8,
          contents: [
            {
              contentType: "VIDEO",
              compRate: 0.8,
            },
            {
              contentType: "AUDIO",
              condition: "",
              compRate: 0.8,
            },
          ],
        },
        certificate: {
          dttmYn: false,
          yn: false,
          startDttm: "",
          seals: [],
          endDttm: "",
          title: "수료증",
          desc: "위 사람은 본 교육과정을 수료하였으므로 이 증서를 수여합니다.",
        },
        campusId,
        pauseYn: false,
        pauseDays: 0,
        pauseTimes: 0,
      },
      productType: "BASIC",
      custAddType: "GLOBAL",
      campusId,
      memberId,
      classfiedYn: false,
      joinType: "AUTO",
    });
    return result;
  },
  async postProductClone(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.products.postProductClone({
      id,
      campusId,
      memberId,
    });
    return result;
  },
});

const getCompletionContents = (contents = [], x = 0.01) => {
  let newContents = [];
  const video = contents.find((content) => content.contentType === "VIDEO");
  const audio = contents.find((content) => content.contentType === "AUDIO");

  const newVideo = {
    ...video,
    compRate: (video?.compRate || 80) * x,
  };
  const newAudio = {
    ...audio,
    compRate: (audio?.compRate || 80) * x,
  };

  const videoIdx = contents.findIndex(
    (content) => content.contentType === "VIDEO"
  );
  newContents = [
    ...contents.slice(0, videoIdx),
    ...contents.slice(videoIdx + 1),
  ];

  const audioIdx = newContents.findIndex(
    (content) => content.contentType === "AUDIO"
  );
  newContents = [
    ...newContents.slice(0, audioIdx),
    ...newContents.slice(audioIdx + 1),
  ];

  newContents = [...newContents, newVideo, newAudio];

  return newContents;
};