import AES from "crypto-js/aes";
import globalStore from "@/store";
import { toJsonDateFormat } from "@/utils/date-format";
import { encryptPublic } from "@/utils/text-format";
import { useAlert } from "@/stores/alert";

export default (apiInstance) => ({
  // 캠퍼스 결제 이력 조회
  reqGetUserPaymentsHistories: async (store, payload) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      reset,
      excelYn,
    } = payload;
    if (reset) {
      store.commit("setuserPaymentsHistoriesReset");
    } else {
      payload.lastKey = store.getters["getLastKey"];
    }

    const result = await apiInstance.payments.getUserPaymentsHistories({
      ...payload,
      campusId,
      memberId,
    });

    if (result.success) {
      if (!excelYn) {
        const { lastKey } = result.data;
        store.commit("setUserPaymentsHistories", result.data);
        store.commit("setLastKey", lastKey);
      }
    }
    return result;
  },

  // 캠퍼스 결제 이력 요약 정보
  reqGetPaymentSummary: async (store, payload = {}) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      sDate,
      eDate,
      productId,
    } = payload;

    const result = await apiInstance.payments.getPaymentSummary({
      campusId,
      memberId,
      ...(sDate && { sDate }),
      ...(eDate && { eDate }),
      ...(productId && { productId }),
    });
    if (result.success) {
      store.commit("setPaymentSummary", result.data);
    }
    return result;
  },
  //검색 키워드 초기화
  setCustomerNameKeyword: async (store, keyword) => {
    store.commit("setCustomerNameKeyword", keyword);
  },
  //lastKey
  setLastKey: async (store, lastKey) => {
    store.commit("setLastKey", lastKey);
  },

  // histories -> 콘텐츠 상품 결제 내역 결제 취소
  reqDeletePaymentHistories: async (store, payload) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.payments.deletePaymentHistories({
      campusId,
      memberId,
      id,
    });
    return result;
  },
  // 판매클래스 일반 결제 승인 요청
  reqClsPay: async (store, payload) => {
    const {
      userId = globalStore.getters["users/getUserUuid"],
      classId,
      tran_cd,
      enc_data,
      enc_info,
      good_mny,
      good_name,
    } = payload;
    if (
      !classId ||
      !tran_cd ||
      !enc_data ||
      !enc_info ||
      !good_mny ||
      !good_name
    ) {
      alert("다시 시도해주세요.");
      return;
    }
    const result = await apiInstance.payments.reqClsPay({
      userId,
      classId,
      tran_cd,
      enc_data,
      enc_info,
      good_mny,
      good_name,
    });
    return result;
  },
  // 모바일 일반 결제 거래 등록
  reqRegPay: async (store, payload) => {
    const {
      ordr_idxx,
      good_mny,
      good_name,
      pay_method,
      Ret_URL,
      escw_used,
      subsYn,
    } = payload;
    if (!ordr_idxx || !good_mny || !good_name || !pay_method || !Ret_URL) {
      alert("다시 시도해주세요.");
      return;
    }
    const result = await apiInstance.payments.reqRegPay({
      ...payload,
      ...(subsYn && { subsYn }),
    });

    return result;
  },
  // pc 배치키 발급 요청
  reqBatchIssue: async (store, payload) => {
    const {
      userId = globalStore.getters["users/getUserUuid"],
      tran_cd,
      enc_data,
      enc_info,
      card_mask_no,
      subsYn,
    } = payload;
    if (!tran_cd || !enc_data || !enc_info || !userId || !card_mask_no) {
      alert("다시 시도해주세요.");
      return;
    }
    const result = await apiInstance.payments.reqBatchIssue({
      userId,
      tran_cd,
      enc_data,
      enc_info,
      card_mask_no,
      ...(subsYn && { subsYn }),
    });
    return result;
  },
  /* cards/{uuid} 사용자의 카드 목록 조회 */
  reqGetCards: async (store, payload = {}) => {
    const { userId = store.rootGetters["users/getUserUuid"] } = payload;
    const result = await apiInstance.payments.getCards({ userId });
    if (result.success) {
      store.commit("setCards", result.data);
    }
    return result;
  },
  /* cards 카드 삭제 */
  reqDeleteCards: async (store, { id }) => {
    const data = { id, userId: store.rootGetters["users/getUserUuid"] };

    const result = await apiInstance.payments.deleteCards(data);
    if (result.success) {
      store.commit("deleteCard", id);
    } else {
      let msg = "";
      switch (result.name) {
        case "REQUEST_NOT_FOUND":
          msg = "<p>카드 정보를 찾을 수 없습니다.</p>";
          break;
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "BAD_REQUEST":
          msg =
            "<p>요금 플랜, 기타결제에서 사용되고 있는 카드는 삭제할 수 없습니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({ open: true, title: "삭제 불가", msg });
    }
    return result;
  },
  /* cards 카드 삭제 */
  reqPutCards: async (store, payload) => {
    const { id, remark, color, subsYn, targets } = payload;
    if (!id && targets?.length < 1) {
      return { success: false };
    }
    const data = {
      id,
      userId: store.rootGetters["users/getUserUuid"],
      remark,
      color,
      ...(subsYn && { subsYn }),
      ...(targets?.length > 0 && { targets }),
    };

    const result = await apiInstance.payments.putCards(data);
    if (result.success) {
      store.commit("updateCard", result.data);
    }
    return result;
  },
  // 캠퍼스관리 > 요금 플랜 > 요금 플랜 결제이력 조회
  reqGetPlanHistories: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      paymentStatus,
      planHistoryType,
      planType,
      sDate,
      eDate,
      order = "DESC",
      limit,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearAllPlanHistories");
    }
    const lastKey = store.getters["getPlanHistoriesLastKey"];

    const result = await apiInstance.payments.getPlanHistories({
      campusId,
      memberId,
      ...(paymentStatus && { paymentStatus }),
      ...(planHistoryType && { planHistoryType }),
      ...(planType && { planType }),
      ...(sDate && { sDate: toJsonDateFormat(sDate, true) }),
      ...(eDate && { eDate: toJsonDateFormat(eDate) }),
      ...(order && { order }),
      ...(limit && { limit }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setPlanHistories", results);
      store.commit("setPlanHistoriesLastKey", lastKey);
    }
    return result;
  },
  reqGetCheckoutHistory: async (store, payload) => {
    const { campusId = store.rootGetters["campuses/getCampusUuid"], id } =
      payload;
    const result = await apiInstance.payments.getCheckoutHistory({
      campusId,
      id,
    });
    if (result.success) {
      store.commit("setCheckoutHistory", result.data);
    }
    return result;
  },
  reqSalesFree: async (store, payload) => {
    const { classId, userId = store.rootGetters["users/getUserUuid"] } =
      payload;
    const result = await apiInstance.payments.reqSalesFree({
      userId,
      classId,
    });
    return result;
  },
  // 무료/유료 콘텐츠 상품 결제 요청
  reqProductPayment: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      productId,
      promotionId,
      customerName,
      enc_data,
      enc_info,
      tran_cd,
      good_mny,
      good_name,
      ordr_idxx,
      contact,
      email,
      testYn,
      paymentWay,
      priceId,
      couponId,
      cardId,
      subscriptionYn,
      quota,
      linkPk,
      linkId,
      partnerCode,
      referrer,
    } = payload;
    if (!productId || !campusId || !userId || !good_mny || !good_name) {
      alert("필수 항목 누락. 다시 시도해주세요.");
      return;
    }

    const result = await apiInstance.payments.postProductPayment({
      memberId,
      campusId,
      userId,
      productId,
      promotionId,
      customerName,
      enc_data,
      enc_info,
      tran_cd,
      good_mny,
      good_name,
      ordr_idxx,
      contact,
      email,
      testYn,
      paymentWay,
      priceId,
      couponId,
      cardId,
      subscriptionYn,
      quota,
      linkPk,
      linkId,
      partnerCode,
      referrer,
    });
    if (result.success) {
      store.commit("setProductPayment", result.data);
    } else {
      let msg = "";
      const cause = result?.cause || "";
      switch (result.name) {
        case "INVALID_PARAMETER":
          if (cause === "COUPON") {
            msg = "쿠폰적용 오류입니다.";
          } else if (cause === "AMOUNT") {
            msg = "결제 금액 오류입니다.";
          } else if (cause === "PROMOTION") {
            msg = "프로모션 미적용 결제건입니다.";
          } else {
            msg = result?.message || "필수 항목 누락";
          }
          break;
        case "REQUEST_NOT_FOUND":
          if (cause === "PRODUCT") {
            msg = "프로덕트 정보 찾을 수 없습니다.";
          } else if (cause === "USER") {
            msg = "사용자 정보 찾을 수 없습니다.";
          } else if (cause === "PROMOTION") {
            msg = "프로모션 정보를 찾을 수 없습니다.";
          } else if (cause === "RESERVATION_PRICE") {
            msg = "예약프로덕트의 가격옵션 찾을 수 없습니다.";
          } else if (cause === "CARD") {
            msg = "등록된 카드정보를 찾을 수 없습니다.";
          } else {
            msg = "결제 대상 정보를 찾을 수 없습니다.";
          }
          break;
        case "BAD_REQUEST":
          msg = "<p>잘못된 결제 금액입니다.</p>";
          break;
        case "LIMIT_EXCEED":
          if (cause === "COUPON_MIN") {
            msg = "쿠폰 적용 최소가격 오류입니다.";
          } else if (cause === "EXPIRED") {
            msg = "유효기간이 만료된 쿠폰입니다.";
          } else {
            msg = `품절된 프로덕트입니다.`;
          }
          break;
        case "ACCESS_DENIED":
          msg = `구매 불가한 프로덕트입니다.`;
          break;
        case "DUPLICATE":
          msg = `이미 구매된 프로덕트/프로모션입니다.`;
          break;
        case "KCP_ERROR":
          msg = result?.message || "KCP 오류. 다시 시도해주세요.";
          break;
        case "UNAVAILABLE":
          if (cause === "PAYMENT_WAY") {
            msg = "사용 불가한 결제수단입니다.";
          } else if (cause === "NOT_MEMBER") {
            msg = "캠퍼스 멤버가 아닌 경우 구매 불가합니다.";
          } else if (cause === "PROMOTION") {
            msg = "프로모션 적용 기간이 아닙니다.";
          } else if (cause === "USED") {
            msg = "이미 사용 완료된 쿠폰입니다.";
          } else if (["TARGETS", "APPLY_TYPES", "IGNORE"]?.includes(cause)) {
            msg = "쿠폰 사용이 불가한 결제건입니다.";
          } else if (cause === "PROMOTION_APPLY") {
            msg = "프로모션 적용 제외 결제건입니다.";
          } else if (cause === "PRODUCT_SUBSCRIPTION") {
            msg = " 정기구매 유형을 제공하지 않는 결제건입니다.";
          } else {
            msg =
              "판매신청 미승인 캠퍼스입니다. <br />캠퍼스 관리자에게 문의해주세요.";
          }
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open(
        {
          open: true,
          title: "결제 실패",
          csLink: true,
          msg,
        },
        { root: true }
      );
    }
    return result;
  },
  // 프로덕트 결제 이후 구매자(예약자) 추가 정보 업데이트 요청
  putProductAdditional: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      paymentId,
      custAdditionals,
    } = payload;

    if (!paymentId || custAdditionals?.length < 1) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.payments.putProductAdditional({
      campusId,
      paymentId,
      custAdditionals: custAdditionals.map((v) => {
        if (v.inputType === "ADDR") {
          return {
            ...v,
            value: `${v.value}\n${v.post}`.trim(),
          };
        }
        return v;
      }),
    });
    if (result.success) {
      store.commit("setProductPayment", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "INVALID_PARAMETER":
          msg = "<p>필수항목 누락</p>";
          break;
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 내역 찾을 수 없습니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "결제 오류",
        msg,
      });
    }
    return result;
  },
  // 기타결제 승인처리
  reqEtcProductPayment: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      productId,
      targetIds,
    } = payload;
    if (!campusId || !userId) {
      alert("필수 항목 누락. 다시 시도해주세요.");
      return;
    }

    const result = await apiInstance.payments.postEtcProductPayment({
      memberId,
      campusId,
      userId,
      productId,
      targetIds,
    });
    if (result.success) {
      // store.commit("setProductPayment", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 대상 정보를 찾을 수 없습니다.</p>";
          break;
        case "INVALID_PARAMETER":
        case "BAD_REQUEST":
          msg = "<p>잘못된 결제 금액입니다.</p>";
          break;
        case "LIMIT_EXCEED":
          msg = `<p>품절된 프로덕트입니다.`;
          break;
        case "ACCESS_DENIED":
          msg = `<p>구매 불가한 프로덕트입니다.</p>`;
          break;
        case "DUPLICATE":
          msg = `<p>이미 구매된 프로덕트/프로모션입니다.</p>`;
          break;
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "UNAVAILABLE":
          msg =
            "<p>판매신청 미승인 캠퍼스입니다. <br />캠퍼스 관리자에게 문의해주세요.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open(
        {
          open: true,
          title: "결제 실패",
          msg,
        },
        { root: true }
      );
    }
    return result;
  },
  // 캠퍼스관리 > 프로덕트 > 결제 내역 > 관리자 취소 처리
  cancelProductPaymentStaff: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      paymentId,
      paymentStatus,
      cancelReason,
      remark,
      refund,
      mod_depositor,
      mod_account,
      mod_bankcode,
    } = payload;
    let uglyAccount = "";
    if (mod_account) {
      uglyAccount = await encryptPublic(mod_account);
    }
    const result = await apiInstance.payments.cancelProductPaymentStaff({
      paymentId,
      campusId,
      memberId,
      userId,
      paymentStatus,
      cancelReason,
      remark,
      refund,
      mod_depositor,
      mod_account: uglyAccount,
      mod_bankcode,
    });
    if (!result.success) {
      let title = "조회 불가";
      let msg = "";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "UNAUTHORIZE":
          msg = "<p>권한이 없습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 내역 찾을 수 없습니다.</p>";
          break;
        case "UNAVAILABLE":
          msg = `<p>취소 처리가 불가한 결제건 입니다.</p>`;
          break;
        case "KCP_ERROR":
          title = "결제 오류";
          msg = result?.message || "KCP_ERROR";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title,
        msg,
      });
    }
    return result;
  },
  // 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 조회
  reqGetUserDetailPayments: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
      productId,
      promotionId,
      keyword,
      order,
      basis,
      paymentStatus,
      limit,
      first,
      inPaymentStatus,
      productType,
      orderStatusQuery,
    } = payload;
    if (first) {
      store.commit("clearUserDetailPayments");
    }
    const lastKey = store.rootGetters["payments/getUserDetailPaymentsLastKey"];

    const result = await apiInstance.payments.getUserDetailPayments({
      campusId,
      memberId,
      userId,
      productId,
      promotionId,
      keyword,
      order,
      basis,
      paymentStatus,
      limit,
      lastKey,
      ...(inPaymentStatus?.length > 0 && { inPaymentStatus }),
      ...(productType && { productType }),
      ...(orderStatusQuery && { orderStatusQuery }),
    });
    if (result.success) {
      store.commit("setUserDetailPayments", result.data);
    } else {
      const alert = useAlert();
      switch (result.name) {
        case "ACCESS_DENIED":
          alert.open({
            open: true,
            title: "접근 불가",
            msg: "접근이 제한된 페이지입니다.",
          });
          break;
      }
    }
    return result;
  },
  // 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 요약 조회
  reqGetUserDetailPaymentSummary: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
      basis,
    } = payload;
    const result = await apiInstance.payments.getUserDetailPaymentSummary({
      campusId,
      memberId,
      userId,
      basis,
    });
    if (result.success) {
      const { results } = result.data;
      store.commit("setUserDetailPaymentSummary", results);
    } else {
      const alert = useAlert();
      switch (result.name) {
        case "ACCESS_DENIED":
          alert.open({
            open: true,
            title: "접근 불가",
            msg: "접근이 제한된 페이지입니다.",
          });
          break;
      }
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 조회 (프로덕트 운영, 전체 프로덕트, 파트너 판매 현황 목록 공통),
  reqGetProductPaymentsHistories: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
      productId,
      promotionId,
      keyword,
      limit,
      paymentStatus,
      basis,
      order,
      first = true,
      inPaymentStatus,
      paymentWay,
      optionName,
      priceName,
      productType,
      discountType,
      originCouponId,
      groupId,
      sDate,
      eDate,
      sAmount,
      eAmount,
      purchaseType,
      partnerId,
      orderStatusQuery,
    } = payload;
    if (first) {
      store.commit("clearPaymentsHistories");
    }
    const lastKey =
      store.rootGetters["payments/getProductPaymentsHistoriesLastKey"];
    const result = await apiInstance.payments.getProductPaymentsHistories({
      campusId,
      memberId,
      userId,
      productId,
      promotionId,
      keyword,
      limit,
      paymentStatus,
      basis,
      order,
      lastKey,
      inPaymentStatus,
      paymentWay,
      optionName,
      priceName,
      productType,
      discountType,
      originCouponId,
      groupId,
      sDate,
      eDate,
      sAmount,
      eAmount,
      purchaseType,
      partnerId,
      orderStatusQuery,
    });

    if (result.success) {
      store.commit("setProductPaymentsHistories", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 조회엑셀다운로드
  reqGetProductPaymentsExcel: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      sDate,
      eDate,
      excelType,
      fileName,
      partnerId,
      basis,
    } = payload;

    if (!excelType) {
      return { success: false };
    }

    const result = await apiInstance.payments.getProductPaymentsExcel({
      campusId,
      memberId,
      excelType,
      ...(productId && { productId }),
      ...(sDate && { sDate }),
      ...(eDate && { eDate }),
      ...(fileName && { fileName }),
      ...(partnerId && { partnerId }),
      ...(basis && { basis }),
    });

    if (!result.success) {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "조회 일자를 선택해주세요.";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
        case "NOT_SUPPORTED":
          msg = "제휴 마케팅 부가서비스 미사용 상태입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 요약 조회
  reqGetProductPaymentsSummary: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      sDate,
      eDate,
      inPaymentStatus,
      partnerId,
    } = payload;

    const result = await apiInstance.payments.getProductPaymentsSummary({
      campusId,
      memberId,
      productId,
      sDate,
      eDate,
      ...(inPaymentStatus?.value?.length > 0 && { inPaymentStatus }),
      ...(partnerId && { partnerId }),
    });

    if (result.success) {
      store.commit("setProductPaymentsSummary", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
        case "NOT_SUPPORTED":
          msg =
            result?.cause === "PARTNERS"
              ? "제휴마케팅 부가서비스 사용하지 않는 경우 판매 현황을 조회할 수 없습니다. "
              : "지원하지 않는 요금제 / 부가 서비스입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "접근 불가",
        msg,
      });
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 요약 조회 > 대시보드 팝업
  reqGetDashboardPaymentsSummary: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      basis,
    } = payload;

    const result = await apiInstance.payments.getProductPaymentsSummary({
      campusId,
      memberId,
      productId,
      basis,
    });

    if (result.success) {
      store.commit("setDashboardPaymentsSummary", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "접근 불가",
        msg,
      });
    }
    return result;
  },
  //(사용자) 결제 내역 단건조회
  reqGetUserCheckoutHistory: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = globalStore.getters["users/getUserUuid"],
      paymentId,
    } = payload;

    const result = await apiInstance.payments.getUserCheckoutHistory({
      campusId,
      userId,
      paymentId,
    });
    if (result.success) {
      store.commit("setUserCheckoutHistory", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
        case "UNAUTHORIZE":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "결제 내역을 찾을 수 없습니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return result;
  },
  // 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 > 프로모션, 프로덕트 드롭다운 목록 조회
  reqGetUserPaymentDropDown: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
    } = payload;

    const result = await apiInstance.payments.getUserPaymentDropDown({
      campusId,
      memberId,
      userId,
    });
    if (result.success) {
      store.commit("setPaymentDropDownItems", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "권한이 없습니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "접근 불가",
        msg,
      });
    }
    return result;
  },
  // 마이페이지 > 내 구매내역
  reqGetUserPurchases: async (store, payload = {}) => {
    const {
      campusId,
      userId,
      keyword,
      paymentStatus,
      sDate,
      eDate,
      order,
      limit,
      first = true,
      productType,
      inPaymentStatus,
    } = payload;
    if (first) {
      store.commit("clearUserPurchases");
    }

    const lastKey = store.rootGetters["payments/getUserPurchasesLastKey"];
    const result = await apiInstance.payments.getUserPurchases({
      campusId,
      userId,
      keyword,
      paymentStatus,
      sDate,
      eDate,
      order,
      limit,
      lastKey,
      productType,
      ...(inPaymentStatus?.length > 0 && { inPaymentStatus }),
    });
    if (result.success) {
      store.commit("setUserPurchases", result.data);
    } else {
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg: "사용자 정보를 조회할 수 없습니다. 다시 시도해주세요.",
      });
    }
    return result;
  },
  // 마이페이지 > 내 구매내역 > 사용자 취소요청
  reqPutCancelProductPayment: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      paymentId,
      remark,
      cancelReason,
      paymentStatus,
      refund,
      mod_depositor,
      mod_account,
      mod_bankcode,
    } = payload;
    let uglyAccount = "";
    if (mod_account) {
      uglyAccount = await encryptPublic(mod_account);
    }
    const result = await apiInstance.payments.putCancelProductPayment({
      campusId,
      memberId,
      paymentId,
      userId,
      remark,
      cancelReason,
      paymentStatus,
      refund,
      mod_depositor,
      mod_account: uglyAccount,
      mod_bankcode,
    });
    if (result.success) {
      store.commit("setUserCancelPurchases", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "INVALID_PARAMETER":
          msg = "<p>필수항목 누락</p>";
          break;
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 내역 찾을 수 없습니다.</p>";
          break;
        case "UNAVAILABLE":
          msg = `<p> 취소요청이 불가한 결제건 입니다.<br/> 고객센터에 문의해주세요.</p>`;
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "취소 불가",
        msg,
      });
    }
    return result;
  },
  // 캠퍼스 관리 > 예약 관리 > 결제내역 > 옵션 필터 목록 조회
  reqGetReservationOptions: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      sDate,
      eDate,
      first = true,
    } = payload;

    if (first) {
      store.commit("clearReservationOptions");
    }

    if (!productId) {
      return { success: false };
    }
    const result = await apiInstance.payments.getReservationOptions({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(productId && { productId }),
      ...(sDate && { sDate }),
      ...(eDate && { eDate }),
    });
    if (result.success) {
      store.commit("setReservationOptions", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "UNAUTHORIZE":
          msg = "<p>권한이 없습니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }

    return result;
  },

  reqGetHistorySettlement: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      basis,
    } = payload;

    const result = await apiInstance.payments.getPaymentsHistorySettlement({
      campusId,
      memberId,
      basis,
    });

    if (result.success) {
      store.commit("setPaymentsHistorySettlement", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return result;
  },
  reqGetSubsPaymentsHistories: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productMemberId,
      limit,
      order,
      lastKey,
    } = payload;

    const result = await apiInstance.payments.getSubsPaymentsHistories({
      campusId,
      memberId,
      ...(productMemberId && { productMemberId }),
      ...(order && { order }),
      ...(limit && { limit }),
      ...(lastKey && { lastKey }),
    });

    if (!result.success) {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "조회 대상 프로덕트 멤버가 존재하지 않습니다.";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return result;
  },
  // 판매프로덕트 정기구매 실패 건 결제 재시도
  reqPostSubscriptionRetry: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      custMemberId,
    } = payload;
    if (!campusId || !memberId || !productId || !custMemberId) {
      alert("필수 항목 누락. 다시 시도해주세요.");
      return;
    }

    const result = await apiInstance.payments.postSubscriptionRetry({
      memberId,
      campusId,
      productId,
      custMemberId,
    });
    if (!result?.success) {
      let msg = `결제 실패되었습니다.<br/>결제수단 확인후 다시 시도해주세요.`;
      switch (result?.name) {
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 대상 정보를 찾을 수 없습니다.</p>";
          break;
        case "INVALID_PARAMETER":
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "UNAVAILABLE":
          msg = "<p>결제 실패 상태가 아닙니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({
        icon: "alert",
        title: "결제 실패",
        msg,
      });
    }
    return result;
  },
  postSubscriptionRetryUser: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      custMemberId = store.rootGetters["members/getMemberId"],
    } = payload;

    const result = await apiInstance.payments.postSubscriptionRetry({
      memberId,
      campusId,
      productId,
      custMemberId,
    });

    return result;
  },
  // 판매프로덕트 정기구매 해지 or 해지 취소
  reqPostSubscriptionChange: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      subsStatus,
      targetMemberIds,
      cancelReasonType,
      cancelReasonDetail,
    } = payload;
    if (!campusId || !memberId || !productId || !subsStatus) {
      alert("필수 항목 누락. 다시 시도해주세요.");
      return;
    }

    const result = await apiInstance.payments.postSubscriptionChange({
      memberId,
      campusId,
      productId,
      subsStatus,
      targetMemberIds,
      cancelReasonType,
      ...(cancelReasonDetail && { cancelReasonDetail }),
    });
    if (!result?.success) {
      let msg = `결제 실패되었습니다.<br/>결제수단 확인후 다시 시도해주세요.`;
      switch (result.name) {
        case "ACCESS_DENIED":
          break;
        case "INVALID_PARAMETER":
          break;
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "UNAVAILABLE":
          msg = "<p>기존 정기구매 상태와 맞지 않는 상태로 변경</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      const alert = useAlert();
      alert.open({
        icon: "alert",
        title: "결제 실패",
        msg,
      });
    }
    return result;
  },
  getHistoriesSubscriptionUser: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productMemberId,
      limit = 20,
      order = "DESC",
      lastKey,
    } = payload;
    const result = await apiInstance.payments.getSubsPaymentsHistories({
      campusId,
      memberId,
      productMemberId,
      limit,
      order,
      ...(lastKey && { lastKey }),
    });
    if (!result?.success) {
      let msg = "다시 시도해주세요.";
      switch (result?.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "회원 정보를 찾을 수 없습니다..";
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg,
      });
    }
    return result;
  },
  async cancelSubscriptionUser(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      cancelReasonType,
      cancelReasonDetail,
    } = payload;
    const res = await apiInstance.payments.postSubscriptionChange({
      targetMemberIds: [memberId],
      subsStatus: "PENDING_CANCEL",
      productId,
      campusId,
      memberId,
      cancelReasonType,
      ...(cancelReasonDetail && { cancelReasonDetail }),
    });
    return res;
  },
  async activeSubscriptionUser(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
    } = payload;
    const res = await apiInstance.payments.postSubscriptionChange({
      targetMemberIds: [memberId],
      subsStatus: "ACTIVE",
      productId,
      campusId,
      memberId,
    });
    return res;
  },
  // 파트너 서비스 > 수익 리포트 목록 조회
  async reqGetPartnerSalesReport(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productType,
      paymentStatus,
      limit,
      order,
      inPaymentStatus,
      purchaseType,
      lastKey,
      sDate,
      eDate,
      keyword,
      productId,
    } = payload;

    const res = await apiInstance.payments.getPartnerSalesReport({
      campusId,
      memberId,
      ...(productType && { productType }),
      ...(paymentStatus && { paymentStatus }),
      ...(limit && { limit }),
      ...(order && { order }),
      ...(inPaymentStatus?.length > 0 && { inPaymentStatus }),
      ...(purchaseType && { purchaseType }),
      ...(lastKey && { lastKey }),
      ...(sDate && { sDate }),
      ...(eDate && { eDate }),
      ...(keyword && { keyword }),
      ...(productId && { productId }),
    });
    return res;
  },

  // 파트너 서비스 > 수익 리포트 요약 조회
  async reqGetPartnerSalesSummary(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      sDate,
      eDate,
      productId,
    } = payload;

    const res = await apiInstance.payments.getPartnerSalesSummary({
      campusId,
      memberId,
      ...(sDate && { sDate }),
      ...(eDate && { eDate }),
      ...(productId && { productId }),
    });
    return res;
  },

  // ezwel 헤더 조회
  async reqGetEzwelHeader(store, payload) {
    const { token } = payload;
    const result = await apiInstance.payments.getEzwelHeader({
      token,
      campusId: store.rootGetters["campuses/getCampusUuid"],
    });
    if (result.success) {
      return result.data;
    }
  },
  // ezwel 주문정보 요청
  async reqEzwelOrder(store, payload) {
    const { orderData, tempOrderData, aesKey } = payload;
    const result = await apiInstance.payments.reqEzwelOrder({
      orderData,
      tempOrderData,
      aesKey,
      campusId: store.rootGetters["campuses/getCampusUuid"],
    });
    if (result.success) {
      return result.data;
    }
  },
});
