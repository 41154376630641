export default {
  setUser: (state, user) => {
    state.user = {
      ...user,
    };
    state.userUuid = user?.uuid || "";
  },
  updateUser: (state, user) => {
    state.user = {
      ...state.user,
      ...user,
    };
  },
};
