import isEmpty from "lodash.isempty";
import { datadogRum } from "@datadog/browser-rum";

export default (apiInstance) => ({
  setClearUser: async (store) => {
    store.dispatch("setUser", { user: {} });
    store.dispatch("notifications/setClearNotifications", null, { root: true });
  },
  setUser: async (store, payload) => {
    const { user } = payload;
    if (user) {
      store.commit("setUser", user);
      datadogRum.setUser({
        id: user.uuid,
        name: user.name,
        email: user.email,
      });
    } else {
      datadogRum.clearUser();
    }
  },
  getUserFromCognitoId: async (store, payload) => {
    const cognitoId = payload.cognitoId || store.getters["getCognitoId"];
    if (cognitoId) {
      const result = await apiInstance.users.getUserFromCognitoId({
        cognitoId,
        setAccInfo: payload.setAccInfo,
        accessKeyId: payload.accessKeyId,
        sigYn: payload.sigYn,
      });
      if (!isEmpty(result.data)) {
        const { user } = result.data;
        if (user) {
          store.dispatch("setUser", { user });
        }
      }
      return result;
    }
    return {
      success: false,
    };
  },
  reqCheckUserEmail: async (store, payload) => {
    const { email } = payload;
    if (email) {
      const result = await apiInstance.users.getCheckUserEmail(email);
      return result;
    }
  },
  reqPutUpdateUsers: async (store, payload) => {
    const {
      birthdate,
      gender,
      cp,
      name,
      image,
      nickname,
      intro,
      email,
      deviceId,
      // TODO: MKT
      mktYn,
    } = payload;
    const uuid = store.getters["getUserUuid"];
    const result = await apiInstance.users.putUpdateUsers({
      userId: uuid,
      ...((intro || intro === "") && { intro }),
      ...((cp || cp === "") && { cp }),
      ...((birthdate || birthdate === "") && { birthdate }),
      ...(name && { name }),
      ...(nickname && { nickname }),
      ...(gender && { gender }),
      ...(email && { email }),
      ...(image && { image }),
      deviceId, // 디바이스 아이디 삭제를 위해 빈 문자열을 보내는 경우 있음.
      // TODO: MKT
      mktYn,
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setUser", { user: data });
    } else {
      // cognito error
      store.dispatch(
        "common/setAlert",
        {
          open: true,
          msg: "<p>시스템 오류입니다.</p>",
          csLink: true,
        },
        { root: true }
      );
    }
    return result;
  },
  reqPutUpdateUserPush: async (store, payload) => {
    const { push } = payload;
    const uuid = store.getters["getUserUuid"];
    const result = await apiInstance.users.putUpdateUserPush({
      userId: uuid,
      push,
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setUser", { user: data });
    }
    return result;
  },
  reqUserQuit: async (store, payload) => {
    const { quitYn, reason } = payload;
    const uuid = store.getters["getUserUuid"];
    const result = await apiInstance.users.putUserQuit({
      userId: uuid,
      quitYn,
      reason,
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setUser", { user: data });
    }

    return result;
  },
});
