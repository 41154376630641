import { isLocal } from "@/config";

export function getCurrentPath() {
  const path = window.location.pathname;
  return path.slice(1);
}

export function checkLoginPath(route) {
  const currentPath = route
    ? isLocal
      ? route.path.replace(`/${route.params.campusDomain}/`, "")
      : route.path.substring(1)
    : getCurrentPath();
  if (currentPath === "") {
    return false;
  }
  const loginPath = [
    "login",
    "login/form",
    "join",
    "join/form",
    "join/complete",
    "join/agreement",
    "login",
    "login/",
    "login/form",
    "join/",
    "join/form",
    "join/complete",
    "login/reset-password",
  ];
  const findIndex = loginPath.findIndex((path) => path === currentPath);
  return findIndex > -1 || checkOauthCallback("naver");
}

export function checkCampusDetailPath(route) {
  if (!route || !route.matched || route.matched.length < 1) return false;
  const campusOnly =
    route.matched.findIndex((routeItem) => routeItem.meta.campusOnly) > -1;
  const shareCampus =
    route.name === "share-entry" &&
    (route.query.type === "campus" || route.query.type === "class");
  const result = campusOnly || shareCampus;
  return result;
}

export function checkOauthCallback(sns) {
  return getCurrentPath().indexOf(`oauth-callback/${sns}`) > -1;
}

export function checkAuthFilter(route) {
  const authRequired =
    route.matched.findIndex((routeItem) => routeItem.meta.authRequired) > -1;
  return authRequired;
}
