import { endpoint } from "@/config";

// cognito id로 유저 정보 조회
// sigYn: false인 경우, 로그인 테이블의 accessKeyId 업데이트
export async function getUserFromCognitoId({
  cognitoId,
  setAccInfo,
  accessKeyId,
  sigYn,
}) {
  const params = {};
  if (setAccInfo && accessKeyId) {
    params.setAccInfo = setAccInfo;
    params.accessKeyId = accessKeyId;
  }
  return this.getMethod("get", sigYn, {
    url: `${endpoint.users}/users/cognito${
      sigYn ? "/private" : "" // 시그니처 보내는 경우 api 주소 다르게 요청.
    }/${cognitoId}`,
    params,
  });
}

// check user emial(before reset password)
export async function getCheckUserEmail(email) {
  return this.getMethod("get", false, {
    url: `${endpoint.users}/users/email/${email}`,
  });
}

// get users
export async function reqGetUsers({ userId }) {
  return this.getMethod("get", true, {
    url: `${endpoint.users}/users/${userId}`,
  });
}

// register user
export async function postUsers({
  cognitoId,
  name,
  snsType,
  email,
  nickname = "",
  agreeYn = false,
  agreeDttm = null,
  mktYn,
}) {
  const data = {
    cognitoUuid: cognitoId,
    name,
    email,
    snsType,
    ...(nickname && { nickname }),
    ...(agreeYn && agreeDttm && { agreeYn, agreeDttm }),
    ...(mktYn !== undefined && { mktYn }),
  };
  return this.getMethod("post", false, {
    url: `${endpoint.users}/users`,
    data,
  });
}

// update user - personal info
export async function putUpdateUsers({
  userId,
  name,
  gender,
  cp,
  email,
  birthdate,
  image,
  nickname,
  intro,
  deviceId,
  // TODO: MKT
  mktYn,
}) {
  const data = {
    ...((intro || intro === "") && { intro }),
    ...((cp || cp === "") && { cp }),
    ...((birthdate || birthdate === "") && { birthdate }),
    ...(name && { name }),
    ...(nickname && { nickname }),
    ...(gender && { gender }),
    ...(email && { email }),
    ...(image && { image }),
    deviceId,
    // TODO: MKT
    mktYn,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.users}/users/${userId}`,
    data,
  });
}

// update user - push setting
export async function putUpdateUserPush({ userId, push }) {
  const data = {
    push,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.users}/users/${userId}`,
    data,
  });
}

// update user image
export async function postImage({ userType, uuid, file }) {
  const data = new FormData();
  data.append("file", file);

  return this.getMethod("post", true, {
    url: `${endpoint.users}/${userType}/image/${uuid}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// update user - push setting
export async function putUserQuit({ userId, quitYn, reason }) {
  const data = {
    quitYn,
    reason,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.users}/users/quit/${userId}`,
    data,
  });
}

// instructor
export async function postInstructors({
  userId,
  name,
  intro,
  sns,
  cp,
  realName,
  image,
  biz,
}) {
  const data = {
    uuid: userId,
  };
  if (name) {
    data.name = name;
  }
  if (intro) {
    data.intro = intro;
  }
  if (sns) {
    data.sns = sns;
  }
  if (realName) {
    data.realName = realName;
  }
  if (cp) {
    data.cp = cp;
  }
  if (image) {
    data.image = image;
  }
  if (biz) {
    data.biz = biz;
  }
  return this.getMethod("post", true, {
    url: `${endpoint.users}/instructor`,
    data,
  });
}

export async function putInstructors({
  userId,
  name,
  intro,
  sns,
  cp,
  realName,
  biz,
}) {
  const data = {};
  if (name) {
    data.name = name;
  }
  if (intro) {
    data.intro = intro;
  }
  if (sns) {
    data.sns = sns;
  }
  if (realName) {
    data.realName = realName;
  }
  if (cp) {
    data.cp = cp;
  }
  if (biz) {
    data.biz = biz;
  }
  return this.getMethod("put", true, {
    url: `${endpoint.users}/instructor/${userId}`,
    data,
  });
}

/* user subscriptions */
export async function getUserSubscriptions({ uuid }) {
  return this.getMethod("get", true, {
    url: `${endpoint.users}/users/subscription/${uuid}`,
  });
}

/* instructor subscriptions */
export async function getInstructorSubscriptions({ uuid }) {
  return this.getMethod("get", true, {
    url: `${endpoint.users}/instructor/subscription/${uuid}`,
  });
}

/* post subscriptions */
export async function postSubscriptions({ uuid, tarUuid, subscrYn }) {
  const data = {
    uuid,
    tarUuid,
    subscrYn,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.users}/instructor/subscription`,
    data,
  });
}

/* instructor/accounts/{uuid} 강사 계좌정보 조회 */
export async function getInstructorAccounts({ uuid }) {
  return this.getMethod("get", true, {
    url: `${endpoint.users}/instructor/accounts/${uuid}`,
  });
}

/* instructor/accounts/{uuid} 강사 계좌정보 저장 */
export async function postInstructorAccounts({ uuid, accounts }) {
  const data = { accounts };
  return this.getMethod("post", true, {
    url: `${endpoint.users}/instructor/accounts/${uuid}`,
    data,
  });
}
