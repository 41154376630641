import {
  MemberStatus,
  ProgressStatus,
  MemberChangeType,
} from "@/enums/membersEnum";
import { PayMethod, PurchaseType, retWay } from "@/enums/paymentsEnum";
import { PaymentStatus } from "@/enums/paymentsEnum";
import { QuizStatus, ProductType } from "@/enums/productsEnum";
import { AskStatus } from "@/enums/askEnum";
import {
  LogisCd,
  DeliveryWay,
  DeliveryCostType,
  DeliveryPayType,
  CombineCalcType,
} from "@/enums/productsEnum";
import { getDateFormat, getDayLeft } from "@/utils/date-format";
import { comma } from "@/utils/number-format";
import CryptoJS from "crypto-js";
import { passwordSecretKey, kmsPublicKey } from "@/config";
import store from "@/store";

/**
 * 단순 텍스트에서 url을 찾아 a태그로 감싸준다.
 * html 형식의 텍스트에서는 불가.
 * @param inputText
 * @return {*|string}
 */
export const linkify = (inputText) => {
  if (!inputText) return "";
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  // URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a class="blue--text" href="$1" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a class="blue--text" href="http://$2" target="_blank">$2</a>'
  );

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a class="blue--text" href="mailto:$1">$1</a>'
  );

  return replacedText;
};

/**
 * html 형식의 문자열에서 url 형식의 텍스트 요소를 a태그로 감싸서 반환한다.
 * @param html
 * @return {string}
 */
export const linkifyHtml = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const walk = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, null);
  while (walk.nextNode()) {
    const linkified = linkify(walk.currentNode.textContent);
    walk.currentNode.textContent = linkified;
  }

  // 삽입한 linkify 텍스트가 html 엔터티 코드로 변환되기때문에, decode 과정 필요하다.
  return decodeHTMLEntities(tempDiv.innerHTML);
};

export const strip = (str) => {
  return str.replace(/^\s+|\s+$/g, "");
};

// 모든 공백 제거
export const removeAllWhitespace = (str) => {
  return str.replace(/\s+/g, "");
};

/**
 * 이모지 제거
 * https://stackoverflow.com/a/69661174
 * @param str
 * @returns {string|*}
 */
export const removeEmoji = (str) => {
  if (str) {
    return str.replace(
      /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu,
      ""
    );
  }
  return "";
};

export const sanitizeInput = (input) => {
  // 숫자와 "-"만 포함된 문자열을 만들기 위한 정규표현식
  var regex = /[0-9-]/g;

  // 입력값에서 허용된 문자 이외의 문자를 제거하고 반환
  return input?.replaceAll(" ", "")?.match(regex)?.join("") || "";
};

export const phoneNumberFormat = (value) => {
  let changeValue = strip(value).replace(/-/g, "");
  if (changeValue.length === 10) {
    if (changeValue.substr(0, 2) === "02") {
      changeValue = changeValue.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      changeValue = changeValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  } else if (changeValue.length === 9) {
    changeValue = changeValue.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (changeValue.length === 8) {
    changeValue = changeValue.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    changeValue = changeValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return sanitizeInput(changeValue);
};

export const bizNumberFormat = (value) => {
  let companyNum = value.replace(/[^0-9]/g, "");

  let tempNum = "";

  if (companyNum.length < 4) {
    return companyNum;
  } else if (companyNum.length < 6) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    return tempNum;
  } else if (companyNum.length < 11) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);

    return tempNum;
  } else {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);
    return tempNum;
  }
};

export const birthFormat = (value) => {
  let changeValue = strip(value).replace(/-/g, "");
  changeValue = changeValue.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
  return changeValue;
};

export const snsTypeFilter = (type) => {
  switch (type) {
    case "NONE":
      return "이메일 가입";
    case "GOOGLE":
      return "구글";
    case "NAVER":
      return "네이버";
    case "KAKAO":
      return "카카오";
    case "FACEBOOK":
      return "페이스북";
    case "APPLE":
      return "애플";
    default:
      return "";
  }
};

export const encryptor = (txt) => {
  return txt.replace(/(\w)(\w)/g, function (a) {
    return a[0] + "*";
  });
};

export const roldCdFilter = (type) => {
  switch (type) {
    case "A":
      return "개설자";
    case "B":
      return "운영자";
    case "C":
      return "강의자";
    case "D":
      return "회원";
    default:
      return "";
  }
};

export const memberStatusName = (status) => {
  switch (status) {
    case "JOIN":
      return "승인됨";
    case "INVITE":
      return "초대됨";
    case "REQUEST":
      return "대기중";
    case "REJECT":
      return "거절됨";
    case "RETRY_REQUEST":
      return "재신청";
    default:
      return status;
  }
};

export const memberStatusChip = (status, member) => {
  switch (status) {
    case "DEL": {
      if (
        [
          PaymentStatus.PAY_REJECT,
          PaymentStatus.CANCEL,
          PaymentStatus.PAY_REVERT,
        ].includes(member?.paymentStatus)
      ) {
        return { text: "결제 취소", theme: "error" };
      }
      return { text: "내보냄", theme: "gray" };
    }
    case "JOIN":
      return { text: "참여중", theme: "success" };
    case "REQUEST":
      return { text: "대기중", theme: "info" };
    case "PAY_REQUEST":
      return { text: "결제 대기", theme: "info" };
    case "REJECT":
      return { text: "거절됨", theme: "error" };
    case "RETRY_REQUEST":
      return { text: "재신청", theme: "info" };
    case "CANCEL":
      return { text: "신청 취소", theme: "gray" };
    default:
      return { text: "초대됨", theme: "success" };
  }
};
export const addrSubscribeStatusChip = (status) => {
  switch (status) {
    case "SUBSCRIBE":
      return { text: "구독 중", theme: "success", link: "'수신 거부'로 변경" };
    case "REJECT":
      return { text: "수신 거부", theme: "error", link: "'구독 중'으로 변경" };
    case "UNAVAILABLE":
      return { text: "수신 불가", theme: "gray" };
    default:
      return { text: "-", theme: "success" };
  }
};
export const mktYnChip = (confirm) => {
  if (confirm) {
    return { text: "광고 수신동의 O", theme: "success" };
  }
  return { text: "광고 수신동의 X", theme: "error" };
};
export const paymentStatusChip = (status) => {
  switch (status) {
    case "CANCEL_REQ":
      return { text: "환불 신청", theme: "info" };
    case "CANCEL_PART":
      return { text: "부분 환불", theme: "success" };
    case "CANCEL_REJECT":
      return { text: "환불 거부", theme: "error" };
    case "PAY_REJECT":
      return { text: "결제 취소", theme: "error" };
    case "CANCEL":
      return { text: "전체 환불", theme: "success" };
    case "PAID":
      return { text: "결제 완료", theme: "gray" };
    case "PAY_REQUEST":
      return { text: "결제 대기", theme: "info" };
    case "PAY_REVERT":
      return { text: "결제 취소", theme: "error" };
    default:
      return { text: "결제 요청", theme: "info" };
  }
};
export const orderStatusChip = (status) => {
  switch (status) {
    case "PAID":
      return { text: "결제 완료", theme: "success" };
    case "PREPARE":
      return { text: "상품 준비중", theme: "primary" };
    case "SHIPPING":
      return { text: "배송중", theme: "info" };
    case "SHIP_DONE":
      return { text: "베송 완료", theme: "success" };
    case "CONFIRMED":
      return { text: "구매 확정", theme: "gray" };
    case "CANCEL":
      return { text: "결제 취소", theme: "gray" };
    case "RET_DONE":
      return { text: "반품 완료", theme: "gray" };
    default:
      return { text: "상태 없음", theme: "error" };
  }
};
export const planPaymentStatusFilter = (status) => {
  switch (status) {
    case "CANCEL":
    case "REFUND":
      return { text: "환불 완료", theme: "gray" };
    case "REFUND_CANCEL":
      return { text: "환불 취소", theme: "error" };
    case "PAID":
      return { text: "결제 완료", theme: "success" };
    case "CANCEL_PART":
      return { text: "부분 취소", theme: "gray" };
    default:
      return { text: "", theme: "" };
  }
};
export const planPaymentTypeFilter = (status) => {
  switch (status) {
    case "TERMINATE":
      return "해지";
    case "CHANGE":
      return "변경";
    case "NEW":
      return "신규";
    default:
      return "정기 결제";
  }
};
export const planPaymentTargetFilter = (type) => {
  switch (type) {
    case "PLAN":
      return "요금 플랜";
    case "ADDON":
      return "부가 서비스";
    default:
      return "";
  }
};

export const completionStatusFilter = (status) => {
  switch (status) {
    case ProgressStatus.COMPLETE_FORCE:
      return {
        text: "관리자 완료",
        theme: "error",
      };
    case ProgressStatus.COMPLETE:
      return {
        text: "진행완료",
        theme: "success",
      };
    case ProgressStatus.PROGRESS:
      return {
        text: "진행중",
        theme: "primary",
      };
    case ProgressStatus.EXCEED:
      return {
        text: "시간초과(이탈)",
        theme: "error",
      };
    case ProgressStatus.NONE:
      return {
        text: "진행전",
        theme: "gray",
      };
  }
  return {
    text: "진행전",
    theme: "gray",
  };
};

export const memberConfirmTxt = (status) => {
  switch (status) {
    case MemberChangeType.APPROVAL:
      return {
        title: "승인",
        msg: "참여 신청을 승인하시겠습니까?",
        btnTxtConfirm: "승인하기",
        btnTxtCancel: "아니오",
        icon: "confirm",
      };
    case MemberChangeType.REJECT:
      return {
        title: "거절",
        msg: "참여 신청을 거절하시겠습니까?",
        btnTxtConfirm: "거절하기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
    default:
      return {
        title: "내보내기",
        msg: "내보내기된 회원은 더이상 프로덕트에 참여할 수 없습니다. <br />회원을 내보내시겠습니까?",
        btnTxtConfirm: "내보내기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
  }
};
export const userConfirmTxt = (status, length) => {
  switch (status) {
    case MemberChangeType.APPROVAL:
      return {
        title: "가입 승인",
        msg: `${length}명의 신청자를 가입 승인 처리하시겠습니까?`,
        btnTxtConfirm: "승인하기",
        btnTxtCancel: "아니오",
        icon: "confirm",
      };
    case MemberChangeType.REJECT:
      return {
        title: "가입 거절",
        msg: `${length}명의 신청자를 가입 거절 처리하시겠습니까?`,
        btnTxtConfirm: "거절하기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
    default:
      return {
        title: "내보내기",
        msg: "회원을 내보내시겠습니까?",
        btnTxtConfirm: "내보내기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
  }
};

export const paymentFilter = (item) => {
  // 정기 결제 && 결제 금액 0 원 > 결제 수단 '-' 표시
  if (item?.purchaseType === "SUBSCRIPTION" && item?.amount === 0) {
    return "-";
  }
  switch (item?.paymentWay) {
    case "NONE":
      return "-";
    case "ETC":
      return "기타 결제";
    case "KAKAO":
      if (item?.pay_method === PayMethod.PACA) {
        return `카카오 페이 (${
          item.org?.displayName || item.org?.name || "카드"
        }, ${item?.org?.number || ""})`;
      }
      if (item?.pay_method === PayMethod.PAKM) {
        return `카카오 페이 (머니)`;
      }
      return "카카오 페이";
    case "NAVER":
      if (item?.pay_method === PayMethod.PACA) {
        return `네이버 페이 (${
          item.org?.displayName || item.org?.name || "카드"
        }, ${item?.org?.number || ""})`;
      }
      if (item?.pay_method === PayMethod.PANP) {
        return `네이버 페이 (포인트)`;
      }
      return "네이버 페이";
    case "BANK":
      return "실시간 계좌이체";
    case "V_BANK":
      return "가상 계좌이체";
    case "CARD":
      return `(${item.org?.displayName || item.org?.name || "카드"}) ${
        item?.org?.number || ""
      }`;
    case "CASH":
      return "현금";
    case "EZWEL":
      return "이지웰";
    default:
      return "-";
  }
};

export const pubTargetFilter = (value) => {
  switch (value) {
    case "MEMBER_ONLY":
      return { text: "회원공개", theme: "info" };
    default:
      return { text: "전체공개", theme: "gray" };
  }
};

export const snsIconFilter = (type) => {
  switch (type) {
    case "GOOGLE":
      return "Google/20";
    case "FACEBOOK":
      return "Facebook/20";
    case "KAKAO":
      return "Kakao/20";
    case "NAVER":
      return "Naver/20";
    case "APPLE":
      return "Apple/20";
    default:
      return "Email/20";
  }
};

export const formatFooterLink = (val) => {
  switch (val) {
    case "HOMEPAGE":
      return "홈페이지";
    case "FACEBOOK":
      return "Facebook";
    case "INSTAGRAM":
      return "Instagram";
    case "NAVER":
      return "Naver Blog";
    case "KAKAO":
      return "Kakao";
  }
  return "";
};

export const salesStatusFilter = (type) => {
  switch (type) {
    case "REQUEST":
      return "신청중";
    case "REJECT":
      return "거절";
    case "ENABLE":
      return "승인";
    case "COMPLEMENTATION":
      return "보완 요청";
    default:
      return "미신청";
  }
};

export const etcApplyStatusFilter = (type) => {
  switch (type) {
    case "REQUEST":
      return "신청중";
    case "REJECT":
      return "거절";
    case "ENABLE":
      return "승인";
    case "COMPLEMENTATION":
      return "보완 요청";
    default:
      return "미신청";
  }
};

export const planCodeFilter = (plan = {}) => {
  const { planCode = "" } = plan;
  switch (planCode) {
    case "FREE":
      return "무료";
    case "ONDEMAND":
      return "온디맨드";
    case "LIGHT":
      return "라이트";
    case "STARTER":
      return "Starter 플랜";
    case "STANDARD":
      return "Standard 플랜";
    case "PREMIUM":
      return "Premium 플랜";
    case "BUSINESS":
      return "Business 플랜";
    case "ENTERPRISE":
      return "Enterprise 플랜";
    default:
      return planCode;
  }
};
export const planCycleFilter = (type) => {
  switch (type) {
    case "MONTHLY":
      return "월간";
    case "ANNUALY":
    case "ANNUALLY":
      return "연간";
  }
};
export const feedBackStatusFilter = (type) => {
  switch (type) {
    case "READY":
      return { text: "답변 대기", theme: "gray" };
    case "COMPLETE":
      return { text: "답변 완료", theme: "success" };
    default:
      return;
  }
};
export const feedBackAskTargetFilter = (type) => {
  switch (type) {
    case "AI":
      return { text: "🖥️ AI", theme: "info" };
    case "PRO":
      return { text: "전문가", theme: "gray" };
    default:
      return;
  }
};
export const markStatusFilter = (type) => {
  switch (type) {
    case QuizStatus.PASS:
    case QuizStatus.FAIL:
      return {
        text: "채점완료",
        theme: "success",
      };
    case QuizStatus.SUBMIT:
      return {
        text: "채점필요",
        theme: "error",
      };
    default:
      return;
  }
};

export const getAnswerStatusIcon = (question) => {
  const obj = question?.activity || {};
  switch (obj.answerStatus) {
    case "CORRECT":
      return "Circle/Success";
    case "PART":
      return "Triangle/Secondary";
    case "INCORRECT":
      return "Cross/Error";
    case "NONE":
      return "";
  }
  return "";
};

export const applicationStatusFilter = (status) => {
  switch (status) {
    case "PICK":
      return {
        text: "선정",
        theme: "success",
      };
    case "DROP":
      return {
        text: "미선정",
        theme: "gray",
      };
    case "SUBMIT":
      return {
        text: "확인필요",
        theme: "error",
      };
    case "HOLD":
      return {
        text: "보완요청",
        theme: "info",
      };
    default:
      return;
  }
};
export const questionTypeFilter = (status) => {
  switch (status) {
    case "OBJT":
      return "객관식";
    case "SBJT":
      return "주관식";
    case "DESC":
      return "서술형";
    case "FILE":
      return "파일제출";
    default:
      return "";
  }
};

export const addOnTypeFilter = (planCode) => {
  switch (planCode) {
    case "QUIZ":
      return {
        icon: "Service/Quiz",
        text: "퀴즈&과제",
      };
    case "CODING":
      return {
        icon: "Service/Coding",
        text: "코딩 실습",
      };
    case "PRODUCT_BASIC":
      return {
        icon: "Service/ManagementProduct",
        text: "콘텐츠 상품 (운영)",
      };
    case "AI_INSTRUCTOR":
      return {
        icon: "Service/AI",
        text: "AI 조교",
      };
    case "CERTIFICATE":
      return {
        icon: "Service/Diploma",
        text: "수료증",
      };
    case "EXTRA_VOLUME":
      return {
        icon: "Service/Cloud",
        text: "용량 추가",
      };
    case "APPLICATION":
      return {
        icon: "Service/Application",
        text: "신청서",
      };
    case "RESERVATION":
      return {
        icon: "Service/Reservation",
        text: "예약",
      };
    case "ETC_PAY":
      return {
        icon: "Service/EtcPayment",
        text: "기타 결제",
      };
    case "EMAIL_CAMPAIGN":
      return {
        icon: "Service/Email",
        text: "이메일",
      };
    case "PARTNERS":
      return {
        icon: "Service/Partners",
        text: "제휴 마케팅",
      };
    case "PRODUCT_GOODS":
      return {
        icon: "Service/Goods",
        text: "실물 상품",
      };
    default:
      return {
        icon: "BlankImage",
      };
  }
};

export const notSupportedConfirmText = (type) => {
  let contentTypeName = "";
  switch (type) {
    case "QUIZ":
      contentTypeName = "퀴즈&과제";
      break;
    case "CODING":
      contentTypeName = "코딩 실습";
      break;
    case "PRODUCT_BASIC":
      contentTypeName = "콘텐츠 상품 (운영)";
      break;
    case "AI_INSTRUCTOR":
      contentTypeName = "AI 조교";
      break;
    case "CERTIFICATE":
      contentTypeName = "수료증";
      break;
    case "EXTRA_VOLUME":
      contentTypeName = "용량 추가";
      break;
    case "APPLICATION":
      contentTypeName = "신청서";
      break;
    case "RESERVATION":
      contentTypeName = "예약";
      break;
    case "ETC_PAY":
      contentTypeName = "기타 결제";
      break;
    case "PARTNERS":
      contentTypeName = "제휴 마케팅";
      break;
    case "PRODUCT_GOODS":
      contentTypeName = "실물 상품";
      break;
    default:
      break;
  }
  return {
    title: `'${contentTypeName}' 추가 필요`,
    msg: `[ 요금 플랜 / 부가 서비스 > 부가 서비스 ] 에서 '${contentTypeName}' 추가가 필요합니다. 해당 메뉴로 이동하시겠습니까?`,
    btnTxtConfirm: "이동하기",
    btnTxtCancel: "아니오",
    reverse: true,
  };
};

export const notSupportedMsg = (type) => {
  const contentTypeName = addOnTypeFilter(type)?.text || "";

  return {
    title: `'${contentTypeName}' 추가 후 이용 가능합니다.`,
    msg: `[ 요금 플랜 / 부가 서비스 > 부가 서비스 ]에서 '${contentTypeName}' 추가를 진행해주세요.`,
  };
};

export const productTypeName = (type, short = false) => {
  let name = "";
  switch (type) {
    case ProductType.SALES:
      name = short ? "콘텐츠" : "콘텐츠 상품";
      break;
    case ProductType.BASIC:
      name = short ? "콘텐츠 (운영)" : "콘텐츠 상품 (운영)";
      break;
    case ProductType.APPLICATION:
      name = "신청서";
      break;
    case ProductType.RESERVATION:
      name = "예약";
      break;
    case ProductType.GOODS:
      name = "실물 상품";
      break;
    default:
      break;
  }

  return name;
};

export const refundToastMsg = (paymentStatus) => {
  let msg = "";
  switch (paymentStatus) {
    case "CANCEL":
      msg = "전체 환불을 완료했습니다.";
      break;
    case "CANCEL_PART":
      msg = "부분 환불을 완료했습니다.";
      break;
    case "CANCEL_REJECT":
      msg = "환불 거부를 완료했습니다.";
      break;
    case "V_BANK_CANCEL_PART":
      msg = "가상계좌 부분 취소를 완료했습니다.";
      break;
    case "V_BANK_CANCEL":
      msg = "가상계좌 결제 취소를 완료했습니다.";
      break;
    default:
      msg = "환불을 완료했습니다.";
      break;
  }

  return msg;
};

export const emptyTableMsg = (routeName) => {
  let type = "";
  const regTxt = "등록된";
  const emptyTxt = "없습니다.";
  switch (routeName) {
    case "content":
      type = "콘텐츠가";
      break;
    case "product-sales":
    case "product-basic":
      type = "프로덕트가";
      break;
    case "reservation":
      type = "예약이";
      break;
    case "application":
      type = "신청서가";
      break;
    case "users":
      type = "회원이";
      break;
    case "product-detail-users":
    case "community-dashboard-member":
      type = "참여자가";
      break;
    case "staff":
      type = "직원이";
      break;
    case "community":
      type = "커뮤니티가";
      break;
    case "promotion":
    case "product-detail-promotion":
      type = "프로모션이";
      break;
    case "payment":
      type = "판매 내역이";
      break;
    case "notice":
      type = "공지사항이";
      break;
    case "product-detail-notices":
      type = "안내사항이";
      break;
    case "community-dashboard-notice":
      type = "공지사항이";
      break;
    case "reply":
      type = "댓글이";
      break;
    case "ask":
      type = "문의내역이";
      break;
    case "faq":
      type = "자주묻는질문이";
      break;
    case "plan-histories":
    case "product-detail-payments":
    case "reservation-detail-payments":
      type = "결제 내역이";
      break;
    case "credit-histories":
      type = "크레딧 내역이";
      break;
    case "application-detail-results":
      type = "신청서 목록이";
      break;
    case "product-detail-delegator":
    case "reservation-detail-delegator":
    case "application-detail-delegator":
      type = "담당자가";
      break;
    default:
      type = "데이터가";
      break;
  }
  return `${regTxt} ${type} ${emptyTxt}`;
};

export const transferStatusFilter = (status) => {
  switch (status) {
    case "SEND":
      return "발송 완료";
    case "OPEN":
      return "열람";
    case "DELIVERY":
      return "미열람";
    case "CLICK":
      return "클릭";
    case "COMPLAINT":
      return "수신거부";
    case "FAIL":
      return "발송 실패";
    default:
      return "";
  }
};

export const failTypeFilter = (status) => {
  switch (status) {
    case "Permanent":
      return "하드 바운스";
    case "Transient":
      return "소프트 바운스";
    case "Undetermined":
      return "알 수 없음";
    default:
      return "발송 실패";
  }
};

export const activityTypeFilter = (status) => {
  switch (status) {
    case "ADD":
      return "구독";
    case "SEND":
      return "SEND";
    case "FAIL":
      return "발송 실패";
    case "DELIVERY":
      return "발송 완료";
    case "OPEN":
      return "열람";
    case "CLICK":
      return "클릭";
    case "COMPLAINT":
      return "수신거부";
    case "SIGNUP":
      return "변경";
    default:
      return "";
  }
};

export const removeFileExtension = (filename) => {
  return filename?.substr(0, filename.lastIndexOf(".")) || "";
};

/**
 * html 엔터티 코드를 텍스트로 변환해준다.
 * @param text
 * @return {string}
 */
export const decodeHTMLEntities = (text) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
};

export const mailToTypeFilter = (item, short = false) => {
  const type = item?.toType;
  switch (type) {
    case "GROUP":
      return "회원 그룹";
    case "ADDR_BOOK":
      return "주소록";
    case "PRODUCT_MEMBERS":
      return short ? "프로덕트" : "프로덕트 참여자";
    default:
      return short ? "회원" : "전체 회원";
  }
};

export const formatBlockType = (blockType) => {
  switch (blockType) {
    case "TEXT":
      return "텍스트";
    case "BUTTON":
      return "버튼";
    case "IMAGE":
      return "이미지";
    case "BANNER":
      return "배너";
    case "TEXT_BUTTON":
      return "텍스트 + 버튼";
    case "CARD_HORIZONTAL":
      return "가로형 카드";
    case "CARD_VERTICAL":
      return "세로형 카드";
    case "SPACER":
      return "여백";
    case "VIDEO":
      return "비디오";
    case "SNS":
      return "SNS";
    case "DIVIDER":
      return "구분선";
    case "PRODUCT":
      return "프로덕트 리스트";
    case "PRODUCT_CUSTOM":
      return "프로덕트 - 직접 진열";
    case "PRODUCT_AUTO":
      return "프로덕트 - 자동 진열";
    case "COMMUNITY":
      return "커뮤니티 리스트";
    case "COMMUNITY_CUSTOM":
      return "커뮤니티 - 직접 진열";
    case "COMMUNITY_AUTO":
      return "커뮤니티 - 자동 진열";
    case "NOTICE":
      return "사이트 소식 리스트";
    case "NOTICE_CUSTOM":
      return "사이트 소식 - 직접 진열";
    case "NOTICE_AUTO":
      return "사이트 소식 - 자동 진열";
    case "PLAYLIST":
      return "참여현황";
    case "HTML":
      return "코드";
    case "HEADER":
      return "헤더";
    case "FOOTER":
      return "푸터";
    default:
      return "";
  }
};

export const formatBlockIcon = (blockType) => {
  switch (blockType) {
    case "TEXT":
      return "Text";
    case "BUTTON":
      return "Button";
    case "IMAGE":
      return "Image";
    case "BANNER":
      return "Banner";
    case "TEXT_BUTTON":
      return "TextButton";
    case "CARD_HORIZONTAL":
      return "Vertical";
    case "CARD_VERTICAL":
      return "Horizontal";
    case "SPACER":
      return "Space";
    case "VIDEO":
      return "Video";
    case "SNS":
      return "SNS";
    case "DIVIDER":
      return "Line";
    case "PRODUCT":
      return "ProductFixed";
    case "PRODUCT_CUSTOM":
    case "PRODUCT_AUTO":
      return "Product";
    case "COMMUNITY":
      return "CommunityFixed";
    case "COMMUNITY_CUSTOM":
    case "COMMUNITY_AUTO":
      return "Community";
    case "NOTICE":
      return "NoticeFixed";
    case "NOTICE_CUSTOM":
    case "NOTICE_AUTO":
      return "Notice";
    case "PLAYLIST":
      return "BlockFlag";
    case "HTML":
      return "Code";
    default:
      return "";
  }
};

export const couponPublishFilter = (status) => {
  switch (status) {
    case "AVAILABLE":
      return { text: "발급 중", color: "success" };
    case "READY":
      return { text: "발급 대기", color: "info" };
    case "STOP":
      return { text: "발급 완료", color: "gray" };
    case "FORCE_STOP":
      return { text: "발급 중지", color: "error" };
    default:
      return { text: "-", color: "gray" };
  }
};

export const couponTypeFilter = (type) => {
  switch (type) {
    case "SELECT":
      return "대상 지정 발행";
    case "AUTO":
      return "자동 발행";
    case "CODE":
      return "쿠폰 코드 발행";
    case "DOWNLOAD":
      return "다운로드";
    default:
      return "-";
  }
};

export const formatPurchaseType = (purchaseType) => {
  switch (purchaseType) {
    case "ONCE":
      return "1회구매";
    case "SUBSCRIPTION":
      return "정기구매";
    default:
      return "-";
  }
};

export const formatSubsMonths = (subsMonths) => {
  if (subsMonths === 12) {
    return "1년";
  }
  return `${subsMonths}개월`;
};
export const formatSubsMonthsText = (subsMonths) => {
  return `${formatSubsMonths(subsMonths)}마다`;
};

export const formatSettlementStatus = (settlementStatus) => {
  switch (settlementStatus) {
    case "STANDBY":
      return "정산 대기";
    case "PAYOUT":
      return "정산 확정";
    case "CANCEL":
      return "정산 취소";
    default:
      return "-";
  }
};
export const colorSettlementStatus = (settlementStatus) => {
  switch (settlementStatus) {
    case "STANDBY":
      return "gray";
    case "PAYOUT":
      return "success";
    case "CANCEL":
      return "error";
    default:
      return "gray";
  }
};

export const formatSettlementWay = (subsMonth, amount) => {
  if (amount <= 20000) {
    return "일괄 지급";
  }
  switch (subsMonth) {
    case 1:
    case 2:
      return "일괄 지급";
    case 3:
    case 4:
      return "2회 분할 지급";
    case 5:
    case 6:
      return "3회 분할 지급";
    case 12:
      return "6회 분할 지급";
    default:
      return "일괄 지급";
  }
};
export const formatSubsStatus = (subsStatus) => {
  switch (subsStatus) {
    case "ACTIVE":
      return "이용중";
    case "PENDING_CANCEL":
      return "해지 예정";
    case "CANCEL":
      return "해지";
    case "FAIL":
      return "결제 실패";
    default:
      return "";
  }
};

export const formatProductMemberCompleteDttm = (productMember = {}) => {
  const { progressStatus, forceDttm, completeDttm } = productMember;
  const isComplete = progressStatus === ProgressStatus.COMPLETE;
  if (isComplete && forceDttm && completeDttm) {
    if (new Date(forceDttm) >= new Date(completeDttm)) {
      return getDateFormat(forceDttm);
    } else {
      return getDateFormat(completeDttm);
    }
  } else if (isComplete && (forceDttm || completeDttm)) {
    const dttm = forceDttm || completeDttm;
    return getDateFormat(dttm);
  } else {
    return "-";
  }
};

export const formatProductMemberPeriod = (member = {}) => {
  const { startDttm, endDttm, pauseEndDttm } = member;
  if (!endDttm) {
    return "-";
  }
  if (endDttm === new Date(99999999999999).toJSON()) {
    return "무제한";
  }
  const prev = new Date() < new Date(startDttm) ? startDttm : new Date();
  const left = getDayLeft(prev, endDttm);
  const date = getDateFormat(endDttm);
  const isExpired = new Date(endDttm) - new Date() < 0;
  const isPause = !!pauseEndDttm && new Date(pauseEndDttm) > new Date();
  if (isExpired) {
    return `~ ${date}`;
  }
  if (isPause) {
    return `- (${comma(left)}일)`;
  }
  return `~ ${date} (${comma(left)}일)`;
};

export const formatProductMemberStartDttm = (startDttm) => {
  if (!startDttm || startDttm === new Date(99999999999999).toJSON()) {
    return "-";
  }
  return getDateFormat(startDttm);
};

export const badgeProductMemberStatusBasic = (memberStatus, endDttm) => {
  const isExpired = new Date(endDttm) - new Date() < 0;
  if (memberStatus === "JOIN" && isExpired) {
    return { text: "기간 만료", color: "gray" };
  }
  switch (memberStatus) {
    case MemberStatus.REQUEST:
      return { text: "승인 대기", color: "info" };
    case MemberStatus.JOIN:
      return { text: "참여중", color: "success" };
    case MemberStatus.REJECT:
      return { text: "거절", color: "error" };
    case MemberStatus.CANCEL:
      return { text: "신청 취소", color: "gray" };
    case MemberStatus.DEL:
      return { text: "내보냄", color: "gray" };
    default:
      return { text: "", color: "" };
  }
};

// 프로덕트 수료 여부
export const badgeProgressStatusProduct = (progressStatus) => {
  switch (progressStatus) {
    case ProgressStatus.COMPLETE:
      return { text: "수료", color: "success" };
    default:
      return { text: "미수료", color: "gray" };
  }
};

export const formatPurchaseTypeWithSubsMonth = (purchaseType, subsMonths) => {
  switch (purchaseType) {
    case "ONCE":
      return formatPurchaseType(purchaseType);
    case "SUBSCRIPTION":
      return `${formatPurchaseType(purchaseType)} / ${formatSubsMonths(
        subsMonths || 1
      )}`;
    default:
      return "-";
  }
};
export const useStatusFilter = (member) => {
  const currentDate = new Date();
  if (
    member?.memberStatus === MemberStatus.DEL ||
    (member?.memberStatus === MemberStatus.JOIN &&
      currentDate > new Date(member.endDttm))
  ) {
    return { text: "이용 불가", theme: "error" };
  }
  if (
    member?.memberStatus === MemberStatus.PAY_REQUEST ||
    (member?.memberStatus === MemberStatus.JOIN &&
      currentDate < new Date(member.startDttm))
  ) {
    return { text: "이용 대기", theme: "info" };
  }
  if (
    member?.memberStatus === MemberStatus.JOIN &&
    currentDate >= new Date(member.startDttm) &&
    currentDate <= new Date(member.endDttm)
  ) {
    return { text: "이용 가능", theme: "success" };
  }
  return { text: "-", theme: "gray" };
};

export const genderFilter = (gender) => {
  switch (gender) {
    case "MALE":
      return "남";
    case "FEMALE":
      return "여";
    default:
      return "-";
  }
};

export const permissionStatusChip = (yn) => {
  return yn
    ? { text: "허용", color: "success" }
    : { text: "허용 안함", color: "gray" };
};

export const progressBadge = (yn) => {
  return yn
    ? { text: "진행중", color: "success" }
    : { text: "진행전", color: "gray" };
};

export const salesStatusBadge = ({
  startDttm = 99999999999999,
  endDttm = 99999999999999,
  promotionYn,
  promotions,
  productType = "SALES",
}) => {
  const today = new Date();
  const startDay = new Date(startDttm);
  const endDay = new Date(endDttm);
  const notStart =
    startDay.toJSON() === new Date(99999999999999).toJSON() ||
    new Date() < startDay;

  const prePromoSalesYn = () => {
    const startDay = new Date(promotions?.[0]?.startDttm);
    const endDay = new Date(promotions?.[0]?.endDttm);
    if (promotionYn && promotions?.[0].promoPreYn) {
      if (startDay < today < endDay) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const prePromotionSalesYn = prePromoSalesYn(promotionYn, promotions);
  const label = productType === "SALES" ? "판매" : "모집";

  if (today > endDay) {
    return {
      text: `${label}마감`,
      color: "error",
      value: "END",
    };
  }
  if (notStart && prePromotionSalesYn) {
    return {
      text: `${label}중`,
      color: "success",
      value: "SALES",
    };
  }
  if (notStart) {
    return {
      text: `${label}전`,
      color: "gray",
      value: "STANDBY",
    };
  }
  if (startDay < today < endDay) {
    return {
      text: `${label}중`,
      color: "success",
      value: "SALES",
    };
  }
  return {
    text: `${label}전`,
    color: "gray",
    value: "STANDBY",
  };
};

export const formatPrice = (price) => {
  if (price === 0) {
    return "무료";
  }
  return "₩ " + comma(price);
};

export const productPriceFilter = ({
  price,
  purchaseTypes,
  subsMonths = undefined,
  subsPrice = 0,
  optPrices = [],
  singleLine = false,
}) => {
  if (price === -1) {
    return "-";
  }

  if (purchaseTypes?.length === 2) {
    return singleLine
      ? ` ₩ ${comma(price)} / ₩ ${comma(subsPrice)} (${formatSubsMonths(
          subsMonths
        )}마다)`
      : ` ₩ ${comma(price)} /
            ₩ ${comma(subsPrice)} (${formatSubsMonths(subsMonths)}마다) `;
  }

  return subsMonths
    ? `₩ ${comma(subsPrice)} (${formatSubsMonths(subsMonths)}마다) `
    : optPrices?.length > 1
      ? `₩ ${comma(price)} ~`
      : `₩ ${comma(price)}`;
};

export const partnerActivityFilter = (status) => {
  switch (status) {
    case "REQUEST":
      return { text: "신청중", color: "info" };
    case "JOIN":
      return { text: "승인", color: "success" };
    case "DISABLED":
      return { text: "정지", color: "error" };
    case "REJECT":
      return { text: "거절", color: "gray" };
    case "CANCEL":
      return { text: "신청 취소", color: "gray" };
    default:
      return { text: "", color: "" };
  }
};
export const purchaseTypesFilter = (purchaseTypes = []) => {
  if (
    purchaseTypes.some((v) => v === PurchaseType.ONCE) &&
    purchaseTypes.some((v) => v === PurchaseType.SUBSCRIPTION)
  ) {
    return "1회 / 정기구매";
  }
  if (purchaseTypes.some((v) => v === PurchaseType.ONCE)) {
    return "1회구매";
  }
  if (purchaseTypes.some((v) => v === PurchaseType.SUBSCRIPTION)) {
    return "정기구매";
  }
  return "-";
};

export const askStatusUserFilter = (status, short = false) => {
  switch (status) {
    case "COMPLETE":
      return { text: short ? "완료" : "답변 완료", color: "success" };
    case "CANCEL":
      return { text: short ? "삭제" : "답변 삭제", color: "gray" };
    default:
      return { text: short ? "대기" : "답변 대기", color: "gray" };
  }
};
export const askTypeFilter = (type, short = false) => {
  switch (type) {
    case "CAMPUS":
      return short ? "수업문의" : "수업 문의 (참여, 수강 등)";
    case "PRODUCT":
      return short ? "상품문의" : "상품 문의";
    case "CLAIM":
      return short ? "취소/교환/반품" : "취소/교환/반품 문의";
    case "LOGIS":
      return short ? "배송문의" : "배송 문의";
    case "SYSTEM":
      return short ? "기술지원" : "기술 지원 (가입, 접속오류, 구매오류)";
    default:
      return "";
  }
};
export const askHistoryFilter = (type) => {
  switch (type) {
    case "CREATE":
      return "등록";
    case "MODIFY":
      return "수정";
    case "DELETE":
      return "삭제";
    default:
      return "";
  }
};
export const claimTypeFilter = (type) => {
  switch (type) {
    case "RETURN":
      return "반품";
    case "EXCHANGE":
      return "교환";
    case "CANCEL":
      return "취소";
    default:
      return "";
  }
};
// @soo temp
export const orderStatusFilter = (type) => {
  switch (type) {
    case "PENDING":
      return "결제 대기";
    case "COMPLETE":
      return "결제 완료";
    case "PREPARING":
      return "상품 준비중";
    case "DELIVERY":
      return "배송중";
    case "DELIVERED":
      return "배송 완료";
    case "CONFIRMED":
      return "구매 확정";
    case "CANCELED":
      return "취소";
    case "EXCHANGED":
      return "교환";
    case "RETURNED":
      return "반품";
    default:
      return "";
  }
};

export const partnerChangeStatusFilter = (item) => {
  const status = item?.partnerChangeStatus || "";

  switch (status) {
    case "APPROVAL":
      return "승인";
    case "REJECT":
      return "반려";
    default:
      return "-";
  }
};

export const partnerTypeFilter = (item) => {
  const bizYn = item?.bizYn;
  const incomeType = item?.incomeType;

  if (bizYn) {
    return "사업자";
  } else {
    return `개인 (${incomeType === "ETC" ? "기타" : "사업"}소득)`;
  }
};
export const askStatusFilter = (status) => {
  switch (status) {
    case AskStatus.STANDBY:
      return { text: "답변 대기", color: "gray" };
    case AskStatus.COMPLETE:
      return { text: "답변 완료", color: "success" };
    case AskStatus.CANCEL:
      return { text: "문의 삭제", color: "gray" };
    default:
      return "";
  }
};

export const formatBizNumber = (val) => {
  let companyNum = val.replace(/[^0-9]/g, "");

  let tempNum = "";

  if (companyNum.length < 4) {
    tempNum = companyNum;
  } else if (companyNum.length < 6) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    return tempNum;
  } else if (companyNum.length < 11) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);

    return tempNum;
  } else {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);
    return tempNum;
  }
};

export function encrypt(txt, key = passwordSecretKey) {
  if (!txt) return;
  const result = CryptoJS.AES.encrypt(JSON.stringify(txt), key).toString();
  return result;
}

/**
 * 퍼블릭키로 암호화
 */
export async function encryptPublic(data) {
  try {
    // 1. PEM 키를 ArrayBuffer로 변환
    const keyBase64 = atob(kmsPublicKey);
    const keyBuffer = new Uint8Array(keyBase64.length).map((_, i) =>
      keyBase64.charCodeAt(i)
    ).buffer;

    // 2. Web Crypto API에서 퍼블릭 키 가져오기
    const publicKey = await crypto.subtle.importKey(
      "spki", // AWS KMS 공개 키는 spki 형식으로 제공
      keyBuffer,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, // RSA-OAEP에서 사용하는 해시 알고리즘
      },
      false, // 추출 불가
      ["encrypt"] // 암호화만 허용
    );

    // 3. 데이터를 암호화
    const encrypted = await crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      new TextEncoder().encode(data) // 데이터를 UTF-8 인코딩
    );

    // 4. 암호화된 데이터를 Base64로 반환
    return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
  } catch (error) {
    console.error("Encryption failed:", error);
    throw error;
  }
}

/**
 * accessKey 기준으로 decrypt.
 * 대칭키로 복호화 필요한 경우 사용한다.
 * ex) 가상계좌결제 환불계좌 복호화
 * @param data
 * @return {*}
 */
export function decryptByAccessKey(data) {
  if (!data) return;
  const accessKeyId = store.getters["auth/getAccessKeyId"];
  if (!accessKeyId) return;
  const decrypted = CryptoJS.AES.decrypt(data, accessKeyId).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
}

export const decrypt = (txt) => {
  if (!txt) return;
  const bytes = CryptoJS.AES.decrypt(txt, passwordSecretKey);
  const result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)).toString();
  return result;
};

export const logisCdFilter = (cd) => {
  switch (cd) {
    case "KP":
      return {
        text: "우체국택배",
        url: "https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?displayHeader=N&sid1=",
      };
    case "CJ":
      return {
        text: "CJ대한통운",
        url: "https://trace.cjlogistics.com/next/tracking.html?wblNo=",
      };
    case "LOGEN":
      return {
        text: "로젠택배",
        url: "https://www.ilogen.com/m/personal/trace.pop/",
      };
    case "LOTTE":
      return {
        text: "롯데택배",
        url: "https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=",
      };
    case "HANJIN":
      return {
        text: "한진택배",
        url: "https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=",
      };
    case "QX":
      return {
        text: "큐익스프레스",
        url: "https://www.qxpress.net/Tracking?trackno=",
      };
    case "IY":
      return {
        text: "일양로지스",
        url: "https://www.ilyanglogis.com/functionality/tracking_result.asp?hawb_no=",
      };
    case "GY":
      return {
        text: "건영택배",
        url: "https://www.kunyoung.com/goods/goods_01.php?mulno=",
      };
    case "DS":
      return {
        text: "대신택배",
        url: "https://www.ds3211.co.kr/freight/internalFreightSearch.ht?billno=",
      };
    case "KD":
      return {
        text: "경동택배",
        url: "https://kdexp.com/service/delivery/etc/delivery.do?barcode=",
      };
    case "GS":
      return {
        text: "GS Postbox 택배",
        url: "https://www.cvsnet.co.kr/invoice/tracking.do?invoice_no=",
      };
    case "CU":
      return {
        text: "CU편의점택배",
        url: "https://www.cupost.co.kr/postbox/delivery/localResult.cupost?invoice_no=",
      };
    case "SLX":
      return {
        text: "SLX택배",
        url: "https://slx.co.kr/delivery/delivery_number.php?param1=",
      };
    case "HAPDONG":
      return {
        text: "합동택배",
        url: "https://hdexp.co.kr/basic_delivery.hd?barcode=",
      };
    case "WR":
      return {
        text: "우리택배",
        url: "http://www.woorilogis.co.kr/page/index.php?pid=tracking_number&SLIP_BARCD=",
      };
    case "EMS":
      return {
        text: "EMS",
        url: "https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=",
      };
    case "FEDEX":
      return {
        text: "FedEx",
        url: "https://www.fedex.com/fedextrack/?trknbr=",
      };
    case "UPS":
      return {
        text: "UPS",
        url: "https://www.ups.com/track?track=yes&loc=ko_kr&trackNums=",
      };
    case "DHL":
      return {
        text: "DHL",
        url: "https://www.dhl.com/kr-en/home/tracking/tracking-parcel.html?locale=true&submit=1&tracking-id=",
      };
    case "TNT":
      return {
        text: "TNT Express",
        url: "https://www.tnt.com/express/en_kr/site/shipping-tools/tracking.html?respCountry=kr&respLang=ko&searchType=CON&cons=",
      };
    case "USPS":
      return {
        text: "USPS",
        url: "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=",
      };
    case "I_P":
      return {
        text: "i-parcel",
        url: "https://tracking.wweconomy.ups.com/Home/Index?trackingnumber=",
      };
    case "LX":
      return {
        text: "LX판토스",
        url: "http://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?quickNo=",
      };
    case "GSI":
      return {
        text: "GSI Express",
        url: "http://www.gsiexpress.com/track_pop.php?track_type=ship_num&query_num=",
      };
    case "ECMS":
      return {
        text: "ECMS Express",
        url: "https://www.ecmsglobal.com/ko-kr/tracking.html?orderNumber=",
      };
    case "AUS":
      return {
        text: "AusPost",
        url: "https://auspost.com.au/mypost/track/#/details/",
      };
    default:
      return "";
  }
};

export const formatDeliveryWay = (deliveryWay) => {
  switch (deliveryWay) {
    case DeliveryWay.LOGIS:
      return "택배";
    case DeliveryWay.SELLER:
      return "직접 배송";
    case DeliveryWay.SELF:
      return "직접 수령";
    default:
      return "";
  }
};

export const formatDeliveryPayType = (deliveryPayType) => {
  switch (deliveryPayType) {
    case DeliveryPayType.PRE:
      return "선결제";
    case DeliveryPayType.COD:
      return "착불";
    default:
      return "";
  }
};

export const formatDeliveryCostType = (deliveryCostType) => {
  switch (deliveryCostType) {
    case DeliveryCostType.PAID:
      return "유료";
    case DeliveryCostType.FREE:
      return "무료";
    case DeliveryCostType.CONDITION:
      return "조건부 무료";
    case DeliveryCostType.QTY_REPEAT:
      return "수량별 반복 부과";
    case DeliveryCostType.QTY_SEGMENTS:
      return "수량 구간별 부과";
    default:
      return "";
  }
};

export const formatPaymentOption = (payment) => {
  const productType = payment?.productType || "";
  if (!productType) {
    return "-";
  }
  const {
    optionName,
    priceName,
    purchaseType,
    subsMonths,
    itemSelections = [],
  } = payment;

  switch (productType) {
    case "SALES":
      const type = formatPurchaseType(purchaseType);
      return purchaseType === "ONCE"
        ? type
        : `${type} (${formatSubsMonths(subsMonths || 1)}마다)`;
    case "RESERVATION":
      return !optionName || !priceName ? "" : `${optionName} : ${priceName}`;
    case "GOODS":
      let itemName = itemSelections?.reduce((acc, cur, index) => {
        acc += `${cur?.optionName} : ${cur?.name}`;
        if (index < itemSelections?.length - 1) {
          acc += " / ";
        }
        return acc;
      }, "");
      return itemName || "";
    default:
      return "";
  }
};

export const formatOrderStatusQuery = (payment) => {
  const orderStatus = payment?.orderStatus || payment?.paymentStatus || "";
  switch (orderStatus) {
    case "PAID":
      return "결제 완료";
    case "PREPARE":
      return "상품 준비중";
    case "SHIPPING":
      return "배송중";
    case "SHIP_DONE":
      return "배송 완료";
    case "CONFIRMED":
      return "구매 확정";
    case "CANCEL_REQ":
      return "취소 신청";
    case "CANCEL":
      return "취소 완료";
    case "CANCEL_ALL":
      return "취소 상태 전체";
    case "PAY_REVERT":
      return "결제 취소";
    case "XCHG_ALL":
      return "교환 상태 전체";
    case "RET_ALL":
      return "반품 상태 전체";
    case "RET_DONE":
      return "반품 완료";
    default:
      return "";
  }
};

export const formatClaimStatus = (payment) => {
  const claimStatus = payment?.claimStatus || "";

  switch (claimStatus) {
    case "CANCEL_REQ":
      return "취소 신청";
    case "CANCEL":
      return "취소 완료";

    case "RET_REQ":
      return "반품 신청";
    case "RET_DONE":
      return "반품 완료";
    case "RET_REJECT":
      return "반품 거부";
    case "RET_CANCEL":
      return "반품 취소";
    case "RET_PICKING":
      return "반품 수거중";
    case "RET_PICK_DONE":
      return "반품 수거완료";

    case "XCHG_REQ":
      return "교환 신청";
    case "XCHG_PICKING":
      return "교환 수거중";
    case "XCHG_SHIPPING":
      return "교환 재배송중";
    case "XCHG_PICK_DONE":
      return "교환 수거완료";
    case "XCHG_DONE":
      return "교환 완료";
    case "XCHG_REJECT":
      return "교환 거부";
    case "XCHG_CANCEL":
      return "교환 취소";
    default:
      return "";
  }
};

export const formatPaymentStatus = (payment) => {
  const paymentStatus = payment?.paymentStatus || "";
  switch (paymentStatus) {
    case "PAID":
    case "CANCEL_REJECT":
      return "결제 완료";
    case "CANCEL_REQ":
      return "취소 신청";
    case "CANCEL_PART":
    case "CANCEL":
      return "취소 완료";
    case "PAY_REQUEST": // 결제 요청(기타결제, 가상게좌)
      return "결제 대기";
    case "PAY_REJECT": // 결제 거절(기타결제)
    case "PAY_REVERT": //결제 요청 취소(기타결제, 가상계좌)
      return "결제 취소";
    default:
      return "";
  }
};

export const formatOrderStatus = (payment) => {
  const claimStatus = formatClaimStatus(payment);
  const orderStatus = formatOrderStatusQuery(payment);

  const productType = payment?.productType || "";
  const isReq = payment?.paymentStatus === "PAY_REQUEST";
  const isReject = payment?.paymentStatus === "PAY_REJECT";

  if (productType === "GOODS" && !isReq && !isReject) {
    if (claimStatus) {
      return claimStatus;
    }
    return orderStatus;
  }

  return formatPaymentStatus(payment);
};

export const formatPaymentBadge = (payment) => {
  const isGoods = payment?.productType === "GOODS";
  let status = payment?.paymentStatus;
  if (isGoods) {
    if (payment.claimStatus === "CANCEL_REQ") {
      status = payment.claimStatus;
    }
    status = payment.orderStatus || payment.paymentStatus;
  }

  switch (status) {
    // https://www.figma.com/design/a5uCO4fam1OXgLdIGhSK2k?node-id=11276-42926&m=dev#942741525
    case "CANCEL_REJECT":
    case "PAID":
      return { text: "결제 완료", color: "success" };
    case "SHIP_DONE":
      return { text: "베송 완료", color: "success" };

    case "PREPARE":
      return { text: "상품 준비중", color: "primary" };
    case "CANCEL_REQ":
      return { text: "취소 신청", color: "primary" };

    case "SHIPPING":
      return { text: "배송중", color: "info" };
    case "PAY_REQUEST":
      return { text: "결제 대기", color: "info" };

    case "CONFIRMED":
      return { text: "구매 확정", color: "gray" };
    case "CANCEL":
    case "CANCEL_PART":
      return { text: "취소 완료", color: "gray" };
    case "PAY_REVERT":
    case "PAY_REJECT":
      return { text: "결제 취소", color: "gray" };
    case "RET_DONE":
      return { text: "반품 완료", color: "gray" };
    default:
      return { text: "상태 없음", color: "error" };
  }
};
export const deliveryWayFilter = (type) => {
  switch (type) {
    case DeliveryWay.LOGIS:
      return "택배";
    case DeliveryWay.SELLER:
      return "직접 배송";
    case DeliveryWay.SELF:
      return "직접 수령";
    default:
      return "";
  }
};
export const retWayFilter = (type) => {
  switch (type) {
    case retWay.SELLER:
      return "판매자 수거";
    case retWay.SELF:
      return "구매자 직접 발송";
    case retWay.NONE:
      return "수거 정보 없음";
    default:
      return "";
  }
};

export const formatCombineCalcType = (type) => {
  switch (type) {
    case CombineCalcType.MAX:
      return "가장 낮은 배송비로 부과";
    case CombineCalcType.MIN:
      return "가장 높은 배송비로 부과";
    default:
      return "";
  }
};

export const formatSettlementType = (productType) => {
  switch (productType) {
    case "SALES":
      return "콘텐츠 상품";
    case "GOODS":
      return "실물 상품";
    case "RESERVATION":
      return "예약";
    case "DELIVERY":
      return "배송비";
    case "PG":
      return "PG 수수료";
    default:
      return "";
  }
};
export const reasonTypeFilter = (type) => {
  switch (type) {
    case "SELF_A":
      return "주문 실수";
    case "SELF_B":
      return "단순 변심";
    case "SELF_C":
      return "옵션 변경";
    case "SELLER_A":
      return "상품 파손, 불량";
    case "SELLER_B":
      return "상품 설명과 다름";
    case "SELLER_C":
      return "배송 오류";
    case "SELLER_D":
      return "배송 지연";
    case "SELLER_E":
      return "서비스 불만족";
    case "SELLER_F":
      return "상품 품절";
    default:
      return "-";
  }
};

export const formatClaimType = (type) => {
  switch (type) {
    case "SELF_A":
      return "주문 실수";
    case "SELF_B":
      return "단순 변심";
    case "SELF_C":
      return "옵션 변경";
    case "SELLER_A":
      return "상품 파손, 불량";
    case "SELLER_B":
      return "상품 설명과 다름";
    case "SELLER_C":
      return "배송 오류";
    case "SELLER_D":
      return "배송 지연";
    case "SELLER_E":
      return "서비스 불만족";
    case "SELLER_F":
      return "상품 품절";
    case "SELLER_G":
      return "가격 오류";
    case "SELLER_H":
      return "기술적 문제";
    case "SELLER_I":
      return "업데이트 지연";
    case "SELLER_J":
      return "일정 변경";
    default:
      return "";
  }
};

export const formatClaimDeliveryPayType = (type) => {
  return type === "REFUND" ? "환불금에서 공제" : "직접 송금";
};

export const claimStatusChip = (status) => {
  switch (status) {
    case "CANCEL_REQ":
      return { text: "취소 신청", theme: "success" };
    case "CANCEL":
      return { text: "취소 완료", theme: "gray" };
    case "RET_REQ":
      return { text: "반품 신청", theme: "success" };
    case "RET_PICKING":
      return { text: "반품 수거중", theme: "info" };
    case "RET_PICK_DONE":
      return { text: "반품 수거 완료", theme: "info" };
    case "RET_DONE":
      return { text: "반품 완료", theme: "gray" };
    case "RET_CANCEL":
      return { text: "반품 철회", theme: "gray" };
    case "XCHG_REQ":
      return { text: "교환 신청", theme: "success" };
    case "XCHG_PICKING":
      return { text: "교환 수거중", theme: "info" };
    case "XCHG_PICK_DONE":
      return { text: "교환 수거 완료", theme: "info" };
    case "XCHG_SHIPPING":
      return { text: "교환 재배송중", theme: "info" };
    case "XCHG_DONE":
      return { text: "교환 완료", theme: "gray" };
    case "XCHG_REJECT":
      return { text: "교환 철회", theme: "gray" };
    default:
      return { text: "", theme: "gray" };
  }
};

export const formatOrderInfoStatus = (orderInfoStatus, product = {}) => {
  switch (orderInfoStatus) {
    case "ENABLE":
      // 주문 가능
      return "";
    case "DISABLE":
      // 주문 불가
      return "구매 불가";
    case "JOIN_MEMBER":
      // 콘텐츠 상품 이미 참여중인 멤버
      return "이미 참여중입니다.";
    case "PAY_REQUEST_MEMBER":
      // 콘텐츠 상품 기타결제, 가상계좌 결제요청중
      return "결제 대기 중입니다.";
    case "LIMIT_EXCEED":
      // 콘텐츠 상품 판매건수 제한
      return "품절";
    case "PROMOTION_LIMIT_EXCEED":
      // 사전 프로모션 수량 제한 초과
      return `구매 가능 수량 ${product.remainLimit}개`;
    case "MIN":
      // 실물상품 최소구매수량 미달
      return `최소 구매 수량 ${product.min}개`;
    case "MAX":
      // 실물상품 1회 최대구매수량 초과
      return `최대 구매 수량 ${product.max}개`;
    case "PERSON_LIMIT_EXCEED":
      // 실물상품 1인 최대구매수량 초과
      return `1인 최대 구매 수량 ${product.limit}개 (남은 구매 수량 ${product.remainLimit}개)`;
    case "STOCK_EXCEED":
      // 재고수량 초과
      return `남은 재고 수량 ${product.remainStock}개`;
    case "INVALID_COUPON":
      // 그룹할인, 프로모션 적용 후 0원인 경우 쿠폰 적용 불필요
      return "적용 쿠폰 사용 불가";
    case "COUPON_MIN":
      // 쿠폰사용 최소금액 미달
      return "적용 쿠폰 사용 불가";
    case "USED_COUPON":
      // 사용된 쿠폰
      return "적용 쿠폰 사용 불가";
    case "EXPIRED_COUPON":
      // 만료된 쿠폰
      return "적용 쿠폰 사용 불가";
    case "COUPON_INVALID_APPLY_TYPE":
      // 쿠폰 적용 가능 프로덕트 유형 아님
      return "적용 쿠폰 사용 불가";
    case "COUPON_IGNORE":
      // 쿠폰 적용 제외 프로덕트
      return "적용 쿠폰 사용 불가";
    case "COUPON_INVALID_TARGET":
      // 쿠폰 적용 대상 아님
      return "적용 쿠폰 사용 불가";
    default:
      return "";
  }
};

export const formatProductOptionDescription = (orderCheckProductResult) => {
  if (!orderCheckProductResult) {
    return "";
  }

  const product = orderCheckProductResult.product || {};
  const params = orderCheckProductResult.params || {};
  if (product.productType === ProductType.SALES) {
    if (params.subscriptionYn) {
      return `정기구매(${product.subsMonths}개월마다) | 1회차`;
    } else {
      const period =
        product.period > 0
          ? `${comma(product.period)}일 이용 가능`
          : "이용 기한 제한 없음";
      return `1회구매 | ${period}`;
    }
  }
  if (product.productType === ProductType.GOODS) {
    const options = `${orderCheckProductResult.itemSelections?.map((s) => `${s.optionName} : ${s.name}`)?.join(" / ") || ""}`;
    const quantity = params.quantity || 1;
    if (!options) {
      return `${quantity}개`;
    }
    return `${options} | ${quantity}개`;
  }
  if (product.productType === ProductType.RESERVATION) {
    return `${orderCheckProductResult.option?.name}: ${orderCheckProductResult.priceOption?.name}`;
  }

  return "";
};

export const formatTargetNames = (items, unit = "개") => {
  if (!Array.isArray(items) || items.length === 0) {
    return "";
  }

  if (items.length === 1) {
    return items[0].name; // 단일 항목 처리
  }

  // 다중 항목 처리
  return `${items[0].name} 외 ${items.length - 1}${unit}`;
};

export const detectLanguageFromText = (text) => {
  if (/^\s*import\s+\w+/.test(text) || /def\s+\w+\(/.test(text)) {
    return "python";
  } else if (/^\s*function\s+\w+\(/.test(text) || /console\.log/.test(text)) {
    return "javascript";
  } else if (
    /^\s*public\s+class\s+\w+/.test(text) ||
    /System\.out\.println/.test(text)
  ) {
    return "java";
  } else if (/^\s*fun\s+\w+\(/.test(text) || /val\s+\w+\s*=/.test(text)) {
    return "kotlin";
  } else if (/^\s*func\s+\w+\(/.test(text)) {
    return "swift";
  } else if (/^\s*#include\s+<.+>/.test(text) || /std::/.test(text)) {
    return "cpp";
  } else if (
    /^\s*using\s+System;/.test(text) ||
    /Console\.WriteLine/.test(text)
  ) {
    return "csharp";
  } else if (/^\s*\w+\s*<-/.test(text)) {
    return "r";
  } else {
    return "plaintext";
  }
};
