<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "@/store";
import { useAuth } from "@/hooks/useAuth";
import CampusLogo from "@/components/layout/header/CampusLogo.vue";
import UserProfile from "@/components/poin-ui/UserProfile.vue";
import NotificationIcon from "@/components/poin-ui/NotificationIcon.vue";
import CampusHeaderNavigation from "./CampusHeaderNavigation.vue";
import CartIcon from "./CartIcon.vue";
import { useCampusStore } from "@/stores/campus";
import { PartnerStatus } from "@/enums/membersEnum";

const store = useStore();
const route = useRoute();
const campusStore = useCampusStore();
const styles = computed(() => {
  return campusStore.styles;
});

const { signIn, signUp, isAuthenticated } = useAuth();
const domain = computed(() => store.getters["campuses/getCampusDomain"]);
const lastAccCont = computed(() => store.getters["members/getLastAccCont"]);
const isStaff = computed(() => store.getters["members/isStaff"]);
const bgColor = computed(() => styles.value?.headerStyles?.backgroundColor);
const color = computed(() => styles.value?.headerStyles?.color);
const accentColor = computed(() => styles.value?.headerStyles?.accentColor);
const partnersYn = computed(() => styles.value?.headerStyles?.partnersYn);
const member = computed(() => store.getters["members/getMemberItem"]);
const isPartner = computed(() =>
  [
    PartnerStatus.JOIN,
    PartnerStatus.REJECT,
    PartnerStatus.REQUEST,
    PartnerStatus.DISABLED,
  ].includes(member.value?.partnerStatus)
);
</script>

<template>
  <header class="header__container">
    <div class="header__wrap">
      <router-link
        :to="{ name: 'campus-home', params: route.params }"
        class="d-flex"
      >
        <CampusLogo />
      </router-link>
      <CampusHeaderNavigation class="mr-auto" />

      <div
        v-if="!isAuthenticated"
        class="d-flex align-center text-detail-1-medium"
      >
        <span class="sign-text" @click="signIn">로그인</span>
        <span class="gray--text text--darken-3">|</span>
        <span class="sign-text" @click="signUp">회원가입</span>
        <template v-if="partnersYn">
          <span class="gray--text text--darken-3">|</span>
          <router-link
            :to="{ name: 'campus-partners-landing' }"
            class="sign-text"
            >파트너 서비스</router-link
          >
        </template>
      </div>
      <div class="d-flex align-center gap-3" v-else>
        <router-link
          v-if="isStaff"
          :to="{
            name: 'campus-admin-dashboard',
            params: route.params,
            query: route.query,
          }"
          ><p-btn color="gray" outlined icon="Setting" small
            >캠퍼스 관리</p-btn
          ></router-link
        >
        <router-link
          v-else-if="lastAccCont && !isPartner"
          :to="{
            name: 'campus-playground',
            params: { ...route.params, ...lastAccCont },
            query: route.query,
          }"
          ><p-btn color="gray" outlined small>이어보기</p-btn></router-link
        >
        <router-link
          v-if="!isStaff"
          :to="{
            name: 'campus-playlist',
            params: { ...route.params, campusDomain: domain },
            query: route.query,
          }"
          ><p-btn color="gray" outlined small>참여현황</p-btn></router-link
        >
        <!-- <router-link
          v-if="isPartner"
          :to="{
            name: 'campus-partners-dashboard',
            params: { ...route.params, campusDomain: domain },
            query: route.query,
          }"
          ><p-btn color="info" sub small>파트너 홈</p-btn></router-link
        > -->
        <NotificationIcon />
        <UserProfile />
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.header__container {
  width: 100%;
  height: var(--header-height);
  background-color: v-bind(bgColor);
  box-shadow: 0 1px 0 0 $gray3;
  z-index: var(--header-zIndex);
  top: 0;
  left: 0;
  position: sticky;
  .header__wrap {
    padding: 0 var(--gutter);
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
.sign-text {
  color: v-bind(color);
  cursor: pointer;
  padding: 8px;
  @include hover-transition(color);
  &:hover {
    color: v-bind(accentColor);
  }
}
</style>
