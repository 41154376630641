export default {
  setProductsStaff(state, result) {
    state.productsStaff.lastKey = result.lastKey;
    if (result.results) {
      state.productsStaff.items = [...result.results];
    }
  },
  updateProductsStaff(state, result) {
    state.productsStaff.lastKey = result.lastKey;
    if (result.results) {
      state.productsStaff.items = [
        ...state.productsStaff.items,
        ...result.results,
      ];
    }
  },
  clearProductsStaff(state) {
    state.productsStaff.lastKey = "";
    state.productsStaff.items = [];
  },
  updateProductStatus(state, { id, publishYn }) {
    state.productsStaff.items.find((product) => product.id === id).publishYn =
      publishYn;
  },
  setProductDelegators(state, delegators) {
    state.delegators = delegators?.length > 0 ? delegators : [];
  },
  clearProductDelegators(state) {
    state.delegators = [];
  },
  /*
  캠퍼스 관리 > 프로덕트 관리 > 프로덕트 목록 테이블에서 프로덕트 삭제 시,
  store의 프로덕트 목록 중 해당 프로덕트 삭제 처리
   */
  removeProduct(state, id) {
    state.productsStaff.items = state.productsStaff.items.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          name: "삭제된 프로덕트입니다.",
          delYn: true,
          publishYn: false,
        };
      }
      return product;
    });
  },
  /*
  캠퍼스 관리 > 프로덕트 관리 > 프로덕트 목록 테이블에서 노출 정보 수정 시,
  store의 프로덕트 목록 중 해당 프로덕트 정보 업데이트
   */
  updateProducts(state, newProduct) {
    state.productsStaff.items = state.productsStaff.items.map((product) => {
      if (product.id === newProduct?.id) {
        return {
          ...newProduct,
        };
      }
      return product;
    });
  },
  // store에 저장된 프로덕트 단건 업데이트
  setProduct(state, product = {}) {
    const newContents =
      product.CONTENTS?.map((content) => {
        const items = content.items?.map((item) => {
          const children = item.items?.map((child) => {
            const descendants = child.items?.map((descendant) => {
              const id = descendant.id;
              const upperId = descendant.upperId;
              return {
                ...descendant,
                ...(descendant.syncYn && descendant.origin),
                ...(id && { id }),
                ...(upperId && { upperId }),
              };
            });
            const id = child.id;
            const upperId = child.upperId;
            return {
              ...child,
              ...(child.syncYn && child.origin),
              ...(descendants && { items: descendants }),
              ...(id && { id }),
              ...(upperId && { upperId }),
            };
          });
          const id = item.id;
          const upperId = item.upperId;
          return {
            ...item,
            ...(item.syncYn && item.origin),
            ...(children && { items: children }),
            ...(id && { id }),
            ...(upperId && { upperId }),
          };
        });
        const id = content.id;
        const upperId = content.upperId;
        return {
          ...content,
          ...(content.syncYn && content.origin),
          ...(items && { items }),
          ...(id && { id }),
          ...(upperId && { upperId }),
        };
      }) || [];
    state.product = { ...product, CONTENTS: newContents };
  },
  updateProduct(state, product = {}) {
    const newContents =
      product?.CONTENTS?.map((content) => {
        const items = content.items?.map((item) => {
          const children = item.items?.map((child) => {
            const descendants = child.items?.map((descendant) => {
              const id = descendant.id;
              const upperId = descendant.upperId;
              return {
                ...descendant,
                ...(descendant.syncYn && descendant.origin),
                ...(id && { id }),
                ...(upperId && { upperId }),
              };
            });
            const id = child.id;
            const upperId = child.upperId;
            return {
              ...child,
              ...(child.syncYn && child.origin),
              ...(descendants && { items: descendants }),
              ...(id && { id }),
              ...(upperId && { upperId }),
            };
          });
          const id = item.id;
          const upperId = item.upperId;
          return {
            ...item,
            ...(item.syncYn && item.origin),
            ...(children && { items: children }),
            ...(id && { id }),
            ...(upperId && { upperId }),
          };
        });
        const id = content.id;
        const upperId = content.upperId;
        return {
          ...(content.syncYn && content.origin),
          ...content,
          ...(content.syncYn && content.origin),
          ...(items && { items }),
          ...(id && { id }),
          ...(upperId && { upperId }),
        };
      }) || [];
    state.product = { ...state.product, ...product, CONTENTS: newContents };
  },
  clearProduct(state) {
    state.product = {};
  },
  setNewProduct(state, product) {
    const newProduct = structuredClone(product);
    state.newProduct = { ...newProduct };
  },
  updateNewProduct(state, product) {
    state.newProduct = { ...state.newProduct, ...product };
  },
  updateNewProductContents(state, contents) {
    state.newProduct = {
      ...state.newProduct,
      CONTENTS: structuredClone(contents),
    };
  },
  updateNewProductSettings(state, settings = {}) {
    state.newProduct = {
      ...state.newProduct,
      SETTING: {
        ...state.newProduct.SETTING,
        ...settings,
      },
    };
  },
  updateNewProductCompletion(state, completion) {
    state.newProduct = {
      ...state.newProduct,
      SETTING: {
        ...state.newProduct.SETTING,
        completion: {
          ...state.newProduct.SETTING.completion,
          ...completion,
        },
      },
    };
  },
  updateNewProductCertificate(state, settings) {
    state.newProduct = {
      ...state.newProduct,
      SETTING: {
        ...state.newProduct.SETTING,
        certificate: {
          ...state.newProduct.SETTING.certificate,
          ...settings,
        },
      },
    };
  },
  updateNewProductGroupPrice(state, groupPrice) {
    state.newProduct = {
      ...state.newProduct,
      groupPrice: {
        ...state.newProduct.groupPrice,
        ...groupPrice,
      },
    };
  },
  updateNewPurchaseTypes(state, { type, value }) {
    if (state.newProduct.purchaseTypes === undefined) {
      state.newProduct = { ...state.newProduct, purchaseTypes: [] };
    }
    const index = state.newProduct.purchaseTypes?.indexOf(type);
    if (value && index === -1) {
      state.newProduct.purchaseTypes.push(type);
    } else if (!value && index !== -1) {
      state.newProduct.purchaseTypes.splice(index, 1);
    }
  },
  clearNewProduct(state) {
    state.newProduct = {
      ...{
        name: "",
        tags: "",
        price: "1,000",
        refundPolicyId: "",
        period: 0,
        limit: 0,
        startDttm: 99999999999999,
        endDttm: 99999999999999,
        productType: "SALES",
        communityIds: [],
        publishYn: false,
        image: {
          conts: "",
          textColor: "black",
          horizontal: "CENTER",
          nameYn: false,
          items: [
            {
              path: "https://file.poincampus.com/assets/sample/product.png",
              key: "DEFAULT",
            },
          ],
        },
        categoryId: "",
        DESC: {
          links: [""],
          managers: [],
          introduce: "",
        },
        SETTING: {
          options: {
            sequencialYn: false,
            video: {
              seekYn: true,
              playbackYn: true,
              multiple: 0,
            },
            audio: {
              seekYn: true,
              playbackYn: true,
              multiple: 0,
            },
          },
          completion: {
            compRate: 80,
            contents: [
              {
                contentType: "VIDEO",
                compRate: 80,
              },
              {
                contentType: "AUDIO",
                condition: "",
                compRate: 80,
              },
            ],
          },
          // certificate
          certificate: {
            dttmYn: false,
            yn: false,
            startDttm: "",
            seals: [],
            endDttm: "",
            title: "수료증",
            desc: "위 사람은 본 교육과정을 수료하였으므로 이 증서를 수여합니다.",
          },
        },
        CONTENTS: [],
        classfiedYn: false,
        joinType: "AUTO",
        DELETE_CONTENTS: [],
      },
    };
  },
  setCustAdditional(state, items = []) {
    state.custAdditional.items = [...items];
  },
  updateProductImage(state, items) {
    state.newProduct.image.items = items;
  },
  setPromotion(state, promotion) {
    state.promotion = promotion;
  },
  setPromotions(state, data) {
    if (data.lastKey) {
      state.promotionLastkey = data.lastKey;
    } else {
      state.promotionLastkey = "";
    }
    if (data.results) {
      state.promotions = [...state.promotions, ...data.results];
    }
  },
  clearPromotions(state) {
    state.promotionLastkey = "";
    state.promotions = [];
  },
  removePromotion(state, id) {
    state.promotions = state.promotions.map((promotion) => {
      if (promotion.id === id) {
        return {
          ...promotion,
          name: "삭제된 프로모션입니다.",
          delYn: true,
        };
      }
      return promotion;
    });
  },
  updatePromotions(state, newPromotion) {
    state.promotions = state.promotions.map((promotion) => {
      if (promotion.id === newPromotion?.id) {
        return {
          ...promotion,
          // promotionStatus: newPromotion?.promotionStatus,
          ...newPromotion,
        };
      }
      return promotion;
    });
  },
  setChceckPrices(state, prices) {
    state.checkPrices = prices;
  },
  clearCheckPrices(state) {
    state.checkPrices = {};
  },
};
